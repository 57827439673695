/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import Dropzone from "react-dropzone";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import uuid from "react-uuid";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "IMPORT REPORT" : "IMPORT";
const fieldLabels = englishUI
  ? {
      ReportFile: "Report file:",
      Attachs: "Attachs:",
    }
  : {
      ReportFile: "Report file:",
      Attachs: "Attachs:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["ShipCode", "ShipName"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

////////////////////////--------------/////////////////////////////////////////
export default class ImportReportDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validateStatus: { error: false }, //trang thai validate

      after: {
        ReportFile: null,
        Attachs: [],
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  loadDanhMuc() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
       if(this.state.after.ReportFile) {
        this.doImport(this.state.after.ReportFile);
       }
       else {
        alert("Chưa có file!");
       }
    } catch (ex) {
      showError(ex);
    }
  };
  //thuc hien import file
  doImport(file) {
    try {
      var fr = new FileReader();
      fr.onload = async (e) => {
        const text = e.target.result;
        const json = Buffer.from(text).toString();
        const data = JSON.parse(json);
        this.doReadAttachs(data);
      };
      fr.onerror = (err) => showError(err);
      fr.readAsArrayBuffer(file);
    } catch (ex) {
      showError(ex);
    }
  }
  doReadAttachs(data) {
    //thuc hien doc tiep cac file attachs neu co
    var readers = [];
    this.state.after.Attachs.map((file) => {
      var prom = new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          var fileData = {};
          fileData.FileID = uuid();
          fileData.FileName = file.name;
          fileData.FileContent = Buffer.from(text).toString("base64");
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
      readers.push(prom);
    });
    //thuc hien viec xu ly
    Promise.all(readers)
      .then((list) => {
        //thuc hien upload
        this.doUpload(data, list);
      })
      .catch((e) => showError(e));
  }
  //upload du lieu report
  doUpload(data, attachs) {
    this.setState({ saving: true });
    //tao cac tham so
    data.ReportContent = JSON.parse(data.ReportContent);
    data.ReportContent.Attachs = [];
    var ok = true;
    attachs.map((att) =>
    {
      data.ReportContent.Attachs.push({
        FileID: att.FileID,
        FileName: att.FileName,
      });
      if(att.FileContent == null || att.FileContent == undefined) {
        alert("Không đọc được nội dung file!");
        ok = false;
        return;
      }
    }
    );
    if(!ok) {
      return;
    }
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_TempReport_Import",
        ThamSo: {
          ReportInJson: data,
          Attachs: attachs,
        },
      })
      .then((response) => {
          alert("Cập nhật thành công!");
          this.props.close(true);
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }
  render() {
    var attachs = "";
    this.state.after.Attachs.map(
      (file) => (attachs += (attachs !== "" ? ";" : "") + file.name)
    );
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.ReportFile}</FormLabel>
              </Grid>
              <Grid item xs={16}>
                <OutlinedInput
                  id="txtShipCode"
                  fullWidth
                  readOnly
                  value={
                    this.state.after.ReportFile
                      ? this.state.after.ReportFile.name
                      : ""
                  }
                  error={this.state.validateStatus.ReportFile !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropzone
                  maxFiles={1}
                  minSize={1}
                  validator={(file) => {
                    return false;
                  }}
                  accept1={{ "application/json": [".json"] }}
                  onDrop={(acceptedFiles) => {
                    console.log(acceptedFiles);
                    this.saveField(
                      "ReportFile",
                      acceptedFiles ? acceptedFiles[0] : null
                    );
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept=".json" />
                        <Button fullWidth variant="contained">
                          File
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.Attachs}</FormLabel>
              </Grid>
              <Grid item xs={16}>
                <OutlinedInput
                  id="txtAttachs"
                  fullWidth
                  readOnly
                  value={attachs}
                  error={this.state.validateStatus.Attachs !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropzone
                  maxFiles={10}
                  minSize={1}
                  validator={(file) => {
                    return false;
                  }}
                  accept1={{ "application/json": [".json"] }}
                  onDrop={(acceptedFiles) => {
                    console.log(acceptedFiles);
                    this.saveField("Attachs", acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} accept=".json" />
                        <Button fullWidth variant="contained">
                          File
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
