import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Noon At Port Report"
  : "Báo cáo buổi trưa tại cảng";
const fieldLabels = englishUI
  ? {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeNP: "TimeNP",
      PosLat: "PosLat",
      PosLon: "PosLon",
      ETB: "ETB",
      VoyCode: "VoyCode",
      VoyTime: "VoyTime",
      VoyRobFO: "VoyRobFO",
      VoyRobDO: "VoyRobDO",
      LacanWater: "LacanWater",
      SlopWater: "SlopWater",
      FlowAENP: "FlowAENP",
      FlowBLRNP: "FlowBLRNP",
      FlowTempNP: "FlowTempNP",
      ConsFOAENP: "ConsFOAENP",
      ConsDOAENP: "ConsDOAENP",
      ConsFOBLRNP: "ConsFOBLRNP",
      ConsDOBLRNP: "ConsDOBLRNP",
      ConsCylNP: "ConsCylNP",
      ConsSysNP: "ConsSysNP",
      ConsAENP: "ConsAENP",
      ConsHydNP: "ConsHydNP",
      FWPureNP: "FWPureNP",
      RobFONP: "RobFONP",
      RobDONP: "RobDONP",
      RobLONP: "RobLONP",
      RobFWNP: "RobFWNP",
      RunhMENP: "RunhMENP",
      RunhAE1NP: "RunhAE1NP",
      RunhAE2NP: "RunhAE2NP",
      RunhAE3NP: "RunhAE3NP",
      RunhIGNP: "RunhIGNP",
      RepairLog: "RepairLog",
      Remark: "Remark",
    }
  : {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeNP: "TimeNP",
      PosLat: "PosLat",
      PosLon: "PosLon",
      ETB: "ETB",
      VoyCode: "VoyCode",
      VoyTime: "VoyTime",
      VoyRobFO: "VoyRobFO",
      VoyRobDO: "VoyRobDO",
      LacanWater: "LacanWater",
      SlopWater: "SlopWater",
      FlowAENP: "FlowAENP",
      FlowBLRNP: "FlowBLRNP",
      FlowTempNP: "FlowTempNP",
      ConsFOAENP: "ConsFOAENP",
      ConsDOAENP: "ConsDOAENP",
      ConsFOBLRNP: "ConsFOBLRNP",
      ConsDOBLRNP: "ConsDOBLRNP",
      ConsCylNP: "ConsCylNP",
      ConsSysNP: "ConsSysNP",
      ConsAENP: "ConsAENP",
      ConsHydNP: "ConsHydNP",
      FWPureNP: "FWPureNP",
      RobFONP: "RobFONP",
      RobDONP: "RobDONP",
      RobLONP: "RobLONP",
      RobFWNP: "RobFWNP",
      RunhMENP: "RunhMENP",
      RunhAE1NP: "RunhAE1NP",
      RunhAE2NP: "RunhAE2NP",
      RunhAE3NP: "RunhAE3NP",
      RunhIGNP: "RunhIGNP",
      RepairLog: "RepairLog",
      Remark: "Remark",
    };

const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class NoonAtPortReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        VoyageCode: null,
        ReportTypeID: null,
        ReportTime: null,
        TimeNP: null,
        PosLat: null,
        PosLon: null,
        ETB: null,
        VoyCode: null,
        VoyTime: null,
        VoyRobFO: null,
        VoyRobDO: null,
        LacanWater: null,
        SlopWater: null,
        FlowAENP: null,
        FlowBLRNP: null,
        FlowTempNP: null,
        ConsFOAENP: null,
        ConsDOAENP: null,
        ConsFOBLRNP: null,
        ConsDOBLRNP: null,
        ConsCylNP: null,
        ConsSysNP: null,
        ConsAENP: null,
        ConsHydNP: null,
        FWPureNP: null,
        RobFONP: null,
        RobDONP: null,
        RobLONP: null,
        RobFWNP: null,
        RunhMENP: null,
        RunhAE1NP: null,
        RunhAE2NP: null,
        RunhAE3NP: null,
        RunhIGNP: null,
        RepairLog: null,
        Remark: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_Voyage", () => {});
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //load du lieu randomm
  loadRandomSample() {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 5,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 5; //departure report
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_TempReport_Update",
            ThamSo: [
              //danh sach cac report update cung 1 luc'
              {
                ReportID: this.state.after.ReportID,
                VoyageCode: this.state.after.VoyageCode,
                JsonData: JSON.stringify(this.state.after),
                TempStatus: 0,
              },
            ],
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
            //close form
            this.props.close();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTypeID}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ReportTypeID"
                  key="ReportTypeID"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ReportTypeID}
                  onChange={(event) => {
                    this.saveField("ReportTypeID", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportTypeID !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="TimeNP"
                  key="TimeNP"
                  fullWidth
                  value={this.state.after.TimeNP}
                  onChange={(value) => {
                    this.saveField("TimeNP", value);
                  }}
                  error={this.state.validateStatus.TimeNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLat}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLat"
                  key="PosLat"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLat}
                  onChange={(event) => {
                    this.saveField("PosLat", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLat !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLon}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLon"
                  key="PosLon"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLon}
                  onChange={(event) => {
                    this.saveField("PosLon", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLon !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETB}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ETB"
                  key="ETB"
                  fullWidth
                  value={this.state.after.ETB}
                  onChange={(value) => {
                    this.saveField("ETB", value);
                  }}
                  error={this.state.validateStatus.ETB !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyCode}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyCode"
                  key="VoyCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyCode}
                  onChange={(event) => {
                    this.saveField("VoyCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="VoyTime"
                  key="VoyTime"
                  fullWidth
                  value={this.state.after.VoyTime}
                  onChange={(value) => {
                    this.saveField("VoyTime", value);
                  }}
                  error={this.state.validateStatus.VoyTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyRobFO}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyRobFO"
                  key="VoyRobFO"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyRobFO}
                  onChange={(event) => {
                    this.saveField("VoyRobFO", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyRobFO !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyRobDO}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyRobDO"
                  key="VoyRobDO"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyRobDO}
                  onChange={(event) => {
                    this.saveField("VoyRobDO", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyRobDO !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.LacanWater}
                  onChange={(event) => {
                    this.saveField("LacanWater", event.target.value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SlopWater}
                  onChange={(event) => {
                    this.saveField("SlopWater", event.target.value);
                  }}
                  error={this.state.validateStatus.SlopWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowAENP"
                  key="FlowAENP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowAENP}
                  onChange={(event) => {
                    this.saveField("FlowAENP", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowAENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowBLRNP"
                  key="FlowBLRNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowBLRNP}
                  onChange={(event) => {
                    this.saveField("FlowBLRNP", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowBLRNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowTempNP"
                  key="FlowTempNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowTempNP}
                  onChange={(event) => {
                    this.saveField("FlowTempNP", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowTempNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOAENP"
                  key="ConsFOAENP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOAENP}
                  onChange={(event) => {
                    this.saveField("ConsFOAENP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOAENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOAENP"
                  key="ConsDOAENP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOAENP}
                  onChange={(event) => {
                    this.saveField("ConsDOAENP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOAENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOBLRNP"
                  key="ConsFOBLRNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOBLRNP}
                  onChange={(event) => {
                    this.saveField("ConsFOBLRNP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOBLRNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOBLRNP"
                  key="ConsDOBLRNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOBLRNP}
                  onChange={(event) => {
                    this.saveField("ConsDOBLRNP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsCylNP"
                  key="ConsCylNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsCylNP}
                  onChange={(event) => {
                    this.saveField("ConsCylNP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsCylNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsSysNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsSysNP"
                  key="ConsSysNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsSysNP}
                  onChange={(event) => {
                    this.saveField("ConsSysNP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsSysNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsAENP"
                  key="ConsAENP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsAENP}
                  onChange={(event) => {
                    this.saveField("ConsAENP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsAENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsHydNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsHydNP"
                  key="ConsHydNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsHydNP}
                  onChange={(event) => {
                    this.saveField("ConsHydNP", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsHydNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FWPureNP"
                  key="FWPureNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FWPureNP}
                  onChange={(event) => {
                    this.saveField("FWPureNP", event.target.value);
                  }}
                  error={this.state.validateStatus.FWPureNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFONP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFONP"
                  key="RobFONP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFONP}
                  onChange={(event) => {
                    this.saveField("RobFONP", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFONP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDONP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobDONP"
                  key="RobDONP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobDONP}
                  onChange={(event) => {
                    this.saveField("RobDONP", event.target.value);
                  }}
                  error={this.state.validateStatus.RobDONP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobLONP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobLONP"
                  key="RobLONP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobLONP}
                  onChange={(event) => {
                    this.saveField("RobLONP", event.target.value);
                  }}
                  error={this.state.validateStatus.RobLONP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFWNP"
                  key="RobFWNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFWNP}
                  onChange={(event) => {
                    this.saveField("RobFWNP", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFWNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhMENP"
                  key="RunhMENP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhMENP}
                  onChange={(event) => {
                    this.saveField("RunhMENP", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhMENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1NP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE1NP"
                  key="RunhAE1NP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE1NP}
                  onChange={(event) => {
                    this.saveField("RunhAE1NP", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE1NP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE2NP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE2NP"
                  key="RunhAE2NP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE2NP}
                  onChange={(event) => {
                    this.saveField("RunhAE2NP", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE2NP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE3NP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE3NP"
                  key="RunhAE3NP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE3NP}
                  onChange={(event) => {
                    this.saveField("RunhAE3NP", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE3NP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhIGNP"
                  key="RunhIGNP"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhIGNP}
                  onChange={(event) => {
                    this.saveField("RunhIGNP", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhIGNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RepairLog}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RepairLog"
                  key="RepairLog"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RepairLog}
                  onChange={(event) => {
                    this.saveField("RepairLog", event.target.value);
                  }}
                  error={this.state.validateStatus.RepairLog !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  NoonAtPortReportEditDialog
);
