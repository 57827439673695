
import React from "react";
export default class TestComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {count: 1};
        console.log("constructor 1");
    }
    componentDidMount() {
        console.log("did mount");
    }
    componentDidUpdate(props, state) {
        console.log("did update");
    }
    componentWillUnmount() {
        console.log("will unmount");
    }
    render() {
        console.log("render");
        return <div>Hello {this.state.count}</div>
    }
}