import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  OutlinedInput,
  Grid,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, withStyles } from "@mui/styles";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/theme/blue-dark.css";
import "@inovua/reactdatagrid-community/theme/amber-light.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportDataEditDialog from "./PhanMemEditDialog";
import SimpleDialog from "../components/SimpleDialog";
import { filterStyles } from "../components/common";
import moment from "moment";
import SelectFilter from "react-select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ClosedCaptionDisabledOutlined } from "@mui/icons-material";
import { DatePicker, TreeView, TreeItem } from "@mui/lab";
import QuyenEditDialog from "./QuyenEditDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const styles = (theme) => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  colorBlue: {
    color: "blue",
  },
});

const PhanLoaiBenCang = [
  "Vùng đón trả hoa tiêu",
  "Cảng thường",
  "Vùng neo",
  "Phân cảng dầu khí",
  "",
  "Bến phao",
];
const columns_grid = [
  {
    name: "SCD",
    header: "Mã PM",
    headerAlign: "center",
    defaultWidth: 85,
  },
  {
    name: "SNM",
    header: "Tên phần mềm",
    headerAlign: "center",
    defaultWidth: 350,
  },
  {
    name: "UPUSCD",
    header: "Người CN",
    headerAlign: "center",
    defaultWidth: 120,
  },
  {
    name: "UPTM",
    header: "Ngày CN",
    headerAlign: "center",
    defaultWidth: 150,
    render: (props) => moment(props.data.UPTM).format("DD/MM/YYYY HH:mm"),
  },
];
const defaultSortInfo = { name: "TenCang", dir: -1, type: "string" };
const loadDataError = (error, empty, log, trace) => {
  return !error ? (
    <b
      style={{
        padding: 8,
        border: "1px solid #7986cb",
        color: "#0000ff",
        borderRadius: 4,
      }}
    >
      {error ? log : empty ? "Không có bản ghi nào!" : ""}
    </b>
  ) : (
    <a
      href="#"
      style={{ textDecoration: "none" }}
      onClick={() =>
        toast.error(trace, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      }
    >
      <b
        style={{
          padding: 8,
          border: "1px solid #7986cb",
          color: "red",
          borderRadius: 4,
        }}
      >
        {error ? log : empty ? "Không có bản ghi nào!" : ""}
      </b>
    </a>
  );
};
class QuyenPhanMemDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      saving: false, //co dang save du lieu hay khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      loading_coquan: true, // co dang load ding danh muc nguon tin ko
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong

      source_danhmuc: {
        CangBien: undefined,
        CangBien_Filter: undefined,
        KhuVuc: undefined,
      },
      listData: [],
      after: {
        PageIndex: 1,
        PageSize: 1000,
        Ascending: true,
        SortBy: 0, //0-tentau,1-sogcn
        SCD: props.PhanMem.SCD, //dang cap nhat cho phan mem nao
      },

      expandedIds: [], //danh sach id expand
      expandedGroupIds: [], //danh sach id
      DanhSachQuyen: [], //danh sach quyen phan mem
      selectedId: "",
      rowSelected: undefined, //dang lua chon row nao
      showEditDialog: false, // hien thi form cap nhat hay khong
      edittingRecord: undefined, //ban ghi chon de edit
      showConfirmXoa: false, //hien thi dialog xoa
      hasQuyen: global.hasRoles("Admin"),
      open_show_edit_quyen: false, //hien thi form nhap quyen phan mem
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  handleClose(selection) {
    this.props.close(selection);
  }
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);

      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  saveField = (fieldName, value) => {
    var s = this.state;
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    }
    if (fieldName !== "PageSize" && fieldName !== "PageIndex") {
      this.setState(this.state);
    } else {
      this.setState(this.state, () => {
        this.doSearch();
      });
    }
    this.setState(this.state);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  loadDanhMuc() {
    this.doSearch();
  }

  renderTree = (nodeData, listQuyens, PhanMem, classes) => {
    var subList = [];
    //tim danh sach con
    listQuyens.map((item, index) => {
      if (
        (nodeData.RTCD === null && (item.GRCD === null || item.GRCD === '')) ||
        item.GRCD === nodeData.RTCD
      ) {
        subList.push(item);
      }
    });
    var isRoot = nodeData.RTCD === null;
    var nodeName = nodeData.RTCD === null ? PhanMem.SNM : nodeData.RTNM;
    var nodeId = nodeData.RTCD === null ? PhanMem.SCD : nodeData.RTCD;
    var isChecked = nodeId === this.state.selectedId;
    var isRight = nodeData.ISRT === "1";
    var has = true;
    console.log(nodeId);
    console.log(subList);
    return (
      <TreeItem
        key={nodeId}
        nodeId={nodeId}
        label={
          isRoot ? (
            <FormLabel>{nodeName}</FormLabel>
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  readOnly={!has}
                  size="small"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                  checked={isChecked}
                  onChange={(event) => {
                    this.saveField("rights", nodeId, event.target.checked);
                  }}
                />
              }
              label={
                <Typography className={isRight ? undefined : classes.quantrong}>
                  {nodeId} - {nodeName}
                </Typography>
              }
            />
          )
        }
      >
        {subList.length > 0
          ? subList.map((item, index) =>
              this.renderTree(item, listQuyens, PhanMem, classes)
            )
          : null}
      </TreeItem>
    );
  };
  onNodeSelect = (event, listIds) => {
    this.setState({ selectedId: listIds });
  };
  doSearch = () => {
    this.setState({ loading_data: true });
    server
      .query("PhanQuyen/GetQuyenPhanMem", {
        scd: this.props.PhanMem.SCD,
      })
      .then((response) => {
        var listExpands = [this.props.PhanMem.SCD];
        response.map((item) => listExpands.push(item.RTCD));
        this.setState({
          DanhSachQuyen: response,
          expandedIds: listExpands,
          search_error: false,
          rowSelected: undefined,
          loading_data: false,
        });
      })
      .catch((error) => {
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          message = obj.message;
          log = obj.traceLog;
        } catch (e) {
          message = error + "";
        }
        this.setState({
          search_error: true,
          errorMessage: message,
          errorLog: log,
          search_result: { data: [], count: 0 },
          rowSelected: undefined,
          loading_data: false,
        });
      });
  };
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AppBar sx={{ position: "relative" }}>
            <Stack direction="row">
              <Typography
                sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
                variant="h6"
                component="div"
              >
                Danh sách quyền phần mềm : {this.props.PhanMem.SNM}
              </Typography>
              <Stack direction="row" spacing={4}>
                {this.state.loading_data ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.saving ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    this.setState({ open_show_edit_quyen: true });
                  }}
                >
                  Thêm
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    this.state.selectedId === undefined ||
                    this.state.selectedId == this.props.PhanMem.SCD
                  }
                  onClick={() => {
                    //xac dinh du lieu
                    var data = this.state.DanhSachQuyen.find(
                      (item) => item.RTCD == this.state.selectedId
                    );
                    if (data) {
                      this.setState({
                        open_show_edit_quyen: true,
                        edittingRecord: data,
                      });
                    }
                  }}
                >
                  Sửa
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    this.state.selectedId === undefined ||
                    this.state.selectedId == this.props.PhanMem.SCD
                  }
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  Xóa
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.handleClose(undefined)}
                >
                  Thoát
                </Button>
              </Stack>
            </Stack>
          </AppBar>
          <Paper variant="outlined">
            <Grid container spacing={1} columns={12}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon fontSize="small" />}
                  onClick={this.doSearch}
                >
                  Tìm kiếm
                </Button>
              </Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={12}>
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={[]}
                  expanded={this.state.expandedIds}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: 510,
                    flexGrow: 1,
                    overflowY: "auto",
                    backgroundColor: "aliceblue",
                  }}
                  onNodeSelect={this.onNodeSelect}
                >
                  {this.renderTree(
                    { RTCD: null, RTNM: null },
                    this.state.DanhSachQuyen,
                    this.props.PhanMem,
                    classes
                  )}
                </TreeView>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        {this.state.open_show_edit_quyen ? (
          <QuyenEditDialog
            open={true}
            PhanMem={this.props.PhanMem}
            MaQuyenCha={this.state.selectedId === this.props.PhanMem.SCD? "" : this.state.selectedId}
            edittingRecord={this.state.edittingRecord}
            close={(record) => {
              this.setState(
                {
                  open_show_edit_quyen: false,
                  edittingRecord: undefined,
                },
                () => this.doSearch()
              );
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa mã quyền:" + this.state.selectedId + " ?"}
            open={true}
            close={(ok) => {
              if (ok) {
                //thuc hien xoa
                server
                  .post("PhanQuyen/XoaQuyenPhanMem", {
                    SCD: this.props.PhanMem.SCD,
                    RTCD: this.state.selectedId,
                  })
                  .then((response) => {
                    this.setState({ showConfirmXoa: false });
                    //reload
                    this.doSearch();
                  })
                  .catch((error) => {
                    this.setState({ showConfirmXoa: false });
                    this.handleServerError(error);
                  });
              } else {
                this.setState({ showConfirmXoa: false });
              }
            }}
          />
        ) : null}
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(QuyenPhanMemDialog);
