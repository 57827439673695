import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  FormControl,
  Typography,
  Link,
  ListItemButton,
} from "@mui/material";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  ReactDataGrid_default,
  showError,
} from "../components/common";
import localConfig from "local-storage";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";

import DepartureReportEditDialog from "./DepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import CheckResultControl from "./CheckResultControl";
import ShowReportFormDialog from "./ShowReportFormDialog";
import ReportFormEditDialog from "./ReportFormEditDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage number:",
      ReportTypeID: "Report type:",
      ReportTime: "Report time",
      ReportTypeID: "Report type",
    }
  : {
      VoyageCode: "Số chuyến đi:",
      ReportTypeID: "Loại báo cáo:",
      ReportTime: "Thời gian:",
      ReportTypeID: "Loại báo cáo",
    };

const buttonLabels = englishUI
  ? {
      btnThem: "Add",
      btnRead: "Read",
      btnApprove: "Approve/Cancel",
      btnTimKiem: "Search",
      btnReportError: "Failed Report",
      btnXuly: "Process",
    }
  : {
      btnThem: "Thêm",
      btnRead: "Đã đọc",
      btnApprove: "Duyệt/Hủy",
      btnTimKiem: "Tìm kiếm",
      btnReportError: "BC lỗi",
      btnXuly: "Xử lý",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/

////////////////////////////////////////////////////////////////////////////
class ReportingPage extends React.Component {
  constructor(props) {
    super(props);

    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      DanhMuc: {
        DM_ReportForm: [],
      },
      IsAdmin: global.hasRoles("Admin") || global.hasRoles("System"),
    };
  }

  //su kien form load
  componentDidMount() {
    this.loadDanhMuc(() => {});
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {}
  //load du lieu danh muc theo danh sach table
  loadDanhMuc() {
    //load danh muc
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_ReportForm_GetAll",
        ThamSo: {},
      })
      .then((response) => {
        var list = response.DM_ReportForm;
        var newList = [];
        list.map((report) => {
          //console.log(report, global.hasRoles(report.RightID));
          if (
            report.RightID == null ||
            report.RightID === "" ||
            global.hasRoles(report.RightID)
          ) {
 
            newList.push(report);
            report.ReportFormTitle = newList.length + ". " + report.ReportFormTitle;

          }
        });
        //console.log(newList);
        this.setState({
          DanhMuc: {
            ...this.state.DanhMuc,
            DM_ReportForm: newList,
          },
        });
      })
      .catch((error) => {
        showError(error);
      });
  }

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            {this.state.DanhMuc.DM_ReportForm.map((form) => (
              <>
                <Grid item xs={10}>
                  <ListItemButton
                    fullWidth
                    onClick={() => {
                      this.setState({
                        show_view_reportform: true,
                        edittingRecord: form,
         
                      });
                    }}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      this.setState({
                        show_edit_reportform: this.state.IsAdmin,
                        edittingRecord: form,
                      });
                    }}
                  >
                    {form.ReportFormTitle}
                  </ListItemButton>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid container spacing={2} columns={24}>
            <Grid item xs={22}></Grid>
            <Grid item xs={2}>
              {this.state.IsAdmin ? (
                <Button
                  onClick={() => {
                    this.setState({ show_edit_reportform: true });
                  }}
                >
                  +
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <ToastContainer
            position="top-right"
            autoClose={false}
            hideProgressBar
            newestOnTop={true}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover={false}
            style={{ fontSize: 12, width: 750 }}
            limit={5}
          />
        </Paper>
        {this.state.show_edit_reportform ? (
          <ReportFormEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ show_edit_reportform: false }, () =>
                this.loadDanhMuc()
              );
            }}
          />
        ) : null}
        {this.state.show_view_reportform ? (
          <ShowReportFormDialog
            open={true}

            edittingRecord={this.state.edittingRecord}
            ShipData={this.props.ShipData}
            ShipID={this.props.ShipData.ShipID}
            close={() => {
              this.setState({ show_view_reportform: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ReportingPage);
