import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Departure Report" : "Báo cáo tàu đến cảng - DR";
const fieldLabels = englishUI
  ? {
    VoyageCode: "VoyageCode",
    ReportTypeID: "ReportTypeID",
    ReportTime: "ReportTime",
    TimeDD: "TimeDD",
    PilotTime: "PilotTime",
    TimeDC: "TimeDC",
    Position: "Position",
    Tugs: "Tugs used/hours/power",
    TugTime: "",
    TugPower: "",
    VoyTime: "VoyTime",
    VoyRobFO: "VoyRobFO",
    VoyRobDO: "VoyRobDO",
    ForeDraft: "Draft F/A",
    AftDraft: "",
    CargoMT: "CargoMT",
    DisToGo: "DisToGo",
    ETA: "ETA",
    WeatherSea: "Weather (Sea/Wind)",
    WeatherWind: "",
    LacanWater: "LacanWater",
    SlopWater: "SlopWater",
    RobFODD: "ROB-DD:FO/DO",
    RobDODD: "",
    RobSumpDD: "LO",
    RobSysDD: "",
    RobCylDD: "",
    RobAEDD: "",
    RobHydDD: "",
    RobFWDD: "RobFWDD",
    RobFODC: "ROB-DC:FO/DO",
    RobDODC: "",
    RobSumpDC: "LO",
    RobSysDC: "",
    RobCylDC: "",
    RobAEDC: "",
    RobHydDC: "",
    RobFWDC: "RobFWDC",
    RpmCounterMEDD: "M/E RPM CTR DD",
    RpmCounterMEDC: "M/E RPM CTR DC",
    FlowMEDD: "F.M ME/AE/BLR",
    FlowAEDD: "",
    FlowBLRDD: "",
    FlowTempDD: "Temp F.M/ME",
    METempDD: "",
    FlowMEDC: "F.M ME/AE/BLR",
    FlowAEDC: "",
    FlowBLRDC: "",
    FlowTempDC: "Temp F.M/ME",
    METempDC: "",
    ConsFOBLRDD: "BLR(FO/DO)",
    ConsDOBLRDD: "",
    ConsFOAEDD: "Cons(DD): AE(FO/DO)",
    ConsDOAEDD: "",
    ConsCylDD: "LO",
    ConsSysDD: "",
    ConsAEDD: "",
    ConsHydDD: "",
    ConsFOMEDC: "ME (FO/DO)",
    ConsDOMEDC: "",
    ConsFOBLRDC: "BLR(FO/DO)",
    ConsDOBLRDC: "",
    ConsFOAEDC: "Cons(DC): AE(FO/DO)",
    ConsDOAEDC: "",
    ConsCylDC: "LO",
    ConsSysDC: "",
    ConsAEDC: "",
    ConsHydDC: "",
    RunhAE1DD: "Running.Hr(DD): AE1/AE2/AE3",
    RunhAE2DD: "",
    RunhAE3DD: "",
    RunhIGDD: "IG",
    RunhMEDC: "ME",
    RunhAE1DC: "Running.Hr(DC): AE1/AE2/AE3",
    RunhAE2DC: "",
    RunhAE3DC: "",
    RunhIGDC: "IG",
    ReceiveDOFO: "Receive (Date/FO/DO/LOCyl/LOsys/FW)",
    RepairLog: "RepairLog",
    MaterialLog: "MaterialLog",
    Remark: "Remark",
  }
  : {
    VoyageCode: "VoyageCode",
    ReportTypeID: "ReportTypeID",
    ReportTime: "ReportTime",
    TimeDD: "TimeDD",
    PilotTime: "PilotTime",
    TimeDC: "TimeDC",
    Position: "Position",
    Tugs: "Tugs used/hours/power",
    TugTime: "",
    TugPower: "",
    VoyTime: "VoyTime",
    VoyRobFO: "VoyRobFO",
    VoyRobDO: "VoyRobDO",
    ForeDraft: "Draft F/A",
    AftDraft: "",
    CargoMT: "CargoMT",
    DisToGo: "DisToGo",
    ETA: "ETA",
    WeatherSea: "Weather (Sea/Wind)",
    WeatherWind: "",
    LacanWater: "LacanWater",
    SlopWater: "SlopWater",
    RobFODD: "ROB-DD:FO/DO",
    RobDODD: "",
    RobSumpDD: "LO",
    RobSysDD: "",
    RobCylDD: "",
    RobAEDD: "",
    RobHydDD: "",
    RobFWDD: "RobFWDD",
    RobFODC: "ROB-DC:FO/DO",
    RobDODC: "",
    RobSumpDC: "LO",
    RobSysDC: "",
    RobCylDC: "",
    RobAEDC: "",
    RobHydDC: "",
    RobFWDC: "RobFWDC",
    RpmCounterMEDD: "RpmCounterMEDD",
    RpmCounterMEDC: "RpmCounterMEDC",
    FlowMEDD: "F.M ME/AE/BLR",
    FlowAEDD: "",
    FlowBLRDD: "",
    FlowTempDD: "Temp F.M/ME",
    METempDD: "",
    FlowMEDC: "F.M ME/AE/BLR",
    FlowAEDC: "",
    FlowBLRDC: "",
    FlowTempDC: "Temp F.M/ME",
    METempDC: "",
    ConsFOBLRDD: "BLR(FO/DO)",
    ConsDOBLRDD: "",
    ConsFOAEDD: "Cons (DD): AE(FO/DO)",
    ConsDOAEDD: "",
    ConsCylDD: "LO",
    ConsSysDD: "",
    ConsAEDD: "",
    ConsFOMEDC: "ME",
    ConsDOMEDC: "",
    ConsFOBLRDC: "BLR(FO/DO)",
    ConsDOBLRDC: "",
    ConsFOAEDC: "Cons(DC): AE(FO/DO)",
    ConsDOAEDC: "",
    ConsCylDC: "LO",
    ConsSysDC: "",
    ConsAEDC: "",
    ConsHydDC: "",
    RunhAE1DD: "Running.Hr(DD): AE1/AE2/AE3",
    RunhAE2DD: "",
    RunhAE3DD: "",
    RunhIGDD: "IG",
    RunhMEDC: "ME",
    RunhAE1DC: "Running.Hr(DC): AE1/AE2/AE3",
    RunhAE2DC: "",
    RunhAE3DC: "",
    RunhIGDC: "IG",
    ReceiveDOFO: "Receive (Date/FO/DO/LOCyl/LOsys/FW)",
    RepairLog: "RepairLog",
    MaterialLog: "MaterialLog",
    Remark: "Remark",
  };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class DepartureReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        VoyageCode: null,
        ReportTypeID: null,
        ReportTime: null,
        TimeDD: null,
        PilotTime: null,
        TimeDC: null,
        PosLat: null,
        PosLon: null,
        Tugs: null,
        TugTime: null,
        TugPower: null,
        VoyTime: null,
        VoyRobFO: null,
        VoyRobDO: null,
        ForeDraft: null,
        AftDraft: null,
        CargoMT: null,
        DisToGo: null,
        ETA: null,
        WeatherSea: null,
        WeatherWind: null,
        LacanWater: null,
        SlopWater: null,
        RobFODD: null,
        RobDODD: null,
        RobSumpDD: null,
        RobSysDD: null,
        RobCylDD: null,
        RobAEDD: null,
        RobHydDD: null,
        RobFWDD: null,
        RobFODC: null,
        RobDODC: null,
        RobSumpDC: null,
        RobSysDC: null,
        RobCylDC: null,
        RobAEDC: null,
        RobHydDC: null,
        RobFWDC: null,
        RpmCounterMEDD: null,
        RpmCounterMEDC: null,
        FlowMEDD: null,
        FlowAEDD: null,
        FlowBLRDD: null,
        FlowTempDD: null,
        METempDD: null,
        FlowMEDC: null,
        FlowAEDC: null,
        FlowBLRDC: null,
        FlowTempDC: null,
        METempDC: null,
        ConsFOBLRDD: null,
        ConsDOBLRDD: null,
        ConsFOAEDD: null,
        ConsDOAEDD: null,
        ConsCylDD: null,
        ConsSysDD: null,
        ConsAEDD: null,
        ConsHydDD: null,
        ConsFOMEDC: null,
        ConsDOMEDC: null,
        ConsFOBLRDC: null,
        ConsDOBLRDC: null,
        ConsFOAEDC: null,
        ConsDOAEDC: null,
        ConsCylDC: null,
        ConsSysDC: null,
        ConsAEDC: null,
        ConsHydDC: null,
        RunhAE1DD: null,
        RunhAE2DD: null,
        RunhAE3DD: null,
        RunhIGDD: null,
        RunhMEDC: null,
        RunhAE1DC: null,
        RunhAE2DC: null,
        RunhAE3DC: null,
        RunhIGDC: null,
        ReceiveDOFO: [],
        RepairLog: null,
        MaterialLog: null,
        Remark: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_Voyage", () => {});
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //load du lieu randomm
  loadRandomSample() {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 1,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 1; //departure report
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_TempReport_Update",
            ThamSo: [
              //danh sach cac report update cung 1 luc'
              {
                ReportID: this.state.after.ReportID,
                VoyageCode: this.state.after.VoyageCode,
                JsonData: JSON.stringify(this.state.after),
                TempStatus: 0,
              },
            ],
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
            //close form
            this.props.close();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <FormLabel>NỘI DUNG BÁO CÁO</FormLabel>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>A-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.TimeDD}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeDD"
                  key="TimeDD"
                  fullWidth
                  value={this.state.after.TimeDD}
                  onChange={(value) => {
                    this.saveField("TimeDD", value);
                  }}
                  error={this.state.validateStatus.TimeDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.PilotTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime"
                  key="PilotTime"
                  fullWidth
                  value={this.state.after.PilotTime}
                  onChange={(value) => {
                    this.saveField("PilotTime", value);
                  }}
                  error={this.state.validateStatus.PilotTime !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.TimeDC}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeDC"
                  key="TimeDC"
                  fullWidth
                  value={this.state.after.TimeDC}
                  onChange={(value) => {
                    this.saveField("TimeDC", value);
                  }}
                  error={this.state.validateStatus.TimeDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.Position}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={event => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <FormLabel>{fieldLabels.Tugs}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Tugs"
                  key="Tugs"
                  fullWidth
                  placeholder="tugs"
                  autoFocus={false}
                  value={this.state.after.Tugs}
                  onChange={(event) => {
                    this.saveField("Tugs", event.target.value);
                  }}
                  error={this.state.validateStatus.Tugs !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TugTime"
                  key="TugTime"
                  fullWidth
                  placeholder="hours"
                  autoFocus={false}
                  value={this.state.after.TugTime}
                  onChange={(event) => {
                    this.saveField("TugTime", event.target.value);
                  }}
                  error={this.state.validateStatus.TugTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TugPower"
                  key="TugPower"
                  fullWidth
                  placeholder="power"
                  autoFocus={false}
                  value={this.state.after.TugPower}
                  onChange={(event) => {
                    this.saveField("TugPower", event.target.value);
                  }}
                  error={this.state.validateStatus.TugPower !== undefined}
                />
              </Grid>
            </Grid>


            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>B-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ForeDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder="Draft F"
                  decimalScale={0}
                  value={this.state.after.ForeDraft}
                  onChange={value => {
                    this.saveField("ForeDraft", value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder="Draft A"
                  autoFocus={false}
                  value={this.state.after.AftDraft}
                  onChange={value => {
                    this.saveField("AftDraft", value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.CargoMT}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="CargoMT"
                  key="CargoMT"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.CargoMT}
                  onChange={(event) => {
                    this.saveField("CargoMT", event.target.value);
                  }}
                  error={this.state.validateStatus.CargoMT !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.DisToGo}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="DisToGo"
                  key="DisToGo"
                  fullWidth
                  decimalScale={2}
                  autoFocus={false}
                  value={this.state.after.DisToGo}
                  onChange={value => {
                    this.saveField("DisToGo", value);
                  }}
                  error={this.state.validateStatus.DisToGo !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ETA}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETA"
                  key="ETA"
                  fullWidth
                  value={this.state.after.ETA}
                  onChange={(value) => {
                    this.saveField("ETA", value);
                  }}
                  error={this.state.validateStatus.ETA !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>C-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.WeatherSea}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherSea"
                  key="WeatherSea"
                  fullWidth
                  placeholder="Sea"
                  autoFocus={false}
                  value={this.state.after.WeatherSea}
                  onChange={(event) => {
                    this.saveField("WeatherSea", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherSea !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherWind"
                  key="WeatherWind"
                  fullWidth
                  placeholder="Wind"
                  autoFocus={false}
                  value={this.state.after.WeatherWind}
                  onChange={(event) => {
                    this.saveField("WeatherWind", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherWind !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  decimalScale={2}
                  autoFocus={false}
                  value={this.state.after.LacanWater}
                  onChange={value => {
                    this.saveField("LacanWater", value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  decimalScale={2}
                  autoFocus={false}
                  value={this.state.after.SlopWater}
                  onChange={value => {
                    this.saveField("SlopWater", value);
                  }}
                  error={this.state.validateStatus.SlopWater !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>D-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RobFODD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFODD"
                  key="RobFODD"
                  fullWidth
                  decimalScale={2}
                  placeholder="FO"
                  value={this.state.after.RobFODD}
                  onChange={value => {
                    this.saveField("RobFODD", value);
                  }}
                  error={this.state.validateStatus.RobFODD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDODD"
                  key="RobDODD"
                  fullWidth
                  decimalScale={2}
                  placeholder="DO"
                  value={this.state.after.RobDODD}
                  onChange={value => {
                    this.saveField("RobDODD", value);
                  }}
                  error={this.state.validateStatus.RobDODD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RobSumpDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpDD"
                  key="RobSumpDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="Sump"
                  value={this.state.after.RobSumpDD}
                  onChange={value => {
                    this.saveField("RobSumpDD", value);
                  }}
                  error={this.state.validateStatus.RobSumpDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysDD"
                  key="RobSysDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="Sys"
                  value={this.state.after.RobSysDD}
                  onChange={value => {
                    this.saveField("RobSysDD", value);
                  }}
                  error={this.state.validateStatus.RobSysDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylDD"
                  key="RobCylDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="Cyl"
                  value={this.state.after.RobCylDD}
                  onChange={value => {
                    this.saveField("RobCylDD", value);
                  }}
                  error={this.state.validateStatus.RobCylDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEDD"
                  key="RobAEDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE"
                  value={this.state.after.RobAEDD}
                  onChange={value => {
                    this.saveField("RobAEDD", value);
                  }}
                  error={this.state.validateStatus.RobAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydDD"
                  key="RobHydDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="Hyd"
                  value={this.state.after.RobHydDD}
                  onChange={value => {
                    this.saveField("RobHydDD", value);
                  }}
                  error={this.state.validateStatus.RobHydDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWDD"
                  key="RobFWDD"
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.RobFWDD}
                  onChange={value => {
                    this.saveField("RobFWDD", value);
                  }}
                  error={this.state.validateStatus.RobFWDD !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RobFODC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFODC"
                  key="RobFODC"
                  fullWidth
                  decimalScale={2}
                  placeholder="FO"
                  value={this.state.after.RobFODC}
                  onChange={value => {
                    this.saveField("RobFODC", value);
                  }}
                  error={this.state.validateStatus.RobFODC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDODC"
                  key="RobDODC"
                  fullWidth
                  decimalScale={2}
                  placeholder="DO"
                  value={this.state.after.RobDODC}
                  onChange={value => {
                    this.saveField("RobDODC", value);
                  }}
                  error={this.state.validateStatus.RobDODC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RobSumpDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpDC"
                  key="RobSumpDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="Sump"
                  value={this.state.after.RobSumpDC}
                  onChange={value => {
                    this.saveField("RobSumpDC", value);
                  }}
                  error={this.state.validateStatus.RobSumpDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysDC"
                  key="RobSysDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="Sys"
                  value={this.state.after.RobSysDC}
                  onChange={value => {
                    this.saveField("RobSysDC", value);
                  }}
                  error={this.state.validateStatus.RobSysDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylDC"
                  key="RobCylDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="Cyl"
                  value={this.state.after.RobCylDC}
                  onChange={value => {
                    this.saveField("RobCylDC", value);
                  }}
                  error={this.state.validateStatus.RobCylDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEDC"
                  key="RobAEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE"
                  value={this.state.after.RobAEDC}
                  onChange={value => {
                    this.saveField("RobAEDC", value);
                  }}
                  error={this.state.validateStatus.RobAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydDC"
                  key="RobHydDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="Hyd"
                  value={this.state.after.RobHydDC}
                  onChange={value => {
                    this.saveField("RobHydDC", value);
                  }}
                  error={this.state.validateStatus.RobHydDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWDC"
                  key="RobFWDC"
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.RobFWDC}
                  onChange={value => {
                    this.saveField("RobFWDC", value);
                  }}
                  error={this.state.validateStatus.RobFWDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>E-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMEDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEDD"
                  key="RpmCounterMEDD"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RpmCounterMEDD}
                  onChange={value => {
                    this.saveField("RpmCounterMEDD", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowMEDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEDD"
                  key="FlowMEDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="ME"
                  value={this.state.after.FlowMEDD}
                  onChange={value => {
                    this.saveField("FlowMEDD", value);
                  }}
                  error={this.state.validateStatus.FlowMEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEDD"
                  key="FlowAEDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE"
                  value={this.state.after.FlowAEDD}
                  onChange={value => {
                    this.saveField("FlowAEDD", value);
                  }}
                  error={this.state.validateStatus.FlowAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRDD"
                  key="FlowBLRDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="BLR"
                  value={this.state.after.FlowBLRDD}
                  onChange={value => {
                    this.saveField("FlowBLRDD", value);
                  }}
                  error={this.state.validateStatus.FlowBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowTempDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempDD"
                  key="FlowTempDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="F.M"
                  value={this.state.after.FlowTempDD}
                  onChange={value => {
                    this.saveField("FlowTempDD", value);
                  }}
                  error={this.state.validateStatus.FlowTempDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempDD"
                  key="METempDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="ME"
                  value={this.state.after.METempDD}
                  onChange={value => {
                    this.saveField("METempDD", value);
                  }}
                  error={this.state.validateStatus.METempDD !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMEDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEDC"
                  key="RpmCounterMEDC"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RpmCounterMEDC}
                  onChange={value => {
                    this.saveField("RpmCounterMEDC", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowMEDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEDC"
                  key="FlowMEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="ME"
                  value={this.state.after.FlowMEDC}
                  onChange={value => {
                    this.saveField("FlowMEDC", value);
                  }}
                  error={this.state.validateStatus.FlowMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEDC"
                  key="FlowAEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE"
                  value={this.state.after.FlowAEDC}
                  onChange={value => {
                    this.saveField("FlowAEDC", value);
                  }}
                  error={this.state.validateStatus.FlowAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRDC"
                  key="FlowBLRDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="BLR"
                  value={this.state.after.FlowBLRDC}
                  onChange={value => {
                    this.saveField("FlowBLRDC", value);
                  }}
                  error={this.state.validateStatus.FlowBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowTempDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempDC"
                  key="FlowTempDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="F.M"
                  value={this.state.after.FlowTempDC}
                  onChange={value => {
                    this.saveField("FlowTempDC", value);
                  }}
                  error={this.state.validateStatus.FlowTempDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempDC"
                  key="METempDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="ME"
                  value={this.state.after.METempDC}
                  onChange={value => {
                    this.saveField("METempDC", value);
                  }}
                  error={this.state.validateStatus.METempDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAEDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEDD"
                  key="ConsFOAEDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="FO"
                  value={this.state.after.ConsFOAEDD}
                  onChange={value => {
                    this.saveField("ConsFOAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEDD"
                  key="ConsDOAEDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="DO"
                  value={this.state.after.ConsDOAEDD}
                  onChange={value => {
                    this.saveField("ConsDOAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRDD"
                  key="ConsFOBLRDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRDD}
                  onChange={value => {
                    this.saveField("ConsFOBLRDD", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRDD"
                  key="ConsDOBLRDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRDD}
                  onChange={value => {
                    this.saveField("ConsDOBLRDD", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.ConsCylDD}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylDD"
                  key="ConsCylDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="Cyl"
                  value={this.state.after.ConsCylDD}
                  onChange={value => {
                    this.saveField("ConsCylDD", value);
                  }}
                  error={this.state.validateStatus.ConsCylDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysDD"
                  key="ConsSysDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="Sys"
                  value={this.state.after.ConsSysDD}
                  onChange={value => {
                    this.saveField("ConsSysDD", value);
                  }}
                  error={this.state.validateStatus.ConsSysDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEDD"
                  key="ConsAEDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE"
                  value={this.state.after.ConsAEDD}
                  onChange={value => {
                    this.saveField("ConsAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydDD"
                  key="ConsHydDD"
                  fullWidth
                  decimalScale={2}
                  placeholder="Hyd"
                  value={this.state.after.ConsHydDD}
                  onChange={value => {
                    this.saveField("ConsHydDD", value);
                  }}
                  error={this.state.validateStatus.ConsHydDD !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid iten xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAEDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEDC"
                  key="ConsFOAEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="FO"
                  value={this.state.after.ConsFOAEDC}
                  onChange={value => {
                    this.saveField("ConsFOAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEDC"
                  key="ConsDOAEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="DO"
                  value={this.state.after.ConsDOAEDC}
                  onChange={value => {
                    this.saveField("ConsDOAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRDC"
                  key="ConsFOBLRDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRDC}
                  onChange={value => {
                    this.saveField("ConsFOBLRDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRDC"
                  key="ConsDOBLRDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRDC}
                  onChange={value => {
                    this.saveField("ConsDOBLRDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.ConsCylDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylDC"
                  key="ConsCylDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="Cyl"
                  value={this.state.after.ConsCylDC}
                  onChange={value => {
                    this.saveField("ConsCylDC", value);
                  }}
                  error={this.state.validateStatus.ConsCylDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysDC"
                  key="ConsSysDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="Sys"
                  value={this.state.after.ConsSysDC}
                  onChange={value => {
                    this.saveField("ConsSysDC", value);
                  }}
                  error={this.state.validateStatus.ConsSysDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEDC"
                  key="ConsAEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE"
                  value={this.state.after.ConsAEDC}
                  onChange={value => {
                    this.saveField("ConsAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydDC"
                  key="ConsHydDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="Hyd"
                  value={this.state.after.ConsHydDC}
                  onChange={value => {
                    this.saveField("ConsHydDC", value);
                  }}
                  error={this.state.validateStatus.ConsHydDC !== undefined}
                />
              </Grid></Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEDC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEDC"
                  key="ConsFOMEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="FO"
                  value={this.state.after.ConsFOMEDC}
                  onChange={value => {
                    this.saveField("ConsFOMEDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEDC"
                  key="ConsDOMEDC"
                  fullWidth
                  decimalScale={2}
                  placeholder="DO"
                  value={this.state.after.ConsDOMEDC}
                  onChange={value => {
                    this.saveField("ConsDOMEDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>F-</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.RunhAE1DD}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE1DD"
                  key="RunhAE1DD"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE1"
                  value={this.state.after.RunhAE1DD}
                  onChange={value => {
                    this.saveField("RunhAE1DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE1DD !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE2DD"
                  key="RunhAE2DD"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE2"
                  value={this.state.after.RunhAE2DD}
                  onChange={value => {
                    this.saveField("RunhAE2DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE2DD !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE3DD"
                  key="RunhAE3DD"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE3"
                  value={this.state.after.RunhAE3DD}
                  onChange={value => {
                    this.saveField("RunhAE3DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE3DD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGDD}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhIGDD"
                  key="RunhIGDD"
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.RunhIGDD}
                  onChange={value => {
                    this.saveField("RunhIGDD", value);
                  }}
                  error={this.state.validateStatus.RunhIGDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.RunhAE1DC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE1DC"
                  key="RunhAE1DC"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE1"
                  value={this.state.after.RunhAE1DC}
                  onChange={value => {
                    this.saveField("RunhAE1DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE1DC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE2DC"
                  key="RunhAE2DC"
                  fullWidth
                  decimalScale={1}
                  placeholder="AE2"
                  value={this.state.after.RunhAE2DC}
                  onChange={value => {
                    this.saveField("RunhAE2DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE2DC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE3DC"
                  key="RunhAE3DC"
                  fullWidth
                  decimalScale={2}
                  placeholder="AE3"
                  value={this.state.after.RunhAE3DC}
                  onChange={value => {
                    this.saveField("RunhAE3DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE3DC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGDC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhIGDC"
                  key="RunhIGDC"
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.RunhIGDC}
                  onChange={value => {
                    this.saveField("RunhIGDC", value);
                  }}
                  error={this.state.validateStatus.RunhIGDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhMEDC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhMEDC"
                  key="RunhMEDC"
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.RunhMEDC}
                  onChange={value => {
                    this.saveField("RunhMEDC", value);
                  }}
                  error={this.state.validateStatus.RunhMEDC !== undefined}
                />
              </Grid>
            </Grid>
            {this.state.after.ReceiveDOFO.map((item, index) => (
              <Grid container spacing={1} columns={24}>
                {index == 0 ? (<>
                  <Grid item xs={1}>
                    <FormLabel>G-</FormLabel>
                  </Grid>
                  <Grid item xs={5}>
                    <FormLabel>{fieldLabels.ReceiveDOFO}</FormLabel>
                  </Grid></>) : <Grid item xs={6}></Grid>}
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <MyDateEditor
                    id="ReceiveDOFODate"
                    key="ReceiveDOFODate"
                    fullWidth
                    value={item.ReceiveDate}
                    onChange={(value) => {
                      this.saveRecord(item, index, "ReceiveDate", value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFO"
                    key="ReceiveFO"
                    fullWidth
                    decimalScale={2}
                    placeholder="FO"
                    value={item.FO}
                    onChange={value => {
                      this.saveRecord(item, index, "FO", value);
                    }}
                    error={this.state.validateStatus.ReceiveFO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveDO"
                    key="ReceiveDO"
                    fullWidth
                    decimalScale={2}
                    placeholder="DO"
                    value={item.DO}
                    onChange={value => {
                      this.saveRecord(item, index, "DO", value);
                    }}
                    error={this.state.validateStatus.ReceiveDO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOCyl"
                    key="ReceiveLOCyl"
                    fullWidth
                    decimalScale={2}
                    placeholder="LOCyl"
                    value={item.LOCyl}
                    onChange={value => {
                      this.saveRecord(item, index, "LOCyl", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOCyl !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOSys"
                    key="ReceiveLOSys"
                    fullWidth
                    decimalScale={2}
                    placeholder="LOSys"
                    value={item.LOSys}
                    onChange={value => {
                      this.saveRecord(item, index, "LOSys", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOSys !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFW"
                    key="ReceiveFW"
                    fullWidth
                    decimalScale={2}
                    placeholder="FW"
                    value={item.FW}
                    onChange={value => {
                      this.saveRecord(item, index, "FW", value);
                    }}
                    error={this.state.validateStatus.ReceiveFW !== undefined}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                {index == 0 ?
                  (<Grid item><Button variant="contained" onClick={() => { this.addRecord() }}>Add</Button></Grid>) :
                  (<><Grid item><Button variant="contained" onClick={() => { this.removeRecord(index) }}>Remove</Button></Grid></>)}
              </Grid>
            ))}
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>H-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RepairLog}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="RepairLog"
                  key="RepairLog"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RepairLog}
                  onChange={(event) => {
                    this.saveField("RepairLog", event.target.value);
                  }}
                  error={this.state.validateStatus.RepairLog !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>I-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.MaterialLog}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="MaterialLog"
                  key="MaterialLog"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.MaterialLog}
                  onChange={(event) => {
                    this.saveField("MaterialLog", event.target.value);
                  }}
                  error={this.state.validateStatus.MaterialLog !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>J-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  DepartureReportEditDialog
);
