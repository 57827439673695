import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";

import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import Luckysheet from "../components/Luckysheet";

import DepartureReportEditDialog from "./DepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import { mockComponent } from "react-dom/test-utils";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage number:",
      ReportTypeID: "Report type:",
      ReportTime: "Date:",
      Mode: "Mode:",
    }
  : {
      VoyageCode: "Số chuyến đi:",
      ReportTypeID: "Loại báo cáo:",
      ReportTime: "Ngày:",
      Mode: "Hiển thị",
    };

const buttonLabels = englishUI
  ? {
      btnTimKiem: "Search",
      btnLoadConfig: "Load config",
      btnConfig: "Save config",
      btnAtSea: "At-Sea",
      btnReportData: "All",
      btnInport: "In-port",
      btnManu: "Manu",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnLoadConfig: "Cấu hình",
      btnConfig: "Ghi cấu hình",
      btnAtSea: "At-Sea",
      btnReportData: "All",
      btnInport: "In-port",
      btnManu: "Manu",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/

/*-----------------------Cac bien su dung trong form ----------------------*/

function mapSheet_AtSea(response) {
  const datas = response.AtSeaInDay;
  const record = datas && datas.length ? datas[0] : {};
  const lucky = window.luckysheet;
  const config = lucky.toJson();
  const sheet = config.data[0]; //sheet dau tien
  const cellData = sheet.celldata;
  //hien thi du lieu at sea
  for (var c = 0; c < cellData.length; c++) {
    var cell = cellData[c];
    var tag = cell.v.v; //gia tri hien thoi cua cell
    if (!myLib.isEmpty(tag) && record[tag] !== undefined) {
      //tim trong du lieu xem truong nao khop
      lucky.setCellValue(cell.r, cell.c, record[tag]);
    }
  }
}
//hien thi du lieu Inport
function mapSheet_Inport(response) {
  const datas = response.InPortInDay;
  const record = datas && datas.length ? datas[0] : {};
  const lucky = window.luckysheet;
  const config = lucky.toJson();
  const sheet = config.data[0]; //sheet dau tien
  const cellData = sheet.celldata;
  //hien thi du lieu at sea
  for (var c = 0; c < cellData.length; c++) {
    var cell = cellData[c];
    var tag = cell.v.v; //gia tri hien thoi cua cell
    if (!myLib.isEmpty(tag) && record[tag] !== undefined) {
      //tim trong du lieu xem truong nao khop
      lucky.setCellValue(cell.r, cell.c, record[tag]);
    }
  }
}
//hien thi du lieu Manu
function mapSheet_Manu(response) {
  const datas = response.ManuInDay;
  const record = datas && datas.length ? datas[0] : {};
  const lucky = window.luckysheet;
  const config = lucky.toJson();
  const sheet = config.data[0]; //sheet dau tien
  const cellData = sheet.celldata;
  //hien thi du lieu at sea
  for (var c = 0; c < cellData.length; c++) {
    var cell = cellData[c];
    var tag = cell.v.v; //gia tri hien thoi cua cell
    if (!myLib.isEmpty(tag) && record[tag] !== undefined) {
      //tim trong du lieu xem truong nao khop
      lucky.setCellValue(cell.r, cell.c, record[tag]);
    }
  }
}
//tra ve mo ta thoi gian
function getTimeTag(rptTruoc, timeCode) {
  if (timeCode == "DC") {
    return "DD - DC";
  }
  if (timeCode == "AA") {
    return "AC - AA";
  }
  if (timeCode == "FS") {
    return "SS - FS";
  }
  if (rptTruoc == null) {
    return timeCode;
  }
  var t = "";
  if (rptTruoc == 1) {
    t = "DC";
  }
  if (rptTruoc == 2) {
    t = "NS";
  }
  if (rptTruoc == 3) {
    t = "AA";
  }
  if (rptTruoc == 4) {
    t = "AF";
  }
  if (rptTruoc == 5) {
    t = "NP";
  }
  return t != "" ? t + " - " + timeCode : timeCode;
}
//gan du lieu vao config cua sheet de hien thi lai
function mapSheet_DataDay(response) {
  const datas = response.DataInDay;
  const lucky = window.luckysheet;
  const config = lucky.toJson();
  const sheet = config.data[0]; //sheet dau tien
  const cellData = sheet.celldata;
  //tim vi tri bat dau hien thi
  var rowsHienthi = []; //danh sach cac cell dau tien cua 1 dòng hien thi
  cellData.map((cell) => {
    if (cell.v.v == "X") {
      rowsHienthi.push(cell);
      lucky.setCellValue(cell.r, cell.c, "");
    }
  });
  //thuc hien hien thi du lieu cho tung row
  var dataIndex = 0; //vi tri du lieu dang phai hien thi
  var mapFields = {}; //chua dinh danh cot cho tung column
  var mapping = false;

  for (var ix = 0; ix < rowsHienthi.length - 1; ix++) {
    var beginCell = rowsHienthi[ix]; //cell dau tien cua dong
    //console.log("begin cell = ", beginCell);
    var endCell = rowsHienthi[ix + 1]; //cell dau tien cua dong tiep theo
    //duyet cac cell nam giua
    if (!mapping) {
      for (var c = beginCell.c; c < sheet.column; c++) {
        var fieldName = lucky.getCellValue(beginCell.r, c);
        if (fieldName !== "") {
          mapFields[c] = fieldName;
        }
      }
      mapping = true; //chi lam lan dau tien
    }
    //hien thi du lieu
    //tim du lieu dau tien ket thuc = chu cai: DD, DC, NS, AC, AA, AF, NP, SS, FS
    var timeCode = "",
      timeCodeTruoc = "";
    var dataEndIndex = datas.length;
    for (var i = dataIndex; i < datas.length && timeCode == ""; i++) {
      var item = datas[i];
      if (item.TimeCode !== "0") {
        timeCode = item.TimeCode;
        //xac dinh pham vi ket thuc du lieu cua che do nay
        dataEndIndex = datas.length;
        for (var z = i; z < datas.length; z++) {
          if (datas[z].TimeCode !== timeCode) {
            dataEndIndex = z;
            //console.log("end=", z);
            break;
          }
        }
      }
    }
    //xac dinh code truoc
    for (var i = dataIndex; i < dataEndIndex && timeCodeTruoc == ""; i++) {
      var record = datas[i];
      if (record.DataField.indexOf("RtpTruoc") >= 0) {
        timeCodeTruoc = record.NumberValue;
      }
    }
    lucky.setCellValue(
      beginCell.r,
      beginCell.c,
      getTimeTag(timeCodeTruoc, timeCode)
    );
    if (timeCode == "") {
      break; //khong xac dinh duoc du lieu gi thi dung lai
    }
    //tim duoc du lieu dung roi thi tu dong hien thi
    var maxIndex = 0;

    for (var c = beginCell.c + 1; c < sheet.column; c++) {
      var fieldName = mapFields[c];

      if (fieldName && fieldName !== "") {
        //hien thi theo ten truogn du lieu
        var fieldData;
        if (fieldName.endsWith("_")) {
          fieldData = fieldName.replace("_", timeCode);
        } else {
          fieldData = fieldName;
        }
        //tim ban ghi trong datas de tim tra truong thich hop
        var found = false;
        for (var i = dataIndex; i < dataEndIndex; i++) {
          var record = datas[i];
          //xac dinh code bao cao truoc

          if (record.DataField == fieldData) {
            var value =
              record.DataType == "number"
                ? record.NumberValue
                : record.DataType == "string"
                ? record.TextValue
                : moment(record.DateValue).format("HH:mm");
            lucky.setCellValue(beginCell.r, c, value);
            found = true;
            break;
          }
        }
        if (!found) {
          lucky.setCellValue(beginCell.r, c, "-");
          lucky.setCellFormat(beginCell.r, c, "bg", "#dddddddd");
        }
      }
    }
    //chuyen sang phan doan du lieutiep theo
    dataIndex = dataEndIndex;
  }
}
////////////////////////////////////////////////////////////////////
class ReportDataPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  luckyComponent = undefined; //tham chieu den compoennt
  constructor(props) {
    super(props);
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: { DM_Voyage: [], DM_ReportType: [] }, //luu cac du lieu danh muc su dung trong form
      //searching
      sheetLoading: true,
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: 0,
              //danh sach cac tieu chi tim kiem
              VoyageCode: null,
              Ngay: new Date(),
              Mode: "AtSea",
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      CurrentWorksheet: {
        WorksheetID: 0,
        WorksheetCode: "AtSea",
      },
      WorkbookConfig: {
        version: 0, //doi ve -1 de reset neu can
        JsonConfig: {},
      },
      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      dataFilled: false, //cho biet du lieu duoc fill hay chua
    };
  }
  //xu ly event cua sheeeet
  luckysheetCallback = (lucky, event) => {
    console.log("lucky event:" + event);
    this.luckyComponent = lucky;
    if (
      event == "created" &&
      this.state.WorkbookConfig &&
      Object.keys(this.state.WorkbookConfig.JsonConfig).length > 0
    ) {
      //neu da duoc tao xong thi se goi reload
      this.luckyComponent.reload(this.state.WorkbookConfig.JsonConfig);
      //ket thuc load sheet
      this.setState({ sheetLoading: false });
    }
  };

  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s);
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc("DM_Voyage,DM_ReportType", () => {});
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(props, state) {}
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
              after: {
                ...this.state.after,
                VoyageCode:
                  response.DM_Voyage.length > 0
                    ? response.DM_Voyage[0].VoyageCode
                    : null,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
    //thuc hien load config excel
    this.requestLoadConfig();
  }
  //khi thay doi sap xep cot

  //Thuc hien search
  doSearch = () => {
    this.setState({
      saving: true,
    });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_ReportData_GetDataDay",
        ThamSo: {
          VoyageCode: this.state.after.VoyageCode,
          Ngay: this.state.after.Ngay,
          Mode: this.state.after.Mode,
        },
      })
      .then((response) => {
        this.setState({
          saving: false,
          dataFilled: true,
        });
        //dua du lieu vao hien thi trong sheet
        if (this.state.CurrentWorksheet.WorksheetCode == "ReportData") {
          mapSheet_DataDay(response);
        }
        if (this.state.CurrentWorksheet.WorksheetCode == "AtSea") {
          mapSheet_AtSea(response);
        }
        if (this.state.CurrentWorksheet.WorksheetCode == "InPort") {
          mapSheet_Inport(response);
        }
        if (this.state.CurrentWorksheet.WorksheetCode == "Manu") {
          mapSheet_Manu(response);
        }
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  };
  requestLoadConfig() {
    //thuc hien load cau hinh grid tu server
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Worksheet_Get",
        ThamSo: {
          WorksheetID: 0,
          WorksheetCode: this.state.after.Mode,
        },
      })
      .then((response) => {
        if (response.DM_Worksheet.length == 0) {
          this.setState(
            {
              saving: false,
              dataFilled: false,
              WorkbookConfig: {
                version: this.state.WorkbookConfig.version + 1, //doi ve -1 de reset neu can
                JsonConfig: {},
              },
            },
            () => {
              this.luckyComponent.reload(undefined, this.state.after.Mode); //load default
            }
          );
        } else {
          const lastVersion = this.state.WorkbookConfig.version;
          //thu thuat: dau tien set ve -1 de reset, sau do render lai
          this.setState(
            {
              saving: false,
              dataFilled: false,
              CurrentWorksheet: {
                WorksheetID: response.DM_Worksheet[0].WorksheetID,
                WorksheetCode: response.DM_Worksheet[0].WorksheetCode,
              },
              WorkbookConfig: {
                version: lastVersion + 10,
                JsonConfig: JSON.parse(response.DM_Worksheet[0].JsonConfig),
              },
            },
            () => {
              console.log(this.state.sheetLoading);
              if (!this.state.sheetLoading) {
                this.luckyComponent.reload(
                  this.state.WorkbookConfig.JsonConfig,
                  this.state.after.Mode
                );
              }
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ saving: false, sheetLoading: false });
        showError(error);
      });
  }
  requestSaveConfig() {
    //thuc hien save cau hinh ve server
    var json = window.luckysheet.toJson();
    json = JSON.stringify(json);
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Worksheet_Update",
        ThamSo: {
          WorksheetID: 0,
          WorksheetCode: this.state.after.Mode,
          JsonConfig: json,
        },
      })
      .then((response) => {
        this.setState({
          saving: false,
          CurrentWorksheet: response.DM_Worksheet[0], //lay ma tu server
        });
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }
  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    //console.log('render................');
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                id={"VoyageCode"}
                key={"VoyageCode"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Voyage}
                optionLabel="VoyageCode"
                optionValue="VoyageCode"
                placeholder=""
                value={this.state.after}
                onChange={(item) => {
                  this.saveData(item);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.ReportTime}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id={"Ngay"}
                key={"Ngay"}
                fullWidth
                value={this.state.after.Ngay}
                onChange={(value) => {
                  this.saveField("Ngay", value);
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                title={buttonLabels.btnTimKiem}
                variant="contained"
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                  if (item.menuName == "Reload") {
                    //load f5orm
                    this.loadForm(true);
                  }
                  if (item.menuName == "GetCode") {
                    //lay codejs
                    this.generateCodeReactJs();
                  }
                }}
              ></ButtonTimKiem>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.requestLoadConfig(false);
                }}
              >
                {buttonLabels.btnLoadConfig}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                disabled={this.state.dataFilled}
                onClick={() => {
                  this.requestSaveConfig(false);
                }}
              >
                {buttonLabels.btnConfig}
              </Button>
            </Grid>

            <Grid item xs={1}>
              {this.state.saving || this.sheetLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.state.after.Mode = "AtSea";
                  this.requestLoadConfig();
                }}
              >
                {buttonLabels.btnAtSea}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.state.after.Mode = "Manu";
                  this.requestLoadConfig();
                }}
              >
                {buttonLabels.btnManu}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.state.after.Mode = "InPort";
                  this.requestLoadConfig();
                }}
              >
                {buttonLabels.btnInport}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.state.after.Mode = "ReportData";
                  this.requestLoadConfig();
                }}
              >
                {buttonLabels.btnReportData}
              </Button>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <Luckysheet
              height={parentHeight - 55}
              initConfig={this.state.WorkbookConfig}
              callback={this.luckysheetCallback}
            />
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}

        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.action_show_form ? (
          <DataEditDialog
            open={true}
            FormName={this.state.action_FormName}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({
                action_show_form: false,
                action_FormName: undefined,
              });
            }}
          />
        ) : null}
        {this.state.open_edit_departure ? (
          <DepartureReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_departure: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonatsea ? (
          <NoonAtSeaReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonatsea: false });
            }}
          />
        ) : null}
        {this.state.open_edit_arrival ? (
          <ArrivalReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_arrival: false });
            }}
          />
        ) : null}
        {this.state.open_edit_allfast ? (
          <AllfastReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_allfast: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonport ? (
          <NoonAtPortReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonport: false });
            }}
          />
        ) : null}
        {this.state.open_edit_shifting ? (
          <ShiftingReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_shifting: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ReportDataPage);
