import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Noon At Sea Report"
  : "Báo cáo buổi trưa trên biển";
const fieldLabels = englishUI
  ? {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeNS: "TimeNS",
      PosLat: "PosLat",
      PosLon: "PosLon",
      DisToGo: "DisToGo",
      AvgSpeed: "AvgSpeed",
      ETA: "ETA",
      WeatherSea: "WeatherSea",
      WeatherWind: "WeatherWind",
      ShipCourse: "ShipCourse",
      LacanWater: "LacanWater",
      SlopWater: "SlopWater",
      RpmMENS: "RpmMENS",
      BCANS: "BCANS",
      LoadNS: "LoadNS",
      TminNS: "TminNS",
      TmaxNS: "TmaxNS",
      PaMENS: "PaMENS",
      AirMENS: "AirMENS",
      TerNS: "TerNS",
      SlipNS: "SlipNS",
      RunhMENS: "RunhMENS",
      RunhAE1NS: "RunhAE1NS",
      RunhAE2NS: "RunhAE2NS",
      RunhAE3NS: "RunhAE3NS",
      RunhIGNS: "RunhIGNS",
      RpmCounterMENS: "RpmCounterMENS",
      FlowMENS: "FlowMENS",
      FlowAENS: "FlowAENS",
      FlowBLRNS: "FlowBLRNS",
      FlowTempNS: "FlowTempNS",
      METempNS: "METempNS",
      ConsFOMENS: "ConsFOMENS",
      ConsDOMENS: "ConsDOMENS",
      ConsFOAENS: "ConsFOAENS",
      ConsDOAENS: "ConsDOAENS",
      ConsFOBLRNS: "ConsFOBLRNS",
      ConsDOBLRNS: "ConsDOBLRNS",
      ConsCylNS: "ConsCylNS",
      ConsSysNS: "ConsSysNS",
      ConsAENS: "ConsAENS",
      ConsHydNS: "ConsHydNS",
      FWPureNS: "FWPureNS",
      RobFONS: "RobFONS",
      RobDONS: "RobDONS",
      RobFWNS: "RobFWNS",
      TimeChanged: "TimeChanged",
      TimeChangeValue: "TimeChangeValue",
      Floating: "Floating",
      Remark: "Remark",
    }
  : {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeNS: "TimeNS",
      PosLat: "PosLat",
      PosLon: "PosLon",
      DisToGo: "DisToGo",
      AvgSpeed: "AvgSpeed",
      ETA: "ETA",
      WeatherSea: "WeatherSea",
      WeatherWind: "WeatherWind",
      ShipCourse: "ShipCourse",
      LacanWater: "LacanWater",
      SlopWater: "SlopWater",
      RpmMENS: "RpmMENS",
      BCANS: "BCANS",
      LoadNS: "LoadNS",
      TminNS: "TminNS",
      TmaxNS: "TmaxNS",
      PaMENS: "PaMENS",
      AirMENS: "AirMENS",
      TerNS: "TerNS",
      SlipNS: "SlipNS",
      RunhMENS: "RunhMENS",
      RunhAE1NS: "RunhAE1NS",
      RunhAE2NS: "RunhAE2NS",
      RunhAE3NS: "RunhAE3NS",
      RunhIGNS: "RunhIGNS",
      RpmCounterMENS: "RpmCounterMENS",
      FlowMENS: "FlowMENS",
      FlowAENS: "FlowAENS",
      FlowBLRNS: "FlowBLRNS",
      FlowTempNS: "FlowTempNS",
      METempNS: "METempNS",
      ConsFOMENS: "ConsFOMENS",
      ConsDOMENS: "ConsDOMENS",
      ConsFOAENS: "ConsFOAENS",
      ConsDOAENS: "ConsDOAENS",
      ConsFOBLRNS: "ConsFOBLRNS",
      ConsDOBLRNS: "ConsDOBLRNS",
      ConsCylNS: "ConsCylNS",
      ConsSysNS: "ConsSysNS",
      ConsAENS: "ConsAENS",
      ConsHydNS: "ConsHydNS",
      FWPureNS: "FWPureNS",
      RobFONS: "RobFONS",
      RobDONS: "RobDONS",
      RobFWNS: "RobFWNS",
      TimeChanged: "TimeChanged",
      TimeChangeValue: "TimeChangeValue",
      Floating: "Floating",
      Remark: "Remark",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class NoonAtSeaReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        VoyageCode: null,
        ReportTypeID: null,
        ReportTime: null,
        TimeNS: null,
        PosLat: null,
        PosLon: null,
        DisToGo: null,
        AvgSpeed: null,
        ETA: null,
        WeatherSea: null,
        WeatherWind: null,
        ShipCourse: null,
        LacanWater: null,
        SlopWater: null,
        RpmMENS: null,
        BCANS: null,
        LoadNS: null,
        TminNS: null,
        TmaxNS: null,
        PaMENS: null,
        AirMENS: null,
        TerNS: null,
        SlipNS: null,
        RunhMENS: null,
        RunhAE1NS: null,
        RunhAE2NS: null,
        RunhAE3NS: null,
        RunhIGNS: null,
        RpmCounterMENS: null,
        FlowMENS: null,
        FlowAENS: null,
        FlowBLRNS: null,
        FlowTempNS: null,
        METempNS: null,
        ConsFOMENS: null,
        ConsDOMENS: null,
        ConsFOAENS: null,
        ConsDOAENS: null,
        ConsFOBLRNS: null,
        ConsDOBLRNS: null,
        ConsCylNS: null,
        ConsSysNS: null,
        ConsAENS: null,
        ConsHydNS: null,
        FWPureNS: null,
        RobFONS: null,
        RobDONS: null,
        RobFWNS: null,
        TimeChanged: null,
        TimeChangeValue: null,
        Floating: null,
        Remark: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_Voyage", () => {});
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //load du lieu randomm
  loadRandomSample() {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 2,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 2; //departure report
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_TempReport_Update",
            ThamSo: [
              //danh sach cac report update cung 1 luc'
              {
                ReportID: this.state.after.ReportID,
                VoyageCode: this.state.after.VoyageCode,
                JsonData: JSON.stringify(this.state.after),
                TempStatus: 0,
              },
            ],
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
            //close form
            this.props.close();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTypeID}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ReportTypeID"
                  key="ReportTypeID"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ReportTypeID}
                  onChange={(event) => {
                    this.saveField("ReportTypeID", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportTypeID !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="TimeNS"
                  key="TimeNS"
                  fullWidth
                  value={this.state.after.TimeNS}
                  onChange={(value) => {
                    this.saveField("TimeNS", value);
                  }}
                  error={this.state.validateStatus.TimeNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLat}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLat"
                  key="PosLat"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLat}
                  onChange={(event) => {
                    this.saveField("PosLat", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLat !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLon}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLon"
                  key="PosLon"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLon}
                  onChange={(event) => {
                    this.saveField("PosLon", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLon !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.DisToGo}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="DisToGo"
                  key="DisToGo"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.DisToGo}
                  onChange={(event) => {
                    this.saveField("DisToGo", event.target.value);
                  }}
                  error={this.state.validateStatus.DisToGo !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AvgSpeed}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="AvgSpeed"
                  key="AvgSpeed"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.AvgSpeed}
                  onChange={(event) => {
                    this.saveField("AvgSpeed", event.target.value);
                  }}
                  error={this.state.validateStatus.AvgSpeed !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ETA"
                  key="ETA"
                  fullWidth
                  value={this.state.after.ETA}
                  onChange={(value) => {
                    this.saveField("ETA", value);
                  }}
                  error={this.state.validateStatus.ETA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.WeatherSea}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherSea"
                  key="WeatherSea"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.WeatherSea}
                  onChange={(event) => {
                    this.saveField("WeatherSea", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherSea !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.WeatherWind}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherWind"
                  key="WeatherWind"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.WeatherWind}
                  onChange={(event) => {
                    this.saveField("WeatherWind", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherWind !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ShipCourse}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ShipCourse"
                  key="ShipCourse"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ShipCourse}
                  onChange={(event) => {
                    this.saveField("ShipCourse", event.target.value);
                  }}
                  error={this.state.validateStatus.ShipCourse !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.LacanWater}
                  onChange={(event) => {
                    this.saveField("LacanWater", event.target.value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SlopWater}
                  onChange={(event) => {
                    this.saveField("SlopWater", event.target.value);
                  }}
                  error={this.state.validateStatus.SlopWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmMENS"
                  key="RpmMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmMENS}
                  onChange={(event) => {
                    this.saveField("RpmMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.BCANS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="BCANS"
                  key="BCANS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.BCANS}
                  onChange={(event) => {
                    this.saveField("BCANS", event.target.value);
                  }}
                  error={this.state.validateStatus.BCANS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LoadNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="LoadNS"
                  key="LoadNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.LoadNS}
                  onChange={(event) => {
                    this.saveField("LoadNS", event.target.value);
                  }}
                  error={this.state.validateStatus.LoadNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TminNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TminNS"
                  key="TminNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TminNS}
                  onChange={(event) => {
                    this.saveField("TminNS", event.target.value);
                  }}
                  error={this.state.validateStatus.TminNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TmaxNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TmaxNS"
                  key="TmaxNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TmaxNS}
                  onChange={(event) => {
                    this.saveField("TmaxNS", event.target.value);
                  }}
                  error={this.state.validateStatus.TmaxNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PaMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PaMENS"
                  key="PaMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PaMENS}
                  onChange={(event) => {
                    this.saveField("PaMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.PaMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AirMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="AirMENS"
                  key="AirMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.AirMENS}
                  onChange={(event) => {
                    this.saveField("AirMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.AirMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TerNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TerNS"
                  key="TerNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TerNS}
                  onChange={(event) => {
                    this.saveField("TerNS", event.target.value);
                  }}
                  error={this.state.validateStatus.TerNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlipNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="SlipNS"
                  key="SlipNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SlipNS}
                  onChange={(event) => {
                    this.saveField("SlipNS", event.target.value);
                  }}
                  error={this.state.validateStatus.SlipNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhMENS"
                  key="RunhMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhMENS}
                  onChange={(event) => {
                    this.saveField("RunhMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1NS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE1NS"
                  key="RunhAE1NS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE1NS}
                  onChange={(event) => {
                    this.saveField("RunhAE1NS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE1NS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE2NS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE2NS"
                  key="RunhAE2NS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE2NS}
                  onChange={(event) => {
                    this.saveField("RunhAE2NS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE2NS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE3NS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE3NS"
                  key="RunhAE3NS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE3NS}
                  onChange={(event) => {
                    this.saveField("RunhAE3NS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE3NS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhIGNS"
                  key="RunhIGNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhIGNS}
                  onChange={(event) => {
                    this.saveField("RunhIGNS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhIGNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmCounterMENS"
                  key="RpmCounterMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmCounterMENS}
                  onChange={(event) => {
                    this.saveField("RpmCounterMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmCounterMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowMENS"
                  key="FlowMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowMENS}
                  onChange={(event) => {
                    this.saveField("FlowMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowAENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowAENS"
                  key="FlowAENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowAENS}
                  onChange={(event) => {
                    this.saveField("FlowAENS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowAENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowBLRNS"
                  key="FlowBLRNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowBLRNS}
                  onChange={(event) => {
                    this.saveField("FlowBLRNS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowBLRNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowTempNS"
                  key="FlowTempNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowTempNS}
                  onChange={(event) => {
                    this.saveField("FlowTempNS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowTempNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.METempNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="METempNS"
                  key="METempNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.METempNS}
                  onChange={(event) => {
                    this.saveField("METempNS", event.target.value);
                  }}
                  error={this.state.validateStatus.METempNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOMENS"
                  key="ConsFOMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOMENS}
                  onChange={(event) => {
                    this.saveField("ConsFOMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOMENS"
                  key="ConsDOMENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOMENS}
                  onChange={(event) => {
                    this.saveField("ConsDOMENS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOAENS"
                  key="ConsFOAENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOAENS}
                  onChange={(event) => {
                    this.saveField("ConsFOAENS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOAENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOAENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOAENS"
                  key="ConsDOAENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOAENS}
                  onChange={(event) => {
                    this.saveField("ConsDOAENS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOAENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOBLRNS"
                  key="ConsFOBLRNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOBLRNS}
                  onChange={(event) => {
                    this.saveField("ConsFOBLRNS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOBLRNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOBLRNS"
                  key="ConsDOBLRNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOBLRNS}
                  onChange={(event) => {
                    this.saveField("ConsDOBLRNS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsCylNS"
                  key="ConsCylNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsCylNS}
                  onChange={(event) => {
                    this.saveField("ConsCylNS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsCylNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsSysNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsSysNS"
                  key="ConsSysNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsSysNS}
                  onChange={(event) => {
                    this.saveField("ConsSysNS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsSysNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsAENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsAENS"
                  key="ConsAENS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsAENS}
                  onChange={(event) => {
                    this.saveField("ConsAENS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsAENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsHydNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsHydNS"
                  key="ConsHydNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsHydNS}
                  onChange={(event) => {
                    this.saveField("ConsHydNS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsHydNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FWPureNS"
                  key="FWPureNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FWPureNS}
                  onChange={(event) => {
                    this.saveField("FWPureNS", event.target.value);
                  }}
                  error={this.state.validateStatus.FWPureNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFONS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFONS"
                  key="RobFONS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFONS}
                  onChange={(event) => {
                    this.saveField("RobFONS", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFONS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDONS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobDONS"
                  key="RobDONS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobDONS}
                  onChange={(event) => {
                    this.saveField("RobDONS", event.target.value);
                  }}
                  error={this.state.validateStatus.RobDONS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFWNS"
                  key="RobFWNS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFWNS}
                  onChange={(event) => {
                    this.saveField("RobFWNS", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFWNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeChanged}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TimeChanged"
                  key="TimeChanged"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TimeChanged}
                  onChange={(event) => {
                    this.saveField("TimeChanged", event.target.value);
                  }}
                  error={this.state.validateStatus.TimeChanged !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeChangeValue}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TimeChangeValue"
                  key="TimeChangeValue"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TimeChangeValue}
                  onChange={(event) => {
                    this.saveField("TimeChangeValue", event.target.value);
                  }}
                  error={
                    this.state.validateStatus.TimeChangeValue !== undefined
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Floating}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Floating"
                  key="Floating"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Floating}
                  onChange={(event) => {
                    this.saveField("Floating", event.target.value);
                  }}
                  error={this.state.validateStatus.Floating !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  NoonAtSeaReportEditDialog
);
