import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Arrival Report" : "Báo cáo tàu đến cảng";
const fieldLabels = englishUI
  ? {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeAC: "TimeAC",
      POB: "POB",
      TimeAA: "TimeAA",
      PosLat: "PosLat",
      PosLon: "PosLon",
      ForeDraft: "ForeDraft",
      AftDraft: "AftDraft",
      ETB: "ETB",
      WeatherSea: "WeatherSea",
      WeatherWind: "WeatherWind",
      LacanWater: "LacanWater",
      SlopWater: "SlopWater",
      RpmMEAC: "RpmMEAC",
      BCAAC: "BCAAC",
      LoadAC: "LoadAC",
      TminAC: "TminAC",
      TmaxAC: "TmaxAC",
      PaMEAC: "PaMEAC",
      AirMEAC: "AirMEAC",
      TerAC: "TerAC",
      SlipAC: "SlipAC",
      RobFOAC: "RobFOAC",
      RobDOAC: "RobDOAC",
      RobSumpAC: "RobSumpAC",
      RobSysAC: "RobSysAC",
      RobCylAC: "RobCylAC",
      RobAEAC: "RobAEAC",
      RobHydAC: "RobHydAC",
      RobFWAC: "RobFWAC",
      RobFOAA: "RobFOAA",
      RobDOAA: "RobDOAA",
      RobSumpAA: "RobSumpAA",
      RobSysAA: "RobSysAA",
      RobCylAA: "RobCylAA",
      RobAEAA: "RobAEAA",
      RobHydAA: "RobHydAA",
      RobFWAA: "RobFWAA",
      RpmCounterMEAC: "RpmCounterMEAC",
      FlowMEAC: "FlowMEAC",
      FlowAEAC: "FlowAEAC",
      FlowBLRAC: "FlowBLRAC",
      FlowTempAC: "FlowTempAC",
      METempAC: "METempAC",
      RpmCounterMEAA: "RpmCounterMEAA",
      FlowMEAA: "FlowMEAA",
      FlowAEAA: "FlowAEAA",
      FlowBLRAA: "FlowBLRAA",
      FlowTempAA: "FlowTempAA",
      METempAA: "METempAA",
      ConsFOMEAC: "ConsFOMEAC",
      ConsDOMEAC: "ConsDOMEAC",
      ConsFOAEAC: "ConsFOAEAC",
      ConsDOAEAC: "ConsDOAEAC",
      ConsFOBLRAC: "ConsFOBLRAC",
      ConsDOBLRAC: "ConsDOBLRAC",
      ConsCylAC: "ConsCylAC",
      ConsSysAC: "ConsSysAC",
      ConsAEAC: "ConsAEAC",
      ConsHydAC: "ConsHydAC",
      ConsFOMEAA: "ConsFOMEAA",
      ConsDOMEAA: "ConsDOMEAA",
      ConsFOAEAA: "ConsFOAEAA",
      ConsDOAEAA: "ConsDOAEAA",
      ConsFOBLRAA: "ConsFOBLRAA",
      ConsDOBLRAA: "ConsDOBLRAA",
      ConsCylAA: "ConsCylAA",
      ConsSysAA: "ConsSysAA",
      ConsAEAA: "ConsAEAA",
      ConsHydAA: "ConsHydAA",
      FWPureAC: "FWPureAC",
      RunhMEAC: "RunhMEAC",
      RunhAE1AC: "RunhAE1AC",
      RunhAE2AC: "RunhAE2AC",
      RunhAE3AC: "RunhAE3AC",
      RunhIGAC: "RunhIGAC",
      RunhMEAA: "RunhMEAA",
      RunhAE1AA: "RunhAE1AA",
      RunhAE2AA: "RunhAE2AA",
      RunhAE3AA: "RunhAE3AA",
      RunhIGAA: "RunhIGAA",
      ConsFODCAC: "ConsFODCAC",
      ConsBHPDCAC: "ConsBHPDCAC",
      LoadDCAC: "LoadDCAC",
      RpmMEDCAC: "RpmMEDCAC",
      SlipDCAC: "SlipDCAC",
      AvgSpeedDCAC: "AvgSpeedDCAC",
      SeaMapLog: "SeaMapLog",
      Remark: "Remark",
    }
  : {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeAC: "TimeAC",
      POB: "POB",
      TimeAA: "TimeAA",
      PosLat: "PosLat",
      PosLon: "PosLon",
      ForeDraft: "ForeDraft",
      AftDraft: "AftDraft",
      ETB: "ETB",
      WeatherSea: "WeatherSea",
      WeatherWind: "WeatherWind",
      LacanWater: "LacanWater",
      SlopWater: "SlopWater",
      RpmMEAC: "RpmMEAC",
      BCAAC: "BCAAC",
      LoadAC: "LoadAC",
      TminAC: "TminAC",
      TmaxAC: "TmaxAC",
      PaMEAC: "PaMEAC",
      AirMEAC: "AirMEAC",
      TerAC: "TerAC",
      SlipAC: "SlipAC",
      RobFOAC: "RobFOAC",
      RobDOAC: "RobDOAC",
      RobSumpAC: "RobSumpAC",
      RobSysAC: "RobSysAC",
      RobCylAC: "RobCylAC",
      RobAEAC: "RobAEAC",
      RobHydAC: "RobHydAC",
      RobFWAC: "RobFWAC",
      RobFOAA: "RobFOAA",
      RobDOAA: "RobDOAA",
      RobSumpAA: "RobSumpAA",
      RobSysAA: "RobSysAA",
      RobCylAA: "RobCylAA",
      RobAEAA: "RobAEAA",
      RobHydAA: "RobHydAA",
      RobFWAA: "RobFWAA",
      RpmCounterMEAC: "RpmCounterMEAC",
      FlowMEAC: "FlowMEAC",
      FlowAEAC: "FlowAEAC",
      FlowBLRAC: "FlowBLRAC",
      FlowTempAC: "FlowTempAC",
      METempAC: "METempAC",
      RpmCounterMEAA: "RpmCounterMEAA",
      FlowMEAA: "FlowMEAA",
      FlowAEAA: "FlowAEAA",
      FlowBLRAA: "FlowBLRAA",
      FlowTempAA: "FlowTempAA",
      METempAA: "METempAA",
      ConsFOMEAC: "ConsFOMEAC",
      ConsDOMEAC: "ConsDOMEAC",
      ConsFOAEAC: "ConsFOAEAC",
      ConsDOAEAC: "ConsDOAEAC",
      ConsFOBLRAC: "ConsFOBLRAC",
      ConsDOBLRAC: "ConsDOBLRAC",
      ConsCylAC: "ConsCylAC",
      ConsSysAC: "ConsSysAC",
      ConsAEAC: "ConsAEAC",
      ConsHydAC: "ConsHydAC",
      ConsFOMEAA: "ConsFOMEAA",
      ConsDOMEAA: "ConsDOMEAA",
      ConsFOAEAA: "ConsFOAEAA",
      ConsDOAEAA: "ConsDOAEAA",
      ConsFOBLRAA: "ConsFOBLRAA",
      ConsDOBLRAA: "ConsDOBLRAA",
      ConsCylAA: "ConsCylAA",
      ConsSysAA: "ConsSysAA",
      ConsAEAA: "ConsAEAA",
      ConsHydAA: "ConsHydAA",
      FWPureAC: "FWPureAC",
      RunhMEAC: "RunhMEAC",
      RunhAE1AC: "RunhAE1AC",
      RunhAE2AC: "RunhAE2AC",
      RunhAE3AC: "RunhAE3AC",
      RunhIGAC: "RunhIGAC",
      RunhMEAA: "RunhMEAA",
      RunhAE1AA: "RunhAE1AA",
      RunhAE2AA: "RunhAE2AA",
      RunhAE3AA: "RunhAE3AA",
      RunhIGAA: "RunhIGAA",
      ConsFODCAC: "ConsFODCAC",
      ConsBHPDCAC: "ConsBHPDCAC",
      LoadDCAC: "LoadDCAC",
      RpmMEDCAC: "RpmMEDCAC",
      SlipDCAC: "SlipDCAC",
      AvgSpeedDCAC: "AvgSpeedDCAC",
      SeaMapLog: "SeaMapLog",
      Remark: "Remark",
    };

const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class ArrivalReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        VoyageCode: null,
        ReportTypeID: null,
        ReportTime: null,
        TimeAC: null,
        POB: null,
        TimeAA: null,
        PosLat: null,
        PosLon: null,
        ForeDraft: null,
        AftDraft: null,
        ETB: null,
        WeatherSea: null,
        WeatherWind: null,
        LacanWater: null,
        SlopWater: null,
        RpmMEAC: null,
        BCAAC: null,
        LoadAC: null,
        TminAC: null,
        TmaxAC: null,
        PaMEAC: null,
        AirMEAC: null,
        TerAC: null,
        SlipAC: null,
        RobFOAC: null,
        RobDOAC: null,
        RobSumpAC: null,
        RobSysAC: null,
        RobCylAC: null,
        RobAEAC: null,
        RobHydAC: null,
        RobFWAC: null,
        RobFOAA: null,
        RobDOAA: null,
        RobSumpAA: null,
        RobSysAA: null,
        RobCylAA: null,
        RobAEAA: null,
        RobHydAA: null,
        RobFWAA: null,
        RpmCounterMEAC: null,
        FlowMEAC: null,
        FlowAEAC: null,
        FlowBLRAC: null,
        FlowTempAC: null,
        METempAC: null,
        RpmCounterMEAA: null,
        FlowMEAA: null,
        FlowAEAA: null,
        FlowBLRAA: null,
        FlowTempAA: null,
        METempAA: null,
        ConsFOMEAC: null,
        ConsDOMEAC: null,
        ConsFOAEAC: null,
        ConsDOAEAC: null,
        ConsFOBLRAC: null,
        ConsDOBLRAC: null,
        ConsCylAC: null,
        ConsSysAC: null,
        ConsAEAC: null,
        ConsHydAC: null,
        ConsFOMEAA: null,
        ConsDOMEAA: null,
        ConsFOAEAA: null,
        ConsDOAEAA: null,
        ConsFOBLRAA: null,
        ConsDOBLRAA: null,
        ConsCylAA: null,
        ConsSysAA: null,
        ConsAEAA: null,
        ConsHydAA: null,
        FWPureAC: null,
        RunhMEAC: null,
        RunhAE1AC: null,
        RunhAE2AC: null,
        RunhAE3AC: null,
        RunhIGAC: null,
        RunhMEAA: null,
        RunhAE1AA: null,
        RunhAE2AA: null,
        RunhAE3AA: null,
        RunhIGAA: null,
        ConsFODCAC: null,
        ConsBHPDCAC: null,
        LoadDCAC: null,
        RpmMEDCAC: null,
        SlipDCAC: null,
        AvgSpeedDCAC: null,
        SeaMapLog: null,
        Remark: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_Voyage", () => {});
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //load du lieu randomm
  loadRandomSample() {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 3,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 3; //departure report
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_TempReport_Update",
            ThamSo: [
              //danh sach cac report update cung 1 luc'
              {
                ReportID: this.state.after.ReportID,
                VoyageCode: this.state.after.VoyageCode,
                JsonData: JSON.stringify(this.state.after),
                TempStatus: 0,
              },
            ],
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
            //close form
            this.props.close();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTypeID}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ReportTypeID"
                  key="ReportTypeID"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ReportTypeID}
                  onChange={(event) => {
                    this.saveField("ReportTypeID", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportTypeID !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="TimeAC"
                  key="TimeAC"
                  fullWidth
                  value={this.state.after.TimeAC}
                  onChange={(value) => {
                    this.saveField("TimeAC", value);
                  }}
                  error={this.state.validateStatus.TimeAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.POB}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="POB"
                  key="POB"
                  fullWidth
                  value={this.state.after.POB}
                  onChange={(value) => {
                    this.saveField("POB", value);
                  }}
                  error={this.state.validateStatus.POB !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="TimeAA"
                  key="TimeAA"
                  fullWidth
                  value={this.state.after.TimeAA}
                  onChange={(value) => {
                    this.saveField("TimeAA", value);
                  }}
                  error={this.state.validateStatus.TimeAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLat}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLat"
                  key="PosLat"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLat}
                  onChange={(event) => {
                    this.saveField("PosLat", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLat !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLon}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLon"
                  key="PosLon"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLon}
                  onChange={(event) => {
                    this.saveField("PosLon", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLon !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ForeDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ForeDraft}
                  onChange={(event) => {
                    this.saveField("ForeDraft", event.target.value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AftDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.AftDraft}
                  onChange={(event) => {
                    this.saveField("AftDraft", event.target.value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETB}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ETB"
                  key="ETB"
                  fullWidth
                  value={this.state.after.ETB}
                  onChange={(value) => {
                    this.saveField("ETB", value);
                  }}
                  error={this.state.validateStatus.ETB !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.WeatherSea}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherSea"
                  key="WeatherSea"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.WeatherSea}
                  onChange={(event) => {
                    this.saveField("WeatherSea", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherSea !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.WeatherWind}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherWind"
                  key="WeatherWind"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.WeatherWind}
                  onChange={(event) => {
                    this.saveField("WeatherWind", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherWind !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.LacanWater}
                  onChange={(event) => {
                    this.saveField("LacanWater", event.target.value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SlopWater}
                  onChange={(event) => {
                    this.saveField("SlopWater", event.target.value);
                  }}
                  error={this.state.validateStatus.SlopWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmMEAC"
                  key="RpmMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmMEAC}
                  onChange={(event) => {
                    this.saveField("RpmMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.BCAAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="BCAAC"
                  key="BCAAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.BCAAC}
                  onChange={(event) => {
                    this.saveField("BCAAC", event.target.value);
                  }}
                  error={this.state.validateStatus.BCAAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LoadAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="LoadAC"
                  key="LoadAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.LoadAC}
                  onChange={(event) => {
                    this.saveField("LoadAC", event.target.value);
                  }}
                  error={this.state.validateStatus.LoadAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TminAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TminAC"
                  key="TminAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TminAC}
                  onChange={(event) => {
                    this.saveField("TminAC", event.target.value);
                  }}
                  error={this.state.validateStatus.TminAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TmaxAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TmaxAC"
                  key="TmaxAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TmaxAC}
                  onChange={(event) => {
                    this.saveField("TmaxAC", event.target.value);
                  }}
                  error={this.state.validateStatus.TmaxAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PaMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PaMEAC"
                  key="PaMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PaMEAC}
                  onChange={(event) => {
                    this.saveField("PaMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.PaMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AirMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="AirMEAC"
                  key="AirMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.AirMEAC}
                  onChange={(event) => {
                    this.saveField("AirMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.AirMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TerAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TerAC"
                  key="TerAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TerAC}
                  onChange={(event) => {
                    this.saveField("TerAC", event.target.value);
                  }}
                  error={this.state.validateStatus.TerAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlipAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="SlipAC"
                  key="SlipAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SlipAC}
                  onChange={(event) => {
                    this.saveField("SlipAC", event.target.value);
                  }}
                  error={this.state.validateStatus.SlipAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFOAC"
                  key="RobFOAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFOAC}
                  onChange={(event) => {
                    this.saveField("RobFOAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFOAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDOAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobDOAC"
                  key="RobDOAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobDOAC}
                  onChange={(event) => {
                    this.saveField("RobDOAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobDOAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSumpAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobSumpAC"
                  key="RobSumpAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobSumpAC}
                  onChange={(event) => {
                    this.saveField("RobSumpAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobSumpAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSysAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobSysAC"
                  key="RobSysAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobSysAC}
                  onChange={(event) => {
                    this.saveField("RobSysAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobSysAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobCylAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobCylAC"
                  key="RobCylAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobCylAC}
                  onChange={(event) => {
                    this.saveField("RobCylAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobCylAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobAEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobAEAC"
                  key="RobAEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobAEAC}
                  onChange={(event) => {
                    this.saveField("RobAEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobHydAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobHydAC"
                  key="RobHydAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobHydAC}
                  onChange={(event) => {
                    this.saveField("RobHydAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobHydAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFWAC"
                  key="RobFWAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFWAC}
                  onChange={(event) => {
                    this.saveField("RobFWAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFWAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFOAA"
                  key="RobFOAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFOAA}
                  onChange={(event) => {
                    this.saveField("RobFOAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFOAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDOAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobDOAA"
                  key="RobDOAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobDOAA}
                  onChange={(event) => {
                    this.saveField("RobDOAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobDOAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSumpAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobSumpAA"
                  key="RobSumpAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobSumpAA}
                  onChange={(event) => {
                    this.saveField("RobSumpAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobSumpAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSysAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobSysAA"
                  key="RobSysAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobSysAA}
                  onChange={(event) => {
                    this.saveField("RobSysAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobSysAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobCylAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobCylAA"
                  key="RobCylAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobCylAA}
                  onChange={(event) => {
                    this.saveField("RobCylAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobCylAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobAEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobAEAA"
                  key="RobAEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobAEAA}
                  onChange={(event) => {
                    this.saveField("RobAEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobHydAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobHydAA"
                  key="RobHydAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobHydAA}
                  onChange={(event) => {
                    this.saveField("RobHydAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobHydAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFWAA"
                  key="RobFWAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFWAA}
                  onChange={(event) => {
                    this.saveField("RobFWAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFWAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmCounterMEAC"
                  key="RpmCounterMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmCounterMEAC}
                  onChange={(event) => {
                    this.saveField("RpmCounterMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowMEAC"
                  key="FlowMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowMEAC}
                  onChange={(event) => {
                    this.saveField("FlowMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowAEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowAEAC"
                  key="FlowAEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowAEAC}
                  onChange={(event) => {
                    this.saveField("FlowAEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowBLRAC"
                  key="FlowBLRAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowBLRAC}
                  onChange={(event) => {
                    this.saveField("FlowBLRAC", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowBLRAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowTempAC"
                  key="FlowTempAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowTempAC}
                  onChange={(event) => {
                    this.saveField("FlowTempAC", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowTempAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.METempAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="METempAC"
                  key="METempAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.METempAC}
                  onChange={(event) => {
                    this.saveField("METempAC", event.target.value);
                  }}
                  error={this.state.validateStatus.METempAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmCounterMEAA"
                  key="RpmCounterMEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmCounterMEAA}
                  onChange={(event) => {
                    this.saveField("RpmCounterMEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowMEAA"
                  key="FlowMEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowMEAA}
                  onChange={(event) => {
                    this.saveField("FlowMEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowAEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowAEAA"
                  key="FlowAEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowAEAA}
                  onChange={(event) => {
                    this.saveField("FlowAEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowBLRAA"
                  key="FlowBLRAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowBLRAA}
                  onChange={(event) => {
                    this.saveField("FlowBLRAA", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowBLRAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowTempAA"
                  key="FlowTempAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowTempAA}
                  onChange={(event) => {
                    this.saveField("FlowTempAA", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowTempAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.METempAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="METempAA"
                  key="METempAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.METempAA}
                  onChange={(event) => {
                    this.saveField("METempAA", event.target.value);
                  }}
                  error={this.state.validateStatus.METempAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOMEAC"
                  key="ConsFOMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOMEAC}
                  onChange={(event) => {
                    this.saveField("ConsFOMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOMEAC"
                  key="ConsDOMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOMEAC}
                  onChange={(event) => {
                    this.saveField("ConsDOMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOAEAC"
                  key="ConsFOAEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOAEAC}
                  onChange={(event) => {
                    this.saveField("ConsFOAEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOAEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOAEAC"
                  key="ConsDOAEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOAEAC}
                  onChange={(event) => {
                    this.saveField("ConsDOAEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOBLRAC"
                  key="ConsFOBLRAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOBLRAC}
                  onChange={(event) => {
                    this.saveField("ConsFOBLRAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOBLRAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOBLRAC"
                  key="ConsDOBLRAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOBLRAC}
                  onChange={(event) => {
                    this.saveField("ConsDOBLRAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsCylAC"
                  key="ConsCylAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsCylAC}
                  onChange={(event) => {
                    this.saveField("ConsCylAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsCylAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsSysAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsSysAC"
                  key="ConsSysAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsSysAC}
                  onChange={(event) => {
                    this.saveField("ConsSysAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsSysAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsAEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsAEAC"
                  key="ConsAEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsAEAC}
                  onChange={(event) => {
                    this.saveField("ConsAEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsHydAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsHydAC"
                  key="ConsHydAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsHydAC}
                  onChange={(event) => {
                    this.saveField("ConsHydAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsHydAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOMEAA"
                  key="ConsFOMEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOMEAA}
                  onChange={(event) => {
                    this.saveField("ConsFOMEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOMEAA"
                  key="ConsDOMEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOMEAA}
                  onChange={(event) => {
                    this.saveField("ConsDOMEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOAEAA"
                  key="ConsFOAEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOAEAA}
                  onChange={(event) => {
                    this.saveField("ConsFOAEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOAEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOAEAA"
                  key="ConsDOAEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOAEAA}
                  onChange={(event) => {
                    this.saveField("ConsDOAEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOBLRAA"
                  key="ConsFOBLRAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOBLRAA}
                  onChange={(event) => {
                    this.saveField("ConsFOBLRAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOBLRAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOBLRAA"
                  key="ConsDOBLRAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOBLRAA}
                  onChange={(event) => {
                    this.saveField("ConsDOBLRAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsCylAA"
                  key="ConsCylAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsCylAA}
                  onChange={(event) => {
                    this.saveField("ConsCylAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsCylAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsSysAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsSysAA"
                  key="ConsSysAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsSysAA}
                  onChange={(event) => {
                    this.saveField("ConsSysAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsSysAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsAEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsAEAA"
                  key="ConsAEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsAEAA}
                  onChange={(event) => {
                    this.saveField("ConsAEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsHydAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsHydAA"
                  key="ConsHydAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsHydAA}
                  onChange={(event) => {
                    this.saveField("ConsHydAA", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsHydAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FWPureAC"
                  key="FWPureAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FWPureAC}
                  onChange={(event) => {
                    this.saveField("FWPureAC", event.target.value);
                  }}
                  error={this.state.validateStatus.FWPureAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhMEAC"
                  key="RunhMEAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhMEAC}
                  onChange={(event) => {
                    this.saveField("RunhMEAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1AC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE1AC"
                  key="RunhAE1AC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE1AC}
                  onChange={(event) => {
                    this.saveField("RunhAE1AC", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE1AC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE2AC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE2AC"
                  key="RunhAE2AC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE2AC}
                  onChange={(event) => {
                    this.saveField("RunhAE2AC", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE2AC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE3AC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE3AC"
                  key="RunhAE3AC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE3AC}
                  onChange={(event) => {
                    this.saveField("RunhAE3AC", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE3AC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhIGAC"
                  key="RunhIGAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhIGAC}
                  onChange={(event) => {
                    this.saveField("RunhIGAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhIGAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhMEAA"
                  key="RunhMEAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhMEAA}
                  onChange={(event) => {
                    this.saveField("RunhMEAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1AA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE1AA"
                  key="RunhAE1AA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE1AA}
                  onChange={(event) => {
                    this.saveField("RunhAE1AA", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE1AA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE2AA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE2AA"
                  key="RunhAE2AA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE2AA}
                  onChange={(event) => {
                    this.saveField("RunhAE2AA", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE2AA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE3AA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE3AA"
                  key="RunhAE3AA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE3AA}
                  onChange={(event) => {
                    this.saveField("RunhAE3AA", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE3AA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhIGAA"
                  key="RunhIGAA"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhIGAA}
                  onChange={(event) => {
                    this.saveField("RunhIGAA", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhIGAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFODCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFODCAC"
                  key="ConsFODCAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFODCAC}
                  onChange={(event) => {
                    this.saveField("ConsFODCAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFODCAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsBHPDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsBHPDCAC"
                  key="ConsBHPDCAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsBHPDCAC}
                  onChange={(event) => {
                    this.saveField("ConsBHPDCAC", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsBHPDCAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LoadDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="LoadDCAC"
                  key="LoadDCAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.LoadDCAC}
                  onChange={(event) => {
                    this.saveField("LoadDCAC", event.target.value);
                  }}
                  error={this.state.validateStatus.LoadDCAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmMEDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmMEDCAC"
                  key="RpmMEDCAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmMEDCAC}
                  onChange={(event) => {
                    this.saveField("RpmMEDCAC", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmMEDCAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlipDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="SlipDCAC"
                  key="SlipDCAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SlipDCAC}
                  onChange={(event) => {
                    this.saveField("SlipDCAC", event.target.value);
                  }}
                  error={this.state.validateStatus.SlipDCAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AvgSpeedDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="AvgSpeedDCAC"
                  key="AvgSpeedDCAC"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.AvgSpeedDCAC}
                  onChange={(event) => {
                    this.saveField("AvgSpeedDCAC", event.target.value);
                  }}
                  error={this.state.validateStatus.AvgSpeedDCAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SeaMapLog}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="SeaMapLog"
                  key="SeaMapLog"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.SeaMapLog}
                  onChange={(event) => {
                    this.saveField("SeaMapLog", event.target.value);
                  }}
                  error={this.state.validateStatus.SeaMapLog !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ArrivalReportEditDialog
);
