import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  LinearProgress,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
export default class LoadingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            style={{
              minWidth: 450,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            {this.props.showRetry ? (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography>Bấm để thử lại</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <IconButton
                    aria-label="sync"
                    color="primary"
                    sx={{ paddingTop: 2 }}
                    onClick={() => this.props.onRetry()}
                  >
                    <SyncIcon />
                  </IconButton>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography>Đang kiểm tra...</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    width: "100%",
                    padding: 17,
                  }}>
                    <LinearProgress  />
                  </div>
                </Box>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
