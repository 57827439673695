/*Quan ly GCN dang ky*/
import React from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  Typography,
  AppBar,
  CircularProgress,
  Dialog,
  DialogContent
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import RedeemIcon from "@mui/icons-material/Redeem";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import moment from "moment";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import SimpleDialog from "../components/SimpleDialog";
import PhanQuyenEditDialog from "./PhanQuyenEditDialog";

import MenuPrint from "../components/MenuPrint";
import UserEditDialog from "./UserEditDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const styles = {
  timkiem: {
    backgroundColor: "red",
  },
  grow: {
    flexFlow: 1,
  },
  grid: {
    height: `${window.innerHeight - 150}px`,
    backgroundColor: "red",
  },
  alternateRow: {
    backgroundColor: "red",
  },
  iconLabelWrapper: {
    flexDirection: "row",
  },
  labelContainer: {
    width: "auto",
    padding: 0,
  },
};
const Tau_TrangThai_Render = (props) => {
  let row = props.data;
  return row.TrangThai ? null : (
    <BlockIcon sx={{ color: "red" }} fontSize="small" />
  );
};
const Tau_NgayGCN_Render = (props) => {
  let row = props.data;
  return moment(row.NGAYHD).format("DD/MM/YYYY");
};
const Tau_NgayHH_Render = (props) => {
  let row = props.data;
  return moment(row.HetHan).format("DD/MM/YYYY");
};
const columns_tau = [
  {
    name: "BRCD",
    header: "Đơn vị",
    headerAlign: "center",
    defaultWidth: 95,
  },
  {
    name: "USCD",
    header: "Tài khoản",
    headerAlign: "center",
    defaultWidth: 120,
  },
  {
    name: "USNM",
    header: "Họ tên",
    headerAlign: "center",
    defaultWidth: 200,
  },
  {
    name: "EMAIL",
    header: "Email",
    headerAlign: "center",
    defaultWidth: 180,
  },

  {
    name: "Active",
    header: "Hoạt động",
    headerAlign: "center",
    textAlign: "center",
    defaultWidth: 110,
    render: (props) => (props.data.ACTIVE === "1" ? "x" : ""),
  },

  {
    name: "ID",
    header: "",
    headerAlign: "center",
    defaultWidth: 240,
  },
];
const defaultSortInfo = { name: "NgayCN", dir: 0, type: "date" };
const rows = [
  {
    id: 0,
    TrangThai: true,
    TenTau: "PHUONG NAM 2",
    SoGCN: "HPG-00010-1",
    NgayGCN: new Date(),
    TenLoaiDK: "Không thời hạn",
    TenCQDK: "Hải phòng",
  },
  {
    id: 1,
    TrangThai: false,
    TenTau: "PHUONG NAM 3",
    SoGCN: "HPG-00010-2",
    NgayGCN: new Date(),
    TenLoaiDK: "Tạm thời",
    TenCQDK: "Đà Nẵng",
  },
];

const loadTauDataError = (error, empty, log, trace) => {
  return !error ? (
    <b
      style={{
        padding: 8,
        border: "1px solid #7986cb",
        color: "#0000ff",
        borderRadius: 4,
      }}
    >
      {error ? log : empty ? "Không có bản ghi nào!" : ""}
    </b>
  ) : (
    <a
      href="#"
      style={{ textDecoration: "none" }}
      onClick={() =>
        toast.error(trace, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      }
    >
      <b
        style={{
          padding: 8,
          border: "1px solid #7986cb",
          color: "red",
          borderRadius: 4,
        }}
      >
        {error ? log : empty ? "Không có bản ghi nào!" : ""}
      </b>
    </a>
  );
};
var lastState = {};
export default class UsersPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  constructor(props) {
    super(props);
    this.doSearch = this.doSearch.bind(this);
    this.onSortInfoChange = this.onSortInfoChange.bind(this);
    this.loadData = this.loadData.bind(this);
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      saving: false, //co dang save du lieu hay khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      loading_coquan: true, // co dang load ding danh muc nguon tin ko
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      before:
        props.value === undefined
          ? {
              ThoiGian: new Date(),
            }
          : props.value, //thong tin truoc khi edit
      after:
        props.value === undefined
          ? {
              PageIndex: 1,
              PageSize: 10,
              Ascending: true,
              SortBy: 0, //0-tentau,1-sogcn
              MaDV: "",
              HoTen: "",
              Email: "",
            }
          : props.value, //thong tin dang edit
      validateStatus: { error: false }, //trang thai validate
      source_donvi: [], //danh muc options cho nguon tin
      //thong tin tau
      selectedRecord: {}, //record trong danh sach tau duoc chon
      date: new Date(),
      //edit
      open_edit_hoadon: false, //open form nhap dinh bien
      open_edit_dinhnghia: false, //open form dinh nghia
      open_edit_nhap: false, //show form nhap thu cong
      open_confirm_Bcc: false, //hien thi xac nhan xoa dinh bien
      open_edit_user: false, //hien thi form nhap user
      hasThem: global.hasRoles("Admin"),
      hasXuly: global.hasRoles("Admin;USMS.R111"),
    };
  }
  saveField = (fieldName, value) => {
    var s = this.state;
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    }
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "MaDV"
    ) {
      this.setState(this.state);
    } else {
      this.setState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(this.state);
  };
  componentDidMount() {
    this.loadDanhMuc();
  }
  componentDidUpdate(props, state) {
    lastState = state;
  }
  loadDanhMuc() {
    //load danh muc doi tac
    server
      .queryCache("PhanQuyen/GetBranches")
      .then((response) => {
        var list = [];
        //default  theo tai khoan
        this.setState({
          canSaved: true,
          source_donvi: response,
          loading_coquan: false,
          after: {
            ...this.state.after,
            MaDV:
              myLib.isEmpty(global.User.MaDV) && response.length > 0
                ? response[0].BRCD
                : global.User.MaDV,
          },
        }, ()=> this.doSearch());
      })
      .catch((error) => {
        console.log(error.message);
        console.log(error);
        this.setState({ loading_coquan: false });
      });
  }
  //Thuc hien search
  doSearch = () => {
    console.log(this.state.after);
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * 10,
      limit: this.state.after.PageSize,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectedRecord: {},
    });
  };
  onSortInfoChange = (value) => {
    //const newSort = value ? { type: value.type, name: value.name, dir: value.dir } : newValue
    const newSort = value
      ? value.name === "SoHD"
        ? 1
        : value.name === "NgayCN"
        ? 2
        : 3
      : 0;
    const asc = value ? value.dir >= 0 : false;
    this.saveData({ SortBy: newSort, Ascending: asc });
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var loader = new Promise((resolve, eject) => {
      //ghi lai tieu chi tim kiem

      let dieukien = this.state.after;
      if (this.state.source_donvi.length > 0) {
        server
          .post("PhanQuyen/SearchUser", dieukien)
          .then((response) => {
            this.setState({
              search_error: false,
              search_result: { data: response.Records, count: response.Total },
              selectedRecord: undefined,
            });
            resolve({ data: response.Records, count: response.Total });
          })
          .catch((error) => {
            var message = "";
            var log = "";
            try {
              var obj = JSON.parse(error);
              message = obj.message;
              log = obj.traceLog;
            } catch (e) {
              message = error + "";
            }
            this.setState({
              search_error: true,
              errorMessage: message,
              errorLog: log,
              search_result: { data: [], count: 0 },
              selectedRecord: undefined,
            });
            resolve({ data: [], count: 0 });
          });
      } else {
        resolve({ data: [], count: 0 });
      }
    });
    return loader;
  };

  handleServerError(error) {
    console.error(error);
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
    } catch (e) {
      message = error + "";
    }
    this.setState(
      {
        search_error: true,
        errorMessage: message,
        errorLog: log,
      },
      () => {
        toast.error(message + log, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    );
  }

  //call khi user thay doi trang
  render() {
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AppBar sx={{ position: "relative" }}>
            <Stack direction="row">
              <Typography
                sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
                variant="h6"
                component="div"
              >
                Quản lý người dùng
              </Typography>
              <Stack direction="row" spacing={4}>
                {this.state.loading_data ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.saving ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                
              </Stack>
            </Stack>
          </AppBar>
          <div>
            <Paper variant="outlined">
              <Grid container spacing={2} columns={24}>
                <Grid item xs={3}>
                  <FormLabel>Bộ phận:</FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <Select
                    id="cboKV"
                    fullWidth
                    value={this.state.after.MaDV}
                    onChange={(event) => {
                      this.saveField("MaDV", event.target.value);
                    }}
                  >
                    <MenuItem key={"k00"} value={""}>
                      {"---- Tất cả ----"}
                    </MenuItem>
                    {this.state.source_donvi
                      ? this.state.source_donvi.map((item, index) => {
                          return (
                            <MenuItem key={item.BRCD} value={item.BRCD}>
                              {item.BRNM}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </Grid>

                <Grid item xs={8}></Grid>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <FormLabel>Họ tên:</FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="sogcn"
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={this.state.after.HoTen}
                    onChange={(event) =>
                      this.saveField("HoTen", event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormLabel>Email:</FormLabel>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="sogcn"
                    fullWidth
                    value={this.state.after.Email}
                    onChange={(event) =>
                      this.saveField("Email", event.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon fontSize="small" />}
                    onClick={this.doSearch}
                  >
                    Tìm kiếm
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <div style={{ height: 2 }}></div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ReactDataGrid
                  style={{ height: window.innerHeight - 230, fontSize: 12 }}
                  columns={columns_tau}
                  pagination={false}
                  multiSelect={false}
                  checkboxColumn
                  defaultLimit={10}
                  limit={this.state.after.PageSize}
                  skip={
                    (this.state.after.PageIndex - 1) * this.state.after.PageSize
                  }
                  onSkipChange={(skip) => {
                    this.saveField(
                      "PageIndex",
                      skip / this.state.after.PageSize + 1
                    );
                    //chi load khi da co data khi load = promies
                    if (this.state.search_dataSource.length === undefined) {
                      this.doSearch();
                    }
                  }}
                  dataSource={this.state.search_dataSource}
                  idProperty="USCD"
                  rowHeight={30}
                  defaultSortInfo={defaultSortInfo}
                  onSortInfoChange={this.onSortInfoChange}
                  onLimitChange={(v) => this.saveField("PageSize", v)}
                  emptyText={loadTauDataError(
                    this.state.search_error,
                    this.state.search_result.data.length === 0,
                    this.state.errorMessage,
                    this.state.errorLog
                  )}
                  skipLoadOnMount={true}
                  enableSelection={true}
                  onSelectionChange={({ data, selected, unselected }) => {
                    this.setState({ selectedRecord: data });
                  }}
                ></ReactDataGrid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} columns={24}>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={
                        this.state.after.MaDV === undefined ||
                        this.state.after.MaDV == ""
                      }
                      onClick={() => {
                        this.setState({
                          open_edit_user: true,
                          editting_user: undefined,
                        });
                      }}
                    >
                      Thêm
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={
                        this.state.selectedRecord === undefined ||
                        this.state.selectedRecord === null ||
                        !this.state.hasThem
                      }
                      fullWidth
                      onClick={() => {
                        //lay thong tin user
                        server
                          .query("PhanQuyen/GetUser", {
                            uscd: this.state.selectedRecord.USCD,
                          })
                          .then((response) => {
                            this.setState({
                              open_edit_user: true,
                              editting_user: response[0],
                            });
                          })
                          .catch((error) => console.log(error));
                      }}
                    >
                      Sửa
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={
                        this.state.selectedRecord === undefined ||
                        this.state.selectedRecord === null ||
                        !this.state.hasThem
                      }
                      fullWidth
                      onClick={() => {
                        this.setState({
                          showConfirmXoa: true,
                          editting_user: this.state.selectedRecord,
                        });
                      }}
                    >
                      Xóa
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={
                        this.state.selectedRecord === undefined ||
                        this.state.selectedRecord === null ||
                        !this.state.hasThem
                      }
                      fullWidth
                      onClick={() => {
                        this.setState({
                          showConfirmReset: true,
                          editting_user: this.state.selectedRecord,
                        });
                      }}
                    >
                      Reset MK
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={
                        this.state.selectedRecord === undefined ||
                        this.state.selectedRecord === null ||
                        !this.state.hasThem ||
                        (this.state.selectedRecord.BRCD !== global.User.MaDV &&
                          !this.state.hasXuly)
                      }
                      fullWidth
                      onClick={() => {
                        this.setState({
                          open_edit_dinhnghia: true,
                          editting_dangkytau: undefined,
                          editting_user: this.state.selectedRecord,
                        });
                      }}
                    >
                      Phân quyền
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                       
                      fullWidth
                      onClick={() => {
                        this.props.close();
                      }}
                    >
                      Thoát
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ToastContainer
              position="top-right"
              autoClose={false}
              hideProgressBar
              newestOnTop={true}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover={false}
              style={{ fontSize: 12, width: 750 }}
              limit={5}
            />

            {this.state.open_edit_dinhnghia ? (
              <PhanQuyenEditDialog
                open={this.state.open_edit_dinhnghia}
                edittingRecord={this.state.editting_user}
                close={() =>
                  this.setState({
                    open_edit_dinhnghia: false,
                  })
                }
              />
            ) : null}
            {this.state.open_edit_user ? (
              <UserEditDialog
                open={this.state.open_edit_user}
                DanhMucDonVi={this.state.source_donvi}
                MaDonVi={this.state.after.MaDV}
                edittingRecord={this.state.editting_user}
                close={(ok) => {
                  if (ok) {
                    this.setState(
                      {
                        open_edit_user: false,
                      },
                      () => this.doSearch()
                    );
                  } else {
                    this.setState({
                      open_edit_user: false,
                    });
                  }
                }}
              />
            ) : null}
            {this.state.showConfirmXoa ? (
              <SimpleDialog
                confirm
                message={
                  "Bạn muốn xóa user:" + this.state.selectedRecord.USCD + " ?"
                }
                open={true}
                close={(ok) => {
                  if (ok) {
                    //thuc hien xoa
                    server
                      .post("PhanQuyen/XoaUser", {
                        USCD: this.state.selectedRecord.USCD,
                      })
                      .then((response) => {
                        this.setState({ showConfirmXoa: false });
                        //reload
                        this.doSearch();
                      })
                      .catch((error) => {
                        this.setState({ showConfirmXoa: false });
                        this.handleServerError(error);
                      });
                  } else {
                    this.setState({ showConfirmXoa: false });
                  }
                }}
              />
            ) : null}
            {this.state.showConfirmReset ? (
              <SimpleDialog
                confirm
                message={
                  "Bạn muốn reset mật khẩu (abc123) cho:" +
                  this.state.selectedRecord.USCD +
                  " ?"
                }
                open={true}
                close={(ok) => {
                  if (ok) {
                    //thuc hien xoa
                    server
                      .post("PhanQuyen/ResetPassword", {
                        USCD: this.state.selectedRecord.USCD,
                      })
                      .then((response) => {
                        this.setState({ showConfirmReset: false });
                        //reload
                        this.doSearch();
                      })
                      .catch((error) => {
                        this.setState({ showConfirmReset: false });
                        this.handleServerError(error);
                      });
                  } else {
                    this.setState({ showConfirmReset: false });
                  }
                }}
              />
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
