const myLib = require("../lib/MyLib");
const code_full = `
import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
} from "@mui/material";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";

import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import ButtonTimKiem from "../components/ButtonTimKiem";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = false;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const fieldLabels = {fieldLabels};
const buttonLabels = englishUI ?
{
  btnThem: "Add",
  btnSua: "Edit",
}
: 
{
  btnThem: "Thêm",
  btnSua: "Sửa",
};
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = {gridColumns};
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "id", dir: 0, type: "number" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
class {FormName} extends React.Component {
    selectionCounter = 0; //dem so lan click chon row trong grid
    lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
    constructor(props) {
      super(props);
      this.columns_grid = default_gridColumns;
      //load lai thong tin giao dien cua phien lam viec truoc do
      this.state = {
        dataChanged: false, //du lieu co su thay doi hay khong
        loadOnStart: true, //co load du lieu ngay khi mo form khong
        errorMessage: "", //mo ta loi neu co
        errorLog: "", //mo ta chi tiet loi
        DanhMuc: {ListDanhMuc}, //luu cac du lieu danh muc su dung trong form
        //searching
        search_loading: false,
        search_result: { data: [], count: 0 }, //ket qua tim kiem
        search_dataSource: [],
        search_error: false, //search co loi khong
        lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
        sortInfo: defaultSortInfo, //thong tin sap xep cua grid
        after: //chua cac tieu chi tim kiem
          props.value === undefined //gia tri truyen vao tu form cha
            ? {
                PageIndex: 1,
                PageSize: 20,
                Ascending: true,
                SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
                //danh sach cac tieu chi tim kiem
                {FilterFields}
              }
            : props.value, //thong tin tieu chi truyen vao tu form cha
        
        //ban ghi duoc lua chon trong grid
        rowSelected: undefined, //record trong danh sach chuyen duoc chon
      };
    }
    //ghi nhan 1 tieu chi tim kiem
    saveField = (fieldName, value) => {
      var s = this.state;
      if (fieldName !== "PageIndex") {
        s.after.PageIndex = 1;
      } else {
        if (s.after.PageIndex === value) {
          return; //ko co su thay doi thi bo qua
        }
      }
      s.after[fieldName] = value;
      s.dataChanged = true;
      s.canSaved = s.dataChanged;
      if (
        fieldName !== "PageSize" &&
        fieldName !== "PageIndex" &&
        fieldName !== "SortBy"
      ) {
        this.setMyState(this.state);
      } else {
        //lien quan den phan trang hoac sap xep thi reload
        this.setMyState(this.state, () => {
          this.doSearch();
        });
      }
    };
    //ghi nhan nhieu gia tri tieu chi cung 1 luc
    saveData = (obj) => {
      var s = this.state;
      s.after = { ...s.after, ...obj };
      s.dataChanged = true;
      s.canSaved = s.dataChanged;
      if (obj.Ascending || obj.SortBy) {
        this.setMyState(s, () => this.doSearch());
      } else {
        this.setMyState(s);
      }
    };
    //duoc su dung neu form hien thi trong che do MDI
    setMyState = (state, callback) => {
      this.setState(state, () => {
        //su dung cho muc dich MDI form
        if (this.props.onStateChanged) {
          this.props.onStateChanged(this.state);
        }
        //reload data
        if (callback) {
          callback();
        }
      });
    };
    //su kien form load
    componentDidMount() {
      this.loadDanhMuc({DanhMuc}, ()=>{});
    }
    static getDerivedStateFromProps(nextProps, prevState) {
      return null;
    }
    shouldComponentUpdate(nextProps, nextState) {
      return true;
    }
    
    componentDidUpdate(props, state) {}
    //load du lieu danh muc theo danh sach table
    loadDanhMuc(danhsach, nextFunc) {
      //load danh muc
      if(danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
    }
    //khi thay doi sap xep cot
    onSortInfoChange = (value) => {
      const newSort = value
        ? { type: value.type, name: value.name, dir: value.dir }
        : value;
      //ghi nhan vao sort by
      this.setState(
        {
          lastSortInfo: this.state.sortInfo
            ? this.state.sortInfo
            : this.state.lastSortInfo,
          sortInfo: newSort,
        },
        () => this.doSearch()
      );
    };
    loadData = ({ skip, limit, sortInfo }) => {
      var serverSortInfo = this.state.sortInfo
        ? this.state.sortInfo
        : this.state.lastSortInfo;
  
      var loader = new Promise((resolve, eject) => {
        server
          .post("Data/DoRequest", {
            Function: "Proc_{DataSetName}_Search",
            ThamSo: {
              ...this.state.after,
              PageIndex: skip / limit + 1,
              PageSize: limit,
              SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
              Ascending: serverSortInfo.dir == 1,
            },
          })
          .then((response) => {
            this.setState({
              search_loading: false,
              search_error: false,
              search_result: {
                data: response.DataList,
                count: response.DataCount[0].Total,
              },
              rowSelected: undefined,
              rowDoubleClicked: undefined,
            });
            resolve({
              data: response.DataList,
              count: response.DataCount[0].Total,
            });
          })
          .catch((error) => {
            var message = "";
            var log = "";
            try {
              var obj = JSON.parse(error);
              message = obj.message;
              log = obj.traceLog;
            } catch (e) {
              message = error + "";
            }
            this.setState({
              search_loading: false,
              search_error: true,
              errorMessage: message,
              errorLog: log,
              search_result: { data: [], count: 0 },
              rowSelected: undefined,
            });
            resolve({ data: [], count: 0 });
          });
      });
      return loader;
    };
    //Thuc hien search
    doSearch = () => {
      var loader = this.loadData({
        skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
        limit: this.state.after.PageSize,
        sortInfo: this.state.sortInfo,
      });
      this.setState({
        search_loading: true,
        search_dataSource: loader, //chi load sau khi co danh muc
        selectdReecord: {},
      });
    };
  
    //call khi user thay doi trang
    render() {
        //lay mot so thuoc tinh truyen vao tu form cha
      const { classes, theme, parentHeight } = this.props;
      return (
        <div>
          <Paper variant="outlined">
            <Grid container spacing={2} columns={24}>
              {PhanTimKiem}
            </Grid>
          </Paper>
          <div style={{ height: 2 }}></div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ReactDataGrid
                style={{
                  height:
                    parentHeight - {offsetHeight},
                  fontSize: 12,
                }}
                showZebraRows={true}
                i18n={englishUI ? undefined : ReactDataGrid_i18n}
                columns={this.columns_grid}
                pagination={true}
                multiSelect={false}
                showHoverRows={false}
                checkboxColumn={{
                  render: (props) => (
                    <input
                      type="checkbox"
                      readOnly
                      checked={props.rowSelected}
                    ></input>
                  ),
                }}
                defaultLimit={defaultGridLimit}
                limit={this.state.after.PageSize}
                skip={
                  (this.state.after.PageIndex - 1) * this.state.after.PageSize
                }
                onSkipChange={(skip) => {
                  this.saveField(
                    "PageIndex",
                    skip / this.state.after.PageSize + 1
                  );
                }}
                dataSource={this.state.search_dataSource}
                idProperty="{gridIdProperty}"
                headerHeight={30}
                rowHeight={30}
                allowUnsort={false}
                defaultSortInfo={defaultSortInfo}
                sortInfo={this.state.sortInfo}
                onSortInfoChange={this.onSortInfoChange}
                onLimitChange={(v) => this.saveField("PageSize", v)}
                emptyText={loadDataError(
                  this.state.search_error,
                  this.state.search_result.data.length === 0,
                  this.state.errorMessage,
                  this.state.errorLog
                )}
                skipLoadOnMount={true}
                enableSelection={true}
                onSelectionChange={({ data, selected, unselected }) => {
                  var now = new Date().getTime();
                  var diff = now - this.lastClick;
                  if (diff > 300) {
                    this.setState({ rowSelected: data });
                  } else {
                    //double click
                    this.setState({
                      rowSelected: data,
                      rowDoubleClicked: data,
                    });
                  }
                  this.lastClick = now;
                }}
              ></ReactDataGrid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} columns={24}>
                  <Grid item xs={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      this.setMyState({
                        open_edit_sukien: true,
                        edittingRecord: undefined,
                      });
                    }}
                  >
                    {buttonLabels.btnThem}
                  </Button>
                </Grid>
                <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                disabled={this.state.rowSelected == undefined}
                onClick={() => {
                  this.setMyState({
                    open_edit_sukien: true,
                    edittingRecord: this.state.rowSelected,
                  });
                }}
              >
                {buttonLabels.btnSua}
              </Button>
            </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ToastContainer
            position="top-right"
            autoClose={false}
            hideProgressBar
            newestOnTop={true}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover={false}
            style={{ fontSize: 12, width: 750 }}
            limit={5}
          />
          
        </div>
      );
    }
  }
  export default withStyles(formStyles, { withTheme: true })({FormName});
`;
///tra ve grid columns theo config
function generate_gridColumns(gridConfig) {
  var cols = "";
  gridConfig.GridColumns.map((field) => {
    //tao column dua theo dinh nghia
    var oneCol = "";
    //lay cac truong va gia tri co san trong field
    var keys = Object.keys(field);
    keys.map(
      (k) =>
        (oneCol +=
          (oneCol != "" ? ",\n" : "") +
          k +
          " : " +
          (isNaN(field[k]) ? '"' + field[k] + '"' : field[k]))
    );
    oneCol +=
      ',\nheader: englishUI ? "' +
      field.headerEN +
      '" : "' +
      field.header +
      '"';
    oneCol +=
      ",\nrender: " +
      (field.format
        ? field.format == "checkbox"
          ? `({ data }) => (
                  <Checkbox
                    size="small"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={data[field.name]}
                  />
                )`.replace("[field.name]", "." + field.name)
          : field.type == "date"
          ? `(props) =>
                  props.data[field.name]
                    ? moment(props.data[field.name]).format(\"field.format\")
                    : null`
              .replace("[field.name]", "." + field.name)
              .replace("[field.name]", "." + field.name)
              .replace("field.format", field.format)
          : field.type == "number"
          ? `(props) => (
                  <NumericFormat
                    thousandSeparator=","
                    value={props.data[field.name]}
                    displayType="text"
                  />
                )
              : undefined`.replace("[field.name]", "." + field.name)
          : "undefined"
        : "undefined");
    cols += (cols != "" ? ",\n" : "") + "{\n" + oneCol + "\n}";
  });
  return "[\n" + cols + "\n]";
}
function generate_TenCacDanhMuc(filterFields) {
  var danhsach = "";
  filterFields.map((field) => {
    if (!myLib.isEmpty(field.SourceName)) {
      danhsach += (danhsach != "" ? "," : "") + field.SourceName;
    }
  });
  return '"' + (danhsach == "" ? "null" : danhsach) + '"';
}
function generate_DanhMuc(filterFields) {
  var danhsach = "";
  filterFields.map((field) => {
    if (!myLib.isEmpty(field.SourceName)) {
      danhsach += (danhsach != "" ? "," : "") + field.SourceName + ":[]";
    }
  });
  return "{" + (danhsach == "" ? "" : danhsach) + "}";
}
function generate_TieuChi(filterFields) {
  var oneField = "";
  filterFields.map((field) => {
    //tao code cho tung field
    oneField += `
      <Grid item xs={field.LabelWidth}>
        <FormLabel>{fieldLabels.{FieldName}}</FormLabel>
      </Grid>
      `
      .replace("field.LabelWidth", field.LabelWidth)
      .replace("{FieldName}", field.FieldName);
    if (field.InputWidth) {
      //the grid bao ben ngoai input
      oneField += "<Grid item xs={" + field.InputWidth + "}>";
      //tao input combobox

      if (!myLib.isEmpty(field.SourceName) && !field.useAsync) {
        oneField += `
          <SelectFilter
              id={"field.FieldName"}
              key={"field.FieldName"}
              fullWidth
              autoFocus={autoFocus}
              options={danhsach}
              getOptionLabel={(item) => item[field.SourceLabel]}
              getOptionValue={(item) => item[field.SourceValue]}
              placeholder={placeHolder}
              styles={filterStyles}
              menuPosition={"fixed"}
              value={this.state.after.field.FieldName}
              isMulti={isMultiSelect}
              isClearable
              onChange={(item) => {
                {save}
              }}
            ></SelectFilter></Grid>
          `

          .replace(
            "{danhsach}",
            "{this.state.DanhMuc." + field.SourceName + "}"
          )
          .replace("[field.SourceLabel]", "." + field.SourceLabel)
          .replace("[field.SourceValue]", "." + field.SourceValue)
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replace("isMultiSelect", field.Compare.indexOf("in") >= 0)
          .replace(
            "{save}",
            field.Compare.indexOf("in") >= 0
              ? 'this.saveField("field.FieldName", item ? item : [])'
              : 'this.saveField("field.FieldName", item ? item : undefined)'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //tao input combobox async
      if (!myLib.isEmpty(field.SourceName) && field.useAsync) {
        oneField += `
        {
          this.state.DanhMuc.danhsach.length > 0 ?
        <AsyncSelect
              id={"field.FieldName"}
              key={"field.FieldName"}
              fullWidth
              cacheOptions
              defaultOptions
              autoFocus={autoFocus}
              getOptionLabel={(item) => item[field.SourceLabel]}
              getOptionValue={(item) => item[field.SourceValue]}
              placeholder={placeHolder}
              styles={filterStyles}
              menuPosition={"fixed"}
              value={this.state.after.field.FieldName}
              isMulti={isMultiSelect}
              isClearable
              loadOptions={(i, c) =>
                filterDanhSach(
                  this.state.DanhMuc.danhsach,
                  {field.SourceLabel},
                  i,
                  c
                )
              }
              onChange={(item) => {
                {save}
              }}
            ></AsyncSelect> : <OutlinedInput fullWidth readOnly />
          }</Grid>
          `

          .replaceAll("danhsach", field.SourceName + "")
          .replace("[field.SourceLabel]", "." + field.SourceLabel)
          .replace("{field.SourceLabel}", '"' + field.SourceLabel + '"')
          .replace("[field.SourceValue]", "." + field.SourceValue)
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replace("isMultiSelect", field.Compare.indexOf("in") >= 0)
          .replace(
            "{save}",
            field.Compare.indexOf("in") >= 0
              ? 'this.saveField("field.FieldName", item ? item : [])'
              : 'this.saveField("field.FieldName", item ? item : undefined)'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //tao input text box
      if (myLib.isEmpty(field.SourceName) && field.DataType == "string") {
        oneField += `
        <OutlinedInput
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={state.after.field.FieldName}
            onChange={(event) => {
              saveField(\"field.FieldName\", event.target.value);
            }}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //input la date
      if (myLib.isEmpty(field.SourceName) && field.DataType == "date") {
        oneField += `
        <MyDateEditor
        id={\"field.FieldName\"}
        key={\"field.FieldName\"}
        fullWidth
        autoFocus={autoFocus}
        value={state.after.field.FieldName}
        onChange={(value) => {
          saveField(\"field.FieldName\", value);
        }}
      />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //input la datetime
      if (myLib.isEmpty(field.SourceName) && field.DataType == "datetime") {
        oneField += `
        <MyDateTimeEditor
        id={\"field.FieldName\"}
        key={\"field.FieldName\"}
        fullWidth
        autoFocus={autoFocus}
        value={state.after.field.FieldName}
        onChange={(value) => {
          saveField(\"field.FieldName\", value);
        }}
      />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (myLib.isEmpty(field.SourceName) && field.DataType == "Bit") {
        oneField += `
        <FormControlLabel
          id={\"field.FieldName\"}
          key={\"field.FieldName\"}
            label={""}
            labelPlacement="end"
            fullWidth
            control={
              <Checkbox
                size="medium"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                checked={state.after.field.FieldName}
                onChange={(event) => {
                  saveField(\"field.FieldName\", event.target.checked);
                }}
              />
            }
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (
        myLib.isEmpty(field.SourceName) &&
        (field.DataType == "Int" || field.DataType == "int")
      ) {
        oneField += `
        <MyNumberEditor
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            decimalScale={0}
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={state.after.field.FieldName}
            onChange={(event) => {
              saveField(\"field.FieldName\", event.target.value);
            }}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (myLib.isEmpty(field.SourceName) && field.DataType == "decimal") {
        oneField += `
        <MyNumberEditor
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            decimalScale={2}
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={state.after.field.FieldName}
            onValueChange={(value) => {
              saveField(\"field.FieldName\", value);
            }}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (myLib.isEmpty(field.SourceName) && field.DataType == "lat") {
        oneField += `
        <MyLatLonEditor
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            latlon="lat"
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={state.after.field.FieldName}
            onChange={(event) => {
              saveField(\"field.FieldName\", event.target.value);
            }}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (myLib.isEmpty(field.SourceName) && field.DataType == "lon") {
        oneField += `
        <MyLatLonEditor
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            latlon="lon"
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={state.after.field.FieldName}
            onChange={(event) => {
              saveField(\"field.FieldName\", event.target.value);
            }}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //khoang trong phia sau
      if (field.EmptySpace) {
        oneField += `
      <Grid item xs={field.EmptySpace}>
      </Grid>
      `.replace("field.EmptySpace", field.EmptySpace);
      }
      //them nut tim kiem
      oneField += 
      `
      <Grid item xs={3}>
      <ButtonTimKiem
                variant="contained"
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                  if (item.menuName == "Reload") {
                    //load f5orm
                    this.loadForm(true);
                  }
                  if(item.menuName == "GetCode") {
                    //lay codejs
                    this.generateCodeReactJs();
                  }
                }}
              ></ButtonTimKiem></Grid>
      `
    }
  });
  return oneField;
}
//tao cac nhan label
function generate_Labels(filterFields) {
  var labelEnglish = "",
    labelTV = "";
  filterFields.map((field) => {
    labelEnglish +=
      (labelEnglish != "" ? ",\n" : "") +
      field.FieldName +
      ' : "' +
      field.LabelEN +
      '"';
    labelTV +=
      (labelTV != "" ? ",\n" : "") +
      field.FieldName +
      ' : "' +
      field.Label +
      '"';
  });
  return "englishUI ? \n{\n" + labelEnglish + "\n}\n:{\n" + labelTV + "\n}";
}
//tra ve dinh nghia tieu chi tim kiem
function generate_TieuChiFields(filterFields) {
  var danhsach = "";
  filterFields.map((field) => {
    danhsach +=
      (danhsach != "" ? ",\n" : "") +
      field.FieldName +
      " : " +
      (field.DefaultValue ? '"' + field.DefaultValue + '"' : "null");
  });
  return danhsach;
}
//tra ve code cua page tim kiem
export function generateCode_ManageDataPage(name, config) {
  var code = code_full;
  code = code.replaceAll("{FormName}", name);
  code = code.replace("{gridColumns}", generate_gridColumns(config.GridConfig));
  code = code.replace("{ListDanhMuc}", generate_DanhMuc(config.FilterFields));
  code = code.replace("{DanhMuc}", generate_TenCacDanhMuc(config.FilterFields));
  code = code.replace("{DataSetName}", config.DataSetName);
  code = code.replace(
    "{FilterFields}",
    generate_TieuChiFields(config.FilterFields)
  );
  code = code.replace("{PhanTimKiem}", generate_TieuChi(config.FilterFields));
  code = code.replace("{fieldLabels}", generate_Labels(config.FilterFields));
  code = code.replace("{offsetHeight}", config.GridConfig.OffsetHeight);
  code = code.replace("defaultGridLimit", config.GridConfig.DefaultLimit);
  code = code.replace("{gridIdProperty}", config.GridConfig.idColumnName);

  return code;
}
