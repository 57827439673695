import React from "react";
import { Button } from "@mui/material";
import { ThirteenMpSharp } from "@mui/icons-material";

//cau hinh mac dinh cua sheet
const default_config = {
  container: "luckysheet",
  plugins: ["chart"],
  showinfobar: false,
  sheetFormulaBar: false,
  enableAddRow: false,
  enableAddBackTop: false,
  data: [
    {
      name: "Sheet1",
      color: "",
      status: "1",
      order: 0,
      celldata: [],
      config: {},
      index: 0,
      row: 40,
      column: 26,
    },
  ],
};
class Luckysheet extends React.Component {
  bookCreated = false;
  newConfig = undefined;
  constructor(props) {
    super(props);
    this.state = {
      version: 0,
    };
    //bao lai cho parent biet
    props.callback(this, "load");
  }
  reload = (config, tag) => {
    config = config ? config : default_config;
    //
    if (!this.bookCreated) {
      this.newConfig = config;
      console.log("workbook not created");
      return;
    }
    //load lai excel theo config moi
    const luckysheet = window.luckysheet;
    console.log("destroy");
    //luckysheet.destroy();
    //tao lai
    //window.setTimeout(()=>, 100);
    //hieu chinh 1 so thong tin
    config.data.map((sheet) => (sheet.scrollTop = 0));
    console.log("create sheet", tag);
    luckysheet.create(config);
    //luckysheet.create(config);
  };
  componentDidMount() {
    const luckysheet = window.luckysheet;
    if (this.state.version >= 0) {
      console.log("lucky did mount, call create", this.newConfig);
      const config =
        this.newConfig !== undefined ? this.newConfig : default_config;
      config.hook = {
        workbookCreateAfter: (options) => {
          console.log("hook create after");
          this.bookCreated = true;
          const nextConfig = this.newConfig;

          this.newConfig = undefined; //xoa di de ko bi lap
          if (nextConfig !== undefined) {
            this.reload(nextConfig);
          } else {
            if (this.props.callback) {
              //goi lai callback neu co
              this.props.callback(this, "created");
            }
          }
        },
      };
      //tao lai
      luckysheet.create(config);
    }
  }
  render() {
    const luckyCss = {
      width: "100%",
      height: this.props.height,
    };

    return (
      <>
        <div id="luckysheet" style={luckyCss}></div>
      </>
    );
  }
}

export default Luckysheet;
