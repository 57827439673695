//thuc hien validate thong tin dang ky khong thoi han, tra ve trang thai
//tra ve cac truong thong tin loi: {TenTau, TenCT}
function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
export function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
export function validate_HasNumber(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isZero(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
export function validate_KhongThoiHan(data) {
  var ret = validate_Empty(data, [
    "TenTau",
    "MaCT",
    "TenCT",
    "TenCT2",
    "DiaChiCT",
    "DiaChiCT2",
    "TLSH",
    "LoaiTau",
    "TenLT1",
    "CapTau",
    "NoiSX",
    "NamSX",
    "LOA_T",
    "Width_T",
    "Draft_T",
    "DKSHNgay",
    "DKCoNgay",
    "TenTCDK",
    "NgayGCN",
    "MaCangDK",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    
  }
  if (Object.keys(ret).length == 0) {
    ret = validate_HasNumber(data, ["CapTau", "LoaiTau", "LOA", "Width", "Draft", "NamSX"]);
  }
  
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
export function validate_DangDong(data) {
  var ret = validate_Empty(data, [
    "TenTau",
    "MaCT",
    "TenCT",
    "TenCT2",
    "DiaChiCT",
    "DiaChiCT2",
    "TLSH",
    "LoaiTau",
    "TenLT1",
    "CapTau",
    "NoiSX",
    "NamSX",
    "LOA_T",
    "Width_T",
    "Draft_T",
    "DKSHNgay",
    "TenTCDK",
    "NgayGCN",
    "MaCangDK",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    
  }
  if (Object.keys(ret).length == 0) {
    ret = validate_HasNumber(data, ["CapTau", "LoaiTau", "LOA", "Width", "Draft", "NamSX"]);
  }
  
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
export function validate_TamThoi(data) {
  var ret = validate_Empty(data, [
    "TenTau",
    "MaCT",
    "TenCT",
    "TenCT2",
    "DiaChiCT",
    "DiaChiCT2",
    "TLSH",
    "LoaiTau",
    "TenLT1",
    "CapTau",
    "NoiSX",
    "NamSX",
    "LOA_T",
    "Width_T",
    "Draft_T",
    "HieuLuc1",
    "HieuLuc2",
    "TenTCDK",
    "NgayGCN",
    "MaCangDK",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    
  }
  if (Object.keys(ret).length == 0) {
    ret = validate_HasNumber(data, ["CapTau", "LoaiTau", "LOA", "Width", "Draft", "NamSX"]);
  }
  if (Object.keys(ret).length == 0)  {
    if(data.DKSH) {
      ret = validate_Empty(data, ['DKSHNgay']);
      if (Object.keys(ret).length == 0) {
      }
    }
  }
  if (Object.keys(ret).length == 0)  {
    if(data.DKCo) {
      ret = validate_Empty(data, ['DKCoNgay']);
      if (Object.keys(ret).length == 0) {
      }      
    }
  }  
  if (Object.keys(ret).length == 0)  {
    var t1 = (new Date(data.HieuLuc1));
    var t2 = (new Date(data.HieuLuc2));
      if(t1.getTime() >= t2.getTime()) {
        ret = {HieuLuc2: true}
      }
      else {
        var dif = t2.getTime() - t1.getTime();
        if(dif > 180 * 24 * 3600 * 1000 || dif <= 0) {
          ret = {HieuLuc2: true} //qua 180 ngay
        }
      }
  }  
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
export function validate_CoThoiHan(data) {
  var ret = validate_Empty(data, [
    "TenTau",
    "MaCT",
    "TenCT",
    "TenCT2",
    "DiaChiCT",
    "DiaChiCT2",
    "TLSH",
    "LoaiTau",
    "TenLT1",
    "CapTau",
    "NoiSX",
    "NamSX",
    "LOA_T",
    "Width_T",
    "Draft_T",
    "HieuLuc1",
    "HieuLuc2",
    "TenTCDK",
    "NgayGCN",
    "MaCangDK",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    
  }
  if (Object.keys(ret).length == 0) {
    ret = validate_HasNumber(data, ["CapTau", "LoaiTau", "LOA", "Width", "Draft", "NamSX"]);
  }
  if (Object.keys(ret).length == 0)  {
    if(data.DKSH) {
      ret = validate_Empty(data, ['DKSHNgay']);
      if (Object.keys(ret).length == 0) {
      }
    }
  }
  if (Object.keys(ret).length == 0)  {
    if(data.DKCo) {
      ret = validate_Empty(data, ['DKCoNgay']);
      if (Object.keys(ret).length == 0) {
      }      
    }
  }  
  if (Object.keys(ret).length == 0)  {
    var t1 = (new Date(data.HieuLuc1));
    var t2 = (new Date(data.HieuLuc2));
      if(t1.getTime() >= t2.getTime()) {
        ret = {HieuLuc2: true}
      }
      else {
        var dif = t2.getTime() - t1.getTime();
        if(dif <= 0) {
          ret = {HieuLuc2: true} //am
        }
      }
  }  
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
export function validate_XoaDangKy(data) {
  
  var ret = validate_Empty(data, [
    "LyDo",
    "NguoiDN",
    "SoGCN",
    "NgayGCN",
    "HieuLuc1",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
export function validate_ChuTau(data) {
  var ret = validate_Empty(data, [
    "TenCT",
    "TenCT2",
    "DiaChi",
    "DiaChi2",
    "MST",
    "NgayCap",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    
  }
  
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}