/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker, TreeView, TreeItem } from "@mui/lab";
import SelectFilter from "react-select";
import { filterStyles } from "../components/common";
import moment from "moment";
import xml2js from "xml2js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ClosedCaptionDisabledOutlined } from "@mui/icons-material";
import { makeStyles, withStyles } from "@mui/styles";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
const validator = require("../data/DangKy_Validator");

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
const styles = (theme) => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blueText: {
    color: "blue",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
});
function validate_upload(data) {
  var ret = null;
  ret = validate_Empty(data, ["fileXml"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
function validate_hoadon(data) {
  var ret = null;
  if (data.ID === 0) {
    ret = validate_Empty(data, [
      "MAUSO",
      "KYHIEU",
      "SOHD",
      "NGAYHD",
      "NGAYHD_FORMAT",
      "MST",
      "TENKH",
    ]);
  } else {
    ret = validate_Empty(data, [
      "MAUSO",
      "KYHIEU",
      "SOHD",
      "NGAYHD",
      "NGAYHD_FORMAT",
      "MST",
      "TENKH",
    ]);
  }
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
const renderOneTree = (nodeName, nodes, nodeId) => {
  return nodes ? (
    <TreeItem key={nodeId} nodeId={nodeId} label={nodeName}></TreeItem>
  ) : null;
};
var count = 0;
const renderTree1 = (nodeName, nodes, nodeId) => {
  var subList = [];
  //tim danh sach con
  nodes.SoftRights.map((item, index) => {
    if ((nodeId === null && item.GRCD === null) || item.GRCD === nodeId) {
      subList.push(item);
    }
  });
  nodeName = nodeName === null ? nodes.SoftName : nodeName;
  nodeId = nodeId === null ? nodes.SoftCode : nodeId;
  return (
    <TreeItem
      key={nodeId}
      nodeId={nodeId}
      label={<FormControlLabel control={<Checkbox />} label={nodeName} />}
    >
      {subList.length > 0
        ? subList.map((item, index) => renderTree1(item.RTNM, nodes, item.RTCD))
        : null}
    </TreeItem>
  );
};
class PhanQuyenEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onNodeSelect = this.onNodeSelect.bind(this);
    this.renderTree = this.renderTree.bind(this);

    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {},
      danhmuc_coquan: props.danhmuc_coquan,
      xmlDocument: undefined, //noi dung file xml
      expandedIds: [], //danh sach id expand
      expandedGroupIds: [], //danh sach id
      after: {
        SCD: window.SoftCode,
        ...(props.edittingRecord ? props.edittingRecord : {}),
        checkedRights: [], //danh sach quyen duoc lua chon
        checkedGroups: [], //danh sach group duoc lua chon
      },
      canSaved: false,
      loading_coquan: true,
      DanhSachPhanMem: [],
      PhanQuyen: {
        SoftCode: window.SoftCode,
        SoftName: "",
        SoftRights: [], //danh sach quyen cua phan mem
        SoftGroups: [], //danh sach nhom quyen cu aphan mem
        HasGroups: [], //danh sach nhom quyen cua user co'
        HasRights: [], //danh sach quyen cua user
        IsAdmin: false, //co phai tai khoan admin khong
      }, //thong tin phan quyen
    };
  }

  componentDidMount() {
    count = 0;
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value, checked) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    console.log(this.state.PhanQuyen);
    if (fieldName === "rights") {
      if (checked) {
        var has = this.state.PhanQuyen.IsAdmin;
        this.state.PhanQuyen.HasRights.map((item, index) => {
          has = has || item === value;
        });
        if (has) {
          s.after.checkedRights.push(value);
          s.after.checkedGroups = []; // clear groups
        }
      } else {
        //remove
        var findIndex = -1;
        s.after.checkedRights.map((item, index) => {
          if (item === value) {
            findIndex = index;
          }
        });
        if (findIndex >= 0) {
          s.after.checkedRights.splice(findIndex, 1);
        }
      }
    } else {
      if (fieldName === "groups") {
        if (checked) {
          var has = this.state.PhanQuyen.IsAdmin;
          this.state.PhanQuyen.HasGroups.map((item, index) => {
            has = has || item === value;
          });
          if (has) {
            s.after.checkedGroups.push(value);
            s.after.checkedRights = []; // clear rights
          }
        } else {
          //remove
          var findIndex = -1;
          s.after.checkedGroups.map((item, index) => {
            if (item === value) {
              findIndex = index;
            }
          });
          if (findIndex >= 0) {
            s.after.checkedGroups.splice(findIndex, 1);
          }
        }
      } else {
        s.after[fieldName] = value;
      }
    }
    s.dataChanged = true;
    if(fieldName !== 'SCD') {
      this.setState(s);
    }
    else {
      s.PhanQuyen = {
        SoftCode: '',
        SoftName: "",
        SoftRights: [], //danh sach quyen cua phan mem
        SoftGroups: [], //danh sach nhom quyen cu aphan mem
        HasGroups: [], //danh sach nhom quyen cua user co'
        HasRights: [], //danh sach quyen cua user
        IsAdmin: false, //co phai tai khoan admin khong
      };
      s.after = {
        ...s.after,
        checkedRights: [], //danh sach quyen duoc lua chon
        checkedGroups: [], //danh sach group duoc lua chon
      };
      this.setState(s, ()=>{
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  //xu ly du lieu xml thanh du lieu treeview
  populateTreeData(response) {
    var xml = null;
    var nodeIds = [];
    var stack = [{ nodeId: "Root", data: response, name: "Root" }];
    while (stack.length > 0) {
      var node = stack.pop();
      var item = node.data;
      nodeIds.push(node.nodeId); //them id
      if (item && typeof item === "object") {
        var keys = Object.keys(item);
        keys.map((c, idx) =>
          stack.push({ nodeId: node.nodeId + "/" + c, data: item[c], name: c })
        );
      } else {
        //console.log(node.name);
        nodeIds.push(node.nodeId + "/_value"); //dung de hien thi gia tri
      }
    }
    return { xmlDocument: response, xmlIds: nodeIds };
  }
  loadDanhMuc() {
    //load danh muc phan mem
    server
      .query("PhanQuyen/GetDanhSachPhanMem", {})
      .then((response) => {
        this.setState(
          {
            loading_data: false,
            search_error: false,
            DanhSachPhanMem: response,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          message = obj.message;
          log = obj.traceLog;
        } catch (e) {
          message = error + "";
        }
        this.setState({
          loading_data: false,
          search_error: true,
          errorMessage: message,
          errorLog: log,
          rowSelected: undefined,
        });
      });
  }
  doSearch() {
    //load danh muc doi tac
    server
      .query("PhanQuyen/GetPhanQuyen", {
        scd: this.state.after.SCD,
        uscd: this.props.edittingRecord.USCD,
      })
      .then((response) => {
        var list = [];
        var checkedRights = [];
        var checkedGroups = [];
        list.push(response.SoftCode);
        //default  theo tai khoan
        response.SoftRights.map((item, index) => {
          list.push(item.RTCD);
        });
        var userRts = response.UserRights; //danh sach quyen cua user
        if (userRts && userRts.length > 0) {
          var rtcds = userRts[0].RTCDS; //danh sach ma quyen
          var grcds = userRts[0].GRCDS; //danh sach nhom quyen
          if (rtcds !== null && rtcds != "") {
            checkedRights = rtcds.split(";");
          }
          if (grcds != null && grcds != "") {
            checkedGroups = grcds.split(";");
          }
        }
        var hasData = response.HasRights;
        var hasRights = hasData.length > 0 ? hasData[0].RTCDS : null;
        hasRights = hasRights ? hasRights.split(";") : [];
        var hasGroups = hasData.length > 0 ? hasData[0].GRCDS : null;
        hasGroups = hasGroups ? hasGroups.split(";") : [];
        if (hasGroups.length > 0) {
          //gan quyen theo nhom
          hasGroups.map((g, i) => {
            //tim cac quyen cua nhom
            response.SoftGroups.map((group, k) => {
              if (group.GRCD === g) {
                hasRights = hasRights.concat(group.RTCDS.split(";"));
              }
            });
          });
        }
        this.setState({
          canSaved: true,
          PhanQuyen: {
            ...response,
            HasRights: hasRights,
            HasGroups: hasGroups,
            IsAdmin: response.IsAdmin,
          },
          expandedIds: list, // expand tat ca
          loading_coquan: false,
          after: {
            ...this.state.after,
            MaDV: global.User.MaDV,
            checkedRights: checkedRights,
            checkedGroups: checkedGroups,
          },
        });
      })
      .catch((error) => {
        console.log(error.message);
        console.log(error);
        this.setState({ loading_coquan: false });
      });
  }
  renderTree = (nodeName, nodes, nodeId) => {
    var subList = [];
    //tim danh sach con
    nodes.SoftRights.map((item, index) => {
      if ((nodeId === null && (item.GRCD === null || item.GRCD === '')) || item.GRCD === nodeId) {
        subList.push(item);
      }
    });
    var isRoot = nodeName === null;
    nodeName = nodeName === null ? nodes.SoftName : nodeName;
    nodeId = nodeId === null ? nodes.SoftCode : nodeId;
    var isChecked = false;
    if (!isRoot) {
      this.state.after.checkedRights.map((item, index) => {
        isChecked = isChecked || item === nodeId;
      });
    }
    var has = this.state.PhanQuyen.IsAdmin;
    this.state.PhanQuyen.HasRights.map((item, index) => {
      has = has || item === nodeId;
    });
    const { classes, theme } = this.props;
    return (
      <TreeItem
        key={nodeId}
        nodeId={nodeId}
        label={
          isRoot ? (
            <FormLabel>{nodeName}</FormLabel>
          ) : (
            <FormControlLabel
              className={has ? classes.blueText : null}
              control={
                <Checkbox
                  readOnly={!has}
                  size="small"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                  checked={isChecked}
                  onChange={(event) => {
                    this.saveField("rights", nodeId, event.target.checked);
                  }}
                />
              }
              label={nodeId + " - " + nodeName}
            />
          )
        }
      >
        {subList.length > 0
          ? subList.map((item, index) =>
              this.renderTree(item.RTNM, nodes, item.RTCD)
            )
          : null}
      </TreeItem>
    );
  };

  //khi user select node
  onNodeSelect = (event, listIds) => {};
  doSave = () => {
    var rtcds = "";
    var grcds = "";
    this.state.after.checkedRights.map((item, index) => {
      if (item !== "") {
        rtcds += item + ";";
      }
    });
    this.state.after.checkedGroups.map((item, index) => {
      if (item !== "") {
        grcds += item + ";";
      }
    });
    var data = {
      SCD: this.state.after.SCD,
      USCD: this.props.edittingRecord.USCD,
      checkedRights: rtcds,
      checkedGroups: grcds,
    };
    console.log(data);
    this.setState({
      validateStatus: {},
      canSaved: false,
      saving: true,
      errorLog: "",
    });
    server
      .post("PhanQuyen/SavePhanQuyen", data)
      .then((response) => {
        var before = {};
        var after = {
          ...this.state.after,
          TAGID: response.ID,
        };
        Object.assign(before, after);
        this.setState({
          before: before,
          after: after,
          dataChanged: false,
          canSaved: true,
          saving: false,
          errorMessage: "",
          errorLog: "",
        });
        //thong bao cho parent biet co data duoc saved
        if (this.props.onSaved) {
          this.props.onSaved("update", after);
        }
        alert("Cập nhật thành công!");
      })
      .catch((error) => this.handleServerError(error));
  };
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);

      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  render() {
    let tau = this.state.tauData;
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Phân quyền</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={3}>
                <FormLabel>Tài khoản:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="sogcn"
                  fullWidth
                  value={this.state.after.USCD}
                  readOnly
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="sogcn"
                  fullWidth
                  value={this.state.after.USNM}
                  readOnly
                />
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <FormLabel>Phần mềm:</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  value={this.state.after.SCD}
                  onChange={(event) =>
                    this.saveField('SCD', event.target.value)
                  }
                >
                  {this.state.DanhSachPhanMem.map((item) => (
                    <MenuItem value={item.SCD}>
                      {item.SCD} - {item.SNM}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={24}>
                <div></div>
              </Grid>
              <Grid item xs={12}>
                <FormLabel className={classes.quantrong}>
                  Phân theo quyền cụ thể:
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FormLabel className={classes.quantrong}>
                  Phân theo nhóm quyền:
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={[]}
                  expanded={this.state.expandedIds}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: 510,
                    flexGrow: 1,
                    overflowY: "auto",
                    backgroundColor: "aliceblue",
                  }}
                  onNodeSelect={this.onNodeSelect}
                >
                  {this.renderTree(null, this.state.PhanQuyen, null)}
                </TreeView>
              </Grid>
              <Grid item xs={12}>
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={[]}
                  expanded={this.state.expandedGroupIds}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{
                    height: 510,
                    flexGrow: 1,
                    overflowY: "auto",
                    backgroundColor: "lightgrey",
                  }}
                  onNodeSelect={this.onNodeSelect}
                >
                  {this.state.PhanQuyen.SoftGroups.map((item, index) => {
                    var isChecked = false;
                    this.state.after.checkedGroups.map((rtcd, n) => {
                      isChecked = isChecked || rtcd === item.GRCD;
                    });
                    var has = this.state.PhanQuyen.IsAdmin;
                    this.state.PhanQuyen.HasGroups.map((g, j) => {
                      has = has || g === item.GRCD;
                    });
                    return (
                      <TreeItem
                        key={item.GRCD}
                        nodeId={item.GRCD}
                        label={
                          <FormControlLabel
                            className={has ? classes.blueText : null}
                            control={
                              <Checkbox
                                checked={isChecked}
                                size="small"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                                onChange={(event) => {
                                  this.saveField(
                                    "groups",
                                    item.GRCD,
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                            label={item.GRNM}
                          />
                        }
                      ></TreeItem>
                    );
                  })}
                </TreeView>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
            disabled={!this.state.canSaved}
          >
            Lưu
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(PhanQuyenEditDialog);
