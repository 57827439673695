/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Port log" : "Nhật ký trong cảng";
const fieldLabels = englishUI
  ? {
      TripCode: "Trip code:",
      NorTender: "Nor tendered:",
      HoldInspec: "Hold Inspection:",
      DraftSurvey: "Initial Draft Survey:",
      LoadingTime: "Loading/Discharging time",
      FinalSurvey: "Final Draft Survey:",
    }
  : {
      TripCode: "Trip code:",
      NorTender: "Nor tendered:",
      HoldInspec: "Hold Inspection:",
      DraftSurvey: "Initial Draft Survey:",
      LoadingTime: "Loading/Discharging time",
      FinalSurvey: "Final Draft Survey:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["TripCode"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version

  return ret;
}
////////////////////////--------------/////////////////////////////////////////
export default class PortLogEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: props.DanhMuc,
      after: {
        PortLogID: 0,
        ShipID: props.ShipData.ShipID, //lay thong tin tu ship data
        TripCode: props.defaultTripCode,
        NorTender: null,
        HoldInspec1: null,
        HoldInspec2: null,
        DraftSurvey1: null,
        DraftSurvey2: null,
        LoadingTime: null,
        DischargeTime: null,
        FinalSurvey1: null,
        FinalSurvey2: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //load du lieu khi form load
  loadDanhMuc() {
    //lay ma chuyen tu dong tiep theo
    if (this.props.edittingRecord == undefined) {
      this.setState({ saving: true });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DR_PortLog_Get",
          ThamSo: {
            ShipID: this.state.after.ShipID,
            TripCode: this.state.after.TripCode,
          },
        })
        .then((response) => {
          //du lieu cua 1 do thi
          let list = response.DR_PortLog;
          this.setState({
            saving: false,
            after: list.length > 0 ? list[0] : this.state.after,
          });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    }
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var data = this.state.after;
      var check = validate_Data(this.state.after);
      //chuyen doi format
      
      var action = this.props.edittingRecord ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_PortLog_Update",
            ThamSo: data,
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, data);
            }
            alert("Cập nhật thành công!");
            this.props.close(data);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={8}>
                <FormLabel>{fieldLabels.TripCode}</FormLabel>
              </Grid>
              <Grid item xs={16}>
                <OutlinedInput
                  id="TripCode"
                  fullWidth
                  readOnly={true}
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 20,
                  }}
                  value={this.state.after.TripCode}
                  onChange={(event) => {
                    this.saveField(
                      "TripCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.TripCode !== undefined}
                />
              </Grid>
              
              
              <Grid item xs={8}>
                <FormLabel>{fieldLabels.HoldInspec}</FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="HoldInspec1"
                  key="HoldInspec1"
                  fullWidth
                  value={this.state.after.HoldInspec1}
                  onChange={(value) => {
                    this.saveField("HoldInspec1", value);
                  }}
                  error={this.state.validateStatus.HoldInspec1 !== undefined}
                />
              </Grid>
              
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="HoldInspec2"
                  key="HoldInspec2"
                  fullWidth
                  value={this.state.after.HoldInspec2}
                  onChange={(value) => {
                    this.saveField("HoldInspec2", value);
                  }}
                  error={this.state.validateStatus.HoldInspec2 !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <FormLabel>{fieldLabels.DraftSurvey}</FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="DraftSurvey1"
                  key="DraftSurvey1"
                  fullWidth
                  value={this.state.after.DraftSurvey1}
                  onChange={(value) => {
                    this.saveField("DraftSurvey1", value);
                  }}
                  error={this.state.validateStatus.DraftSurvey1 !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="DraftSurvey2"
                  key="DraftSurvey2"
                  fullWidth
                  value={this.state.after.DraftSurvey2}
                  onChange={(value) => {
                    this.saveField("DraftSurvey2", value);
                  }}
                  error={this.state.validateStatus.DraftSurvey2 !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <FormLabel>{fieldLabels.LoadingTime}</FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="LoadingTime"
                  key="LoadingTime"
                  fullWidth
                  value={this.state.after.LoadingTime}
                  onChange={(value) => {
                    this.saveField("LoadingTime", value);
                  }}
                  error={this.state.validateStatus.LoadingTime !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="DischargeTime"
                  key="DischargeTime"
                  fullWidth
                  value={this.state.after.DischargeTime}
                  onChange={(value) => {
                    this.saveField("DischargeTime", value);
                  }}
                  error={this.state.validateStatus.DischargeTime !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <FormLabel>{fieldLabels.FinalSurvey}</FormLabel>
              </Grid>
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="FinalSurvey1"
                  key="FinalSurvey1"
                  fullWidth
                  value={this.state.after.FinalSurvey1}
                  onChange={(value) => {
                    this.saveField("FinalSurvey1", value);
                  }}
                  error={this.state.validateStatus.FinalSurvey1 !== undefined}
                />
              </Grid>
              <Grid item xs={8}>
                <MyDateTimeEditor
                  id="FinalSurvey2"
                  key="FinalSurvey2"
                  fullWidth
                  value={this.state.after.FinalSurvey2}
                  onChange={(value) => {
                    this.saveField("FinalSurvey2", value);
                  }}
                  error={this.state.validateStatus.FinalSurvey2 !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>

          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
