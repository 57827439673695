/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "SHIP INFO" : "THÔNG TIN TÀU";
const fieldLabels = englishUI
  ? {
      ShipID: "ShipID",
      ShipCode: "Code:",
      ShipName: "Ship' name:",
      CallSign: "Callsign:",
      IMO: "IMO:",
      MMSI: "MMSI:",
      DWT: "DWT:",
      GT: "GRT:",
      SlipH: "Slip-H:",
      NguoiCN: "NguoiCN",
      NgayCN: "NgayCN",
      PowerExpression: "PowerExpression",
      OrderUI: "Order:",
      Emails: "Report to emails:",
    }
  : {
      ShipID: "ShipID",
      ShipCode: "Ký hiệu:",
      ShipName: "Tên tàu:",
      CallSign: "Hô hiệu:",
      IMO: "IMO:",
      MMSI: "MMSI:",
      DWT: "DWT:",
      GT: "GRT:",
      NguoiCN: "NguoiCN",
      NgayCN: "NgayCN",
      PowerExpression: "PowerExpression",
      OrderUI: "Order:",
      Emails: "Report to emails:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["ShipCode", "ShipName"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
const defaultEmails = () => [
  {
    ReportTypeID: 1,
    Emails: "",
  },
  {
    ReportTypeID: 2,
    Emails: "",
  },
  {
    ReportTypeID: 3,
    Emails: "",
  },
  {
    ReportTypeID: 4,
    Emails: "",
  },
  {
    ReportTypeID: 5,
    Emails: "",
  },
  {
    ReportTypeID: 6,
    Emails: "",
  },
  {
    ReportTypeID: 9,
    Emails: "",
  },
  {
    ReportTypeID: 10,
    Emails: "",
  },
  {
    ReportTypeID: 11,
    Emails: "",
  },

  {
    ReportTypeID: 8,
    Emails: "",
  },
];
////////////////////////--------------/////////////////////////////////////////
export default class ShipEditDialog extends React.Component {
  constructor(props) {
    super(props);
    var emails =
      props.edittingRecord !== undefined
        ? JSON.parse(props.edittingRecord.Emails)
        : [];
    if (emails.length == 0) {
      emails = defaultEmails();
    }
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: { DM_ReportType: [] },
      after: {
        ShipID: 0,
        ShipCode: "",
        ShipName: "",
        CallSign: "",
        IMO: "",
        MMSI: "",
        DWT: null,
        GT: null,
        OrderUI: 0,

        ...(props.edittingRecord ? props.edittingRecord : {}),
        ...{
          ...(props.edittingRecord && props.edittingRecord.FMEnable
            ? JSON.parse(props.edittingRecord.FMEnable)
            : {}),
        },
        Emails: emails,
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  loadDanhMuc() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: "DM_ReportType",
        },
      })
      .then((response) => {
        //gien thi them neu ko co
        var emails = this.state.after.Emails;
        response.DM_ReportType.map((item) => {
          var old = emails.find((e) => e.ReportTypeID === item.ReportTypeID);
          if (old === undefined || old === null) {
            emails.push({
              ReportTypeID: item.ReportTypeID,
              Emails: "",
            });
          }
        });
        this.setState({
          DanhMuc: {
            ...response,
          },
        });
      })
      .catch((error) => {
        showError(error);
      });
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.ShipID > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_Ship_Update",
            ThamSo: this.state.after,
          })
          .then((response) => {
            var before = {};
            var after = {
              ...this.state.after,
              ShipID: response.ShipID,
            };
            Object.assign(before, after);
            this.setState({
              before: before,
              after: after,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, after);
            }
            alert("Cập nhật thành công!");
            this.props.close(after);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.ShipCode}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtShipCode"
                  fullWidth
                  autoFocus
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 20,
                  }}
                  value={this.state.after.ShipCode}
                  onChange={(event) => {
                    this.saveField(
                      "ShipCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.ShipCode !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.ShipName}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtShipName"
                  fullWidth
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 40,
                  }}
                  value={this.state.after.ShipName}
                  onChange={(event) => {
                    this.saveField(
                      "ShipName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.ShipName !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.CallSign}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtCallSign"
                  fullWidth
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 40,
                  }}
                  value={this.state.after.CallSign}
                  onChange={(event) => {
                    this.saveField("CallSign", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.IMO}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtIMO"
                  fullWidth
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 9,
                  }}
                  value={this.state.after.IMO}
                  onChange={(event) => {
                    this.saveField("IMO", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.MMSI}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="txtMMSI"
                  fullWidth
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 9,
                  }}
                  value={this.state.after.MMSI}
                  onChange={(event) => {
                    this.saveField("MMSI", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.DWT}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <MyNumberEditor
                  id="txtDWT"
                  fullWidth
                  value={this.state.after.DWT}
                  onValueChange={(value) => {
                    this.saveField("DWT", value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.GT}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="txtGT"
                  fullWidth
                  value={this.state.after.GT}
                  onValueChange={(value) => {
                    this.saveField("GT", value);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlipH}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="SlipH"
                  fullWidth
                  decimalScale={4}
                  value={this.state.after.SlipH}
                  onValueChange={(value) => {
                    this.saveField("SlipH", value);
                  }}
                />
              </Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.OrderUI}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <MyNumberEditor
                  id="OrderUI"
                  fullWidth
                  decimalScales={0}
                  value={this.state.after.OrderUI}
                  onValueChange={(value) => {
                    this.saveField("OrderUI", value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.Emails}</FormLabel>
              </Grid>
              <Grid item xs={20}></Grid>

              {this.state.after.Emails.map((item) => (
                <React.Fragment key={"E" + item.ReportTypeID}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}>
                    <FormLabel>
                      {this.state.DanhMuc.DM_ReportType.find(
                        (t) => t.ReportTypeID == item.ReportTypeID
                      )
                        ? this.state.DanhMuc.DM_ReportType.find(
                            (t) => t.ReportTypeID == item.ReportTypeID
                          ).ReportTypeName
                        : ""}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={20}>
                    <OutlinedInput
                      id="Emails"
                      key={"E" + item.ReportTypeID}
                      fullWidth
                      placeholder="abc@gmail.com;xyz@gmail.com"
                      value={item.Emails}
                      onChange={(event) => {
                        item.Emails = event.target.value;
                        this.setState({ saving: false });
                      }}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
