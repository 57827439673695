import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";

import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import Luckysheet from "../components/Luckysheet";

import DepartureReportEditDialog from "./DepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import { mockComponent } from "react-dom/test-utils";
import { da } from "date-fns/locale";
import { parse } from "date-fns";
import { ReceiptTwoTone } from "@mui/icons-material";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      ShipID: "Ship' name:",
    }
  : {
      ShipID: "Tên tàu:",
    };

const buttonLabels = englishUI
  ? {
      btnTimKiem: "Search",
      btnLoadConfig: "Load config",
      btnConfig: "Save config",
      btnSave: "Save data",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnLoadConfig: "Cấu hình",
      btnConfig: "Ghi cấu hình",
      btnSave: "Lưu dữ liệu",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/

/*-----------------------Cac bien su dung trong form ----------------------*/

//tra ve mo ta thoi gian

//gan du lieu vao config cua sheet de hien thi lai
function mapSheet_DinhMuc(response) {
  const datas = response.DM_DinhMuc;
  const lucky = window.luckysheet;
  const config = lucky.toJson();
  const sheet = config.data[0]; //sheet dau tien
  const cellData = sheet.celldata;
  //tim vi tri bat dau hien thi
  for (var r = 5; r < 13; r++) {
    var stt = Number(lucky.getCellValue(r, 0));
    var rec = datas.find((item) => item.DinhMucID == stt);
    //hien thi ban ghi
    for (var c = 1; c < sheet.column; c++) {
      var ten = sheet_fields[c]; //ten truong thong tin
      var value = rec ? rec[ten] : null;
      lucky.setCellValue(r, c, value);
    }
  }
}
//Thutu truong thong tin theo format hien thi du lieu trong sheet
const sheet_fields = [
  "DinhMucID",
  "RpmME",
  "PowerME",
  "ConsFOME",
  "ConsDOME",
  "ConsCylME",
  "ConsSysME",
  "PowerAENS",
  "ConsFOAENS",
  "ConsDOAENS",
  "PowerAENP0",
  "ConsFOAENP0",
  "ConsDOAENP0",
  "PowerAENP",
  "ConsFOAENP",
  "ConsDOAENP",
  "ConsLOAE",
  "ConsFOIG",
  "ConsFONS",
  "ConsDONS",
  "ConsFONP0",
  "ConsDONP0",
  "ConsFONP",
  "ConsDONP",
  "SpeedBallast",
  "SpeedCargo",
];
////////////////////////////////////////////////////////////////////
class DinhMucPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  luckyComponent = undefined; //tham chieu den compoennt
  constructor(props) {
    super(props);
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: { DM_Ship: [], DM_ReportType: [] }, //luu cac du lieu danh muc su dung trong form
      //searching
      sheetLoading: true,
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: 0,
              //danh sach cac tieu chi tim kiem
              ShipID: null,
              ShipName: "",
              Mode: "ShipQuota",
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      CurrentWorksheet: {
        WorksheetID: 0,
        WorksheetCode: "ShipQuota",
      },
      WorkbookConfig: {
        version: 0, //doi ve -1 de reset neu can
        JsonConfig: {},
      },
      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      dataFilled: false, //cho biet du lieu duoc fill hay chua
    };
  }
  //xu ly event cua sheeeet
  luckysheetCallback = (lucky, event) => {
    console.log("lucky event:" + event);
    this.luckyComponent = lucky;
    if (
      event == "created" &&
      this.state.WorkbookConfig &&
      Object.keys(this.state.WorkbookConfig.JsonConfig).length > 0
    ) {
      //neu da duoc tao xong thi se goi reload
      this.luckyComponent.reload(this.state.WorkbookConfig.JsonConfig);
      //ket thuc load sheet
      this.setState({ sheetLoading: false });
    }
  };

  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s);
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc("DM_Ship");
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(props, state) {}
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          var default_ship = {};
          if (response.DM_Ship.length > 0) {
            default_ship = myLib.copy(response.DM_Ship[0], [
              "ShipID",
              "ShipName",
            ]);
          }
          this.setState(
            {
              DanhMuc: response,
              after: {
                ...this.state.after,
                ...default_ship,
              },
              saving: false,
            },
            () => this.doSearch()
          );
        })
        .catch((error) => {
          this.setState({ saving: false });
          showError(error);
        });
    }
    //thuc hien load config excel
    this.requestLoadConfig();
  }
  //khi thay doi sap xep cot

  //Thuc hien search
  doSearch = () => {
    this.setState({
      saving: true,
    });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_DinhMuc_GetOne",
        ThamSo: {
          ShipID: this.state.after.ShipID,
        },
      })
      .then((response) => {
        this.setState({
          saving: false,
          dataFilled: true,
        });
        //dua du lieu vao hien thi trong sheet

        mapSheet_DinhMuc(response);
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  };
  requestLoadConfig() {
    //thuc hien load cau hinh grid tu server
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Worksheet_Get",
        ThamSo: {
          WorksheetID: 0,
          WorksheetCode: this.state.after.Mode,
        },
      })
      .then((response) => {
        if (response.DM_Worksheet.length == 0) {
          this.setState({
            saving: false,
            dataFilled: false,
          });
        } else {
          const lastVersion = this.state.WorkbookConfig.version;
          //thu thuat: dau tien set ve -1 de reset, sau do render lai
          this.setState(
            {
              saving: false,
              dataFilled: false,
              CurrentWorksheet: {
                WorksheetID: response.DM_Worksheet[0].WorksheetID,
                WorksheetCode: response.DM_Worksheet[0].WorksheetCode,
              },
              WorkbookConfig: {
                version: lastVersion + 10,
                JsonConfig: JSON.parse(response.DM_Worksheet[0].JsonConfig),
              },
            },
            () => {
              console.log(this.state.sheetLoading);
              if (!this.state.sheetLoading) {
                this.luckyComponent.reload(
                  this.state.WorkbookConfig.JsonConfig
                );
              }
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ saving: false, sheetLoading: false });
        showError(error);
      });
  }
  requestSaveConfig() {
    //thuc hien save cau hinh ve server
    var json = window.luckysheet.toJson();
    json = JSON.stringify(json);
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Worksheet_Update",
        ThamSo: {
          WorksheetID: 0,
          WorksheetCode: this.state.after.Mode,
          JsonConfig: json,
        },
      })
      .then((response) => {
        this.setState({
          saving: false,
          CurrentWorksheet: response.DM_Worksheet[0], //lay ma tu server
        });
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }
  //tra ve danh sach du lieu dinh muc cua tau
  getDuLieuDinhMuc() {
    var data = [];
    const lucky = window.luckysheet;
    const config = lucky.toJson();
    const sheet = config.data[0]; //sheet dau tien
    //thuc hien doc. du lieu
    for (var r = 5; r < 13; r++) {
      var stt = lucky.getCellValue(r, 0);
      var rec = null; //ban ghi
      for (var c = 1; c < sheet.column; c++) {
        var value = lucky.getCellValue(r, c);
        //console.log(r, c, value);
        if (rec == null) {
          //neu co gia tri
          if (value !== null) {
            rec = {
              ShipID: this.state.after.ShipID,
              DinhMucID: parseInt(stt),
            };
            data.push(rec);
          } else {
            break; //ko co du lieu thi bo qua
          }
        }
        //doc gia tri
        if (rec !== null) {
          var ten = sheet_fields[c]; //ten truong thong tin
          var so = value == null ? null : Number(value);
          //console.log(so);
          rec[ten] = so == null || isNaN(so) ? null : so;
        }
      }
    }

    return data;
  }
  //ghi nho du lieu dinh muc
  doSave() {
    try {
      var data = this.getDuLieuDinhMuc();
      console.log(data);
      this.setState({ saving: true });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_DinhMuc_Update",
          ThamSo: data,
        })
        .then((response) => {
          this.setState({
            saving: false,
          });
        })
        .catch((error) => {
          this.setState({ saving: false });
          showError(error);
        });
    } catch (ex) {
      showError(ex);
      this.setState({ saving: false });
    }
  }
  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    //console.log('render................');
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.ShipID}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                id={"ShipID"}
                key={"ShipID"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Ship}
                optionLabel="ShipName"
                optionValue="ShipID"
                placeholder=""
                value={this.state.after}
                onChange={(item) => {
                  this.saveData(item);
                }}
              ></MySelectFilter>
            </Grid>

            <Grid item xs={3}>
              <ButtonTimKiem
                title={buttonLabels.btnTimKiem}
                variant="contained"
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                  if (item.menuName == "Reload") {
                    //load f5orm
                    this.loadForm(true);
                  }
                  if (item.menuName == "GetCode") {
                    //lay codejs
                    this.generateCodeReactJs();
                  }
                }}
              ></ButtonTimKiem>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  this.requestLoadConfig(false);
                }}
              >
                {buttonLabels.btnLoadConfig}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                disabled={this.state.dataFilled}
                onClick={() => {
                  this.requestSaveConfig(false);
                }}
              >
                {buttonLabels.btnConfig}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                disabled={!this.state.dataFilled}
                onClick={() => {
                  this.doSave();
                }}
              >
                {buttonLabels.btnSave}
              </Button>
            </Grid>
            <Grid item xs={1}>
              {this.state.saving || this.sheetLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <Luckysheet
              height={parentHeight - 55}
              initConfig={this.state.WorkbookConfig}
              callback={this.luckysheetCallback}
            />
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}

        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.action_show_form ? (
          <DataEditDialog
            open={true}
            FormName={this.state.action_FormName}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({
                action_show_form: false,
                action_FormName: undefined,
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(DinhMucPage);
