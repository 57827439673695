/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
  LinearProgress,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import uuid from "react-uuid";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../components/common";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import AsyncSelect from "react-select/async";
import { af } from "date-fns/locale";
import {
  Add,
  Clear,
  Delete,
  Edit,
  FilterTiltShiftSharp,
  SyncAlt,
} from "@mui/icons-material";
import BackupIcon from "@mui/icons-material/Backup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SimpleDialog from "../components/SimpleDialog";
import SimpleInputDialog from "../components/SimpleInputDialog";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import FilterFieldEditDialog from "./FilterFieldEditDialog";
const dataStruct = require("../data/DataStruct");
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const defaultValueFunctions = {
  ToDay: () => new Date(),
};
function validate_Data(data) {
  var ret = myLib.validateEmpty(data, [
    "ReportFormCode",
    "ReportFormTitle",
    "OrderNum",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

//////////////////////////////////////////////---------------///////////////////////////////////////////////////////
export default class ReportFormEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      validateStatus: { error: false }, //trang thai validate

      after: {
        ReportFormID: 0,
        RightID: "",
        ReportFormConfig: {
          Fields: [],
        },
        ...props.edittingRecord,
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }

  loadDanhMuc() {
    //load form config
    this.setState({ saving: true });
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_ReportForm_GetOne",
        ThamSo: {
          ReportFormID: this.state.after.ReportFormID,
        },
      })
      .then((response) => {
        if (response.DM_ReportForm.length > 0) {
          this.setState({
            saving: false,
            after: {
              ...this.state.after,
              ...response.DM_ReportForm[0],
              ReportFormConfig: JSON.parse(
                response.DM_ReportForm[0].ReportFormConfig
              ),
            },
          });
        } else {
          this.setState({ saving: false });
        }
      })
      .catch((error) => {
        this.setState({ saving: false });
        handleServerError(error);
      });
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_ReportForm_Update",
            ThamSo: this.state.after,
          })
          .then((response) => {
            this.props.close(true);
          })
          .catch((error) => {
            this.setState({ saving: false });
            handleServerError(error);
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.state.FormConfig ? this.state.FormConfig.ReportTitle : "Report"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Thứ tự:</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="STT"
                  fullWidth
                  autoFocus
                  decimalScale={0}
                  value={this.state.after.OrderNum}
                  onValueChange={(value) => {
                    this.saveField("OrderNum", value);
                  }}
                  error={this.state.validateStatus.OrderNum !== undefined}
                />
              </Grid>
              <Grid item xs={18}></Grid>
              <Grid item xs={4}>
                <FormLabel>Mã báo cáo:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="ReportFormCode"
                  fullWidth
                  value={this.state.after.ReportFormCode}
                  onChange={(event) => {
                    this.saveField(
                      "ReportFormCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.ReportFormCode !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Tên báo cáo:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="ReportFormTitle"
                  fullWidth
                  value={this.state.after.ReportFormTitle}
                  onChange={(event) => {
                    this.saveField("ReportFormTitle", event.target.value);
                  }}
                  error={
                    this.state.validateStatus.ReportFormTitle !== undefined
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Mã nhóm:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="ReportGroupCode"
                  fullWidth
                  value={this.state.after.ReportGroupCode}
                  onChange={(event) => {
                    this.saveField(
                      "ReportGroupCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={
                    this.state.validateStatus.ReportGroupCode !== undefined
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Mã quyền:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="RightID"
                  fullWidth
                  value={this.state.after.RightID}
                  onChange={(event) => {
                    this.saveField(
                      "RightID",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={
                    this.state.validateStatus.RightID !== undefined
                  }
                />
              </Grid>
              <Grid item xs={24}>
                <Typography variant="h5">Danh sách tiêu chí:</Typography>
              </Grid>
              {this.state.after.ReportFormConfig !== undefined &&
              this.state.after.ReportFormConfig.Fields !== undefined
                ? this.state.after.ReportFormConfig.Fields.map((field) => (
                    <Grid item xs={24}>
                      <ListItemButton
                        onClick={() => {
                          this.setState({
                            show_edit_field: true,
                            edittingField: field,
                          });
                        }}
                      >
                        <ListItemText>{field.FieldName}</ListItemText>
                      </ListItemButton>
                    </Grid>
                  ))
                : null}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.setState({
                show_edit_field: true,
                edittingField: undefined,
              });
            }}
            variant="contained"
          >
            Thêm trường
          </Button>
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Lưu
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.show_edit_field ? (
          <FilterFieldEditDialog
            open={true}
            edittingRecord={this.state.edittingField}
            close={(data) => {
              if (data) {
                if (this.state.edittingField == undefined) {
                  this.state.after.ReportFormConfig.Fields.push(data);
                } else {
                  myLib.assign(data, this.state.edittingField, [
                    "FieldName",
                    "Label",
                    "DataType",
                    "SkipValue",
                    "Compare",
                    "ListName",
                    "SourceLabel",
                    "SourceValue",
                    "Visible",
                    "DefaultValue",
                  ]);
                }
                this.setState({ show_edit_field: false });
              } else {
                this.setState({ show_edit_field: false });
              }
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
