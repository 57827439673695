/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../components/common";

const dataStruct = require("../data/DataStruct");
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["FieldName", "DataType", "Compare"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

export default class ReportFormFieldEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {},
      after: {
        FieldName: "",
        DataType: "String", //kieu du lieu
        SkipValue: "", //gia tri loai tru
        Compare: "=", //phep so sanh
        Label: "",
        SourceName: "",
        SourceLabel: "",
        SourceValue: "",
        LabelWidth: 4,
        FieldInputWidth: 8,
        Visible: true,
        DefaultValue: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    //this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.props.close(this.state.after);
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tiêu chí tìm kiếm</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Tên trường(*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  autoFocus
                  readOnly={this.props.edittingRecord !== undefined}
                  value={this.state.after.FieldName}
                  onChange={(event) => {
                    this.saveField("FieldName", event.target.value);
                  }}
                  error={this.state.validateStatus.FieldName !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Kiểu dữ liệu (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <Select
                  fullWidth
                  value={this.state.after.DataType}
                  onChange={(event) =>
                    this.saveData({
                      DataType: event.target.value,
                      Compare:
                        event.target.value.indexOf("Array") >= 0
                          ? "in"
                          : this.state.after.Compare == "in"
                          ? "="
                          : this.state.after.Compare,
                    })
                  }
                >
                  <MenuItem value={"String"}>String</MenuItem>
                  <MenuItem value={"Int"}>Int</MenuItem>
                  <MenuItem value={"Decimal"}>Decimal</MenuItem>
                  <MenuItem value={"Date"}>Date</MenuItem>
                  <MenuItem value={"DateTime"}>DateTime</MenuItem>
                  <MenuItem value={"ArrayString"}>ArrayString</MenuItem>
                  <MenuItem value={"ArrayInt"}>ArrayInt</MenuItem>
                  <MenuItem value={"ArrayDecimal"}>ArrayDecimal</MenuItem>
                  <MenuItem value={"ArrayDate"}>ArrayDate</MenuItem>
                  <MenuItem value={"ArrayDateTime"}>ArrayDateTime</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Giá trị bỏ qua(*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SkipValue}
                  onChange={(event) => {
                    this.saveField("SkipValue", event.target.value);
                  }}
                  error={this.state.validateStatus.SkipValue !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Phép so sánh (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <Select
                  fullWidth
                  value={this.state.after.Compare}
                  onChange={(event) =>
                    this.saveData({
                      Compare: event.target.value,
                      DataType:
                        event.target.value == "in"
                          ? this.state.after.DataType.indexOf("Array") < 0
                            ? "ArrayString"
                            : this.state.after.DataType
                          : this.state.after.DataType.indexOf("Array") >= 0
                          ? "String"
                          : this.state.after.DataType,
                    })
                  }
                >
                  <MenuItem value={"<"}>{"Nhỏ hơn"}</MenuItem>
                  <MenuItem value={">"}>Lớn hơn</MenuItem>
                  <MenuItem value={"="}>Bằng</MenuItem>
                  <MenuItem value={"<="}>Nhỏ hơn hoặc bằng</MenuItem>
                  <MenuItem value={">="}>Lớn hơn hoặc bằng</MenuItem>
                  <MenuItem value={"like"}>Xâu con</MenuItem>
                  <MenuItem value={"<>"}>Khác</MenuItem>
                  <MenuItem value={"in"}>Theo danh sách</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Nhãn(*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.Label}
                  onChange={(event) => {
                    this.saveField("Label", event.target.value);
                  }}
                  error={this.state.validateStatus.Label !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Theo danh mục:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SourceName}
                  onChange={(event) => {
                    this.saveField("SourceName", event.target.value);
                  }}
                  error={this.state.validateStatus.SourceName !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Danh mục - Tên:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SourceLabel}
                  onChange={(event) => {
                    this.saveField("SourceLabel", event.target.value);
                  }}
                  error={this.state.validateStatus.SourceLabel !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Danh mục - Giá trị:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SourceValue}
                  onChange={(event) => {
                    this.saveField("SourceValue", event.target.value);
                  }}
                  error={this.state.validateStatus.SourceValue !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Chiều rộng - Nhãn:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.LabelWidth}
                  onChange={(event) => {
                    this.saveField("LabelWidth", event.target.value);
                  }}
                  error={this.state.validateStatus.LabelWidth !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Chiều rộng - Nhập:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.FieldInputWidth}
                  onChange={(event) => {
                    this.saveField("FieldInputWidth", event.target.value);
                  }}
                  error={
                    this.state.validateStatus.FieldInputWidth !== undefined
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={20}>
                <FormControlLabel
                  label="Có hiển thị"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.after.Visible}
                      onChange={(event) => {
                        this.saveField("Visible", event.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Giá trị mặc định:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={
                    this.state.after.DefaultValue == null
                      ? ""
                      : this.state.after.DefaultValue
                  }
                  onChange={(event) => {
                    this.saveField(
                      "DefaultValue",
                      event.target.value == "" ? null : event.target.value
                    );
                  }}
                  error={this.state.validateStatus.DefaultValue !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Lưu
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
