/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssignmentIcon from "@mui/icons-material/Assignment";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "white",
  },
  menuIcon: {
    color: "white",
  },
}));

export default function MenuButtons(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="contained"
        color={props.color ? props.color : "primary"}
        disabled={props.disabled}
        fullWidth={props.fullWidth ? true : false}
        onClick={handleClick}
      >
        {props.title === undefined ? "Button" : props.title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.SubActions.map((button) => (
          <MenuItem
            onClick={() => {
              if (props.onClick) {
                props.onClick(button);
              }
              handleClose();
            }}
          >
            <ListItemIcon>
              <BookmarkAddedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{props.englishUI ? button.LabelEN : button.Label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
