export const WarningStatusList1 = [
  {Result: 0, Label: "--Không--"},
  {Result: 1, Label: "Chưa xem"},
  {Result: 2, Label: "Đã xem"},
  {Result: 3, Label: "Đang xử lý"},
  {Result: 4, Label: "Đã xử lý"},
  {Result: 5, Label: "Đã duyệt"},
];
export const WarningStatusList2 = [
  {Result: 1, Label: "Chưa xem"},
  {Result: 2, Label: "Đã xem"},
  {Result: 3, Label: "Đang xử lý"},
  {Result: 4, Label: "Đã xử lý"},
  {Result: 5, Label: "Đã duyệt"},
];
const WarningStatusList3 = [
  {Result: 1, Label: "Chưa xem"},
  {Result: 2, Label: "Đã xem"},
  {Result: 3, Label: "Đang xử lý"},
  {Result: 4, Label: "Đã xử lý"},
];


export const XulyWarnings = [
  "",
  "Chưa xem",
  "Đã xem",
  "Đang xử lý",
  "Đã xử lý",
  "Đã duyệt"
];
/**Cac du lieu co dinh dung chung */
export const PhanNhom_LoaiTau = [
  "Khác",
  "Tàu SAR",
  "Tàu hàng",
  "Quốc phòng",
  "Tàu cá",
  "Máy bay",
];
export const PhanNhom_LoaiTau_List = [
  { value: 1, label: "Tàu SAR" },
  { value: 2, label: "Tàu hàng" },
  { value: 3, label: "Quốc phòng" },
  { value: 4, label: "Tàu cá" },
  { value: 5, label: "Máy bay" },
  { value: 0, label: "Khác" },
];
export const PhanLoai_DoiTac = [
  "Khác",
  "CRS",
  "MRCC nước ngoài",
  "Cơ quan khác",
  "Tàu tại khu vực",
];
export const PhanLoai_DoiTac_List = [
  { value: 1, label: "CRS" },
  { value: 2, label: "MRCC nước ngoài" },
  { value: 3, label: "Cơ quan khác" },
  { value: 4, label: "Tàu tại khu vực" },
  { value: 0, label: "Khác" },
];
export const PhanLoai_VungBien = [
  "Khác",
  "Việt Nam",
  "Nước ngoài",
  "Vùng nước cảng",
];
export const PhanLoai_VungBien_List = [
  { value: 1, label: "Việt Nam" },
  { value: 2, label: "Nước ngoài" },
  { value: 3, label: "Vùng nước cảng" },
  { value: 0, label: "Khác" },
];
export const PhanLoai_KhuVuc = [
  "Không rõ",
  "Sát bờ",
  "Gần bờ",
  "Khu vực giữa",
  "Xa bờ",
  "Ngoài khơi",
  "Nước ngoài"
];
export const PhanLoai_KhuVuc_List = [
  { value: 1, label: "Sát bờ" },
  { value: 2, label: "Gần bờ" },
  { value: 3, label: "Khu vực giữa" },
  { value: 4, label: "Xa bờ" },
  { value: 5, label: "Ngoài khơi" },
  { value: 6, label: "Nước ngoài" },
  { value: 0, label: "Không rõ" },
];
export const TrangThai_SuKien_Ten = [
  "",
  "Đang XL",
  "Kết thúc"
];
//danh sach cac truong khi chuyen dang ky thi copy sang
export const Fields_ChuyenDK = [
  "MaDK_Cha",
  "TenTau",
  "HoHieu",
  "IMO",
  "MaCT",
  "TenCT",
  "HTSH",
  "TLSH",
  "DiaChiCT",
  "TenCT2",
  "DiaChiCT2",
  "MaNKT",
  "TenNKT",
  "DiaChiNKT",
  //ky thuat
  "LoaiTau",
  "LoaiTau_Item",
  "TenLT1",
  "TenLT2",
  "CapTau",
  "NgayKey",
  "NoiSX",
  "NoiSX2",
  "NamSX",
  "NamSX2",
  "NamSX3",
  "LOA",
  "LOA_T",
  "Width",
  "Width_T",
  "Draft",
  "Draft_T",
  "GT",
  "GT_T",
  "GT2",
  "GT2_T",
  "DWT",
  "DWT_T",
  "DonViCS",
  "SoMay",
  "CSMay",
  "CSMay_T",
  //bo sung
  "MMSI",
  "VatLieu",
  "SLHam",
  "LOA2",
  "CaoMan",
  "SoHK",
  "Speed",
  "MayChinh",
  "MayPhat",
  "BienChe",
  "BienChe2",
  //ten tau cu
  "LichSu",
  "TenTauCu",
  "DongMoi",
  "DKSH",
  "DKSHNgay",
  "DKCo",
  "DKCoNgay",
  "TenTCDK",
  "TenTCDK2",
  "SoDKiem",
  "NguoiDN",
  "DiaChiDN",
  "NguoiDN2",
  "DiaChiDN2",
  "DienThoaiDN",
  "FaxDN",
  "GhiChu",
];
export const Fields_DoiDangKy_TamThoi = [
  "MaDK_Cha",
  "TenTau",
  "HoHieu",
  "IMO",
  "MaCT",
  "TenCT",
  "HTSH",
  "TLSH",
  "DiaChiCT",
  "TenCT2",
  "DiaChiCT2",
  "MaNKT",
  "TenNKT",
  "DiaChiNKT",
  //ky thuat
  "LoaiTau",
  "LoaiTau_Item",
  "TenLT1",
  "TenLT2",
  "CapTau",
  "NgayKey",
  "NoiSX",
  "NoiSX2",
  "NamSX",
  "NamSX2",
  "NamSX3",
  "LOA",
  "LOA_T",
  "Width",
  "Width_T",
  "Draft",
  "Draft_T",
  "GT",
  "GT_T",
  "GT2",
  "GT2_T",
  "DWT",
  "DWT_T",
  "DonViCS",
  "SoMay",
  "CSMay",
  "CSMay_T",
  //bo sung
  "MMSI",
  "VatLieu",
  "SLHam",
  "LOA2",
  "CaoMan",
  "SoHK",
  "Speed",
  "MayChinh",
  "MayPhat",
  "BienChe",
  "BienChe2",
  //ten tau cu
  "LichSu",
  "TenTauCu",
  "DongMoi",
  "DKSH",
  "DKCo",
  "TenTCDK",
  "TenTCDK2",
  "SoDKiem",
  "NguoiDN",
  "DiaChiDN",
  "NguoiDN2",
  "DiaChiDN2",
  "DienThoaiDN",
  "FaxDN",
  "GhiChu",
];
export const Fields_XoaDK = [
  "MaDK_Cha",
  "LoaiDK",
  "NguoiDN",
  "DiaChiDN",
  "NguoiDN2",
  "DiaChiDN2",
  "DienThoaiDN",
  "FaxDN",
];
export const DanhSach_MauIn = [
  {
    value: "DK1",
    label: "1.Đăng ký không thời hạn",
  },
  {
    value: "DK2",
    label: "2.Xóa đăng ký",
  },
  {
    value: "DK4",
    label: "4.Đăng ký có thời hạn",
  },
  {
    value: "DK5",
    label: "5.Đăng ký tạm thời",
  },
  {
    value: "DK7",
    label: "7.Đăng ký đang đóng",
  },
  {
    value: "DK8",
    label: "8.Xóa đăng ký đang đóng",
  },
  {
    value: "DK10",
    label: "10.Thế chấp",
  },
  {
    value: "DK11",
    label: "11.Thế chấp tàu đang đóng",
  },
  {
    value: "DK12",
    label: "12.Xóa thế chấp",
  },
  {
    value: "DK13",
    label: "13.Xóa thế chấp tàu đang đóng",
  },
  {
    value: "DK16",
    label: "16.Xử lý thế chấp",
  },
  {
    value: "DK17",
    label: "17.Thay đổi thế chấp",
  },
  {
    value: "DK18",
    label: "18.Xử lý thế chấp đang đóng",
  },
  {
    value: "DK19",
    label: "19.Thay đổi thế chấp đang đóng",
  },
  {
    value: "DB",
    label: "Giấy chứng nhận định biên",
  },
  {
    value: "LL",
    label: "Giấy chứng nhận lý lịch tàu",
  },
  {
    value: "BCC",
    label: "Giấy chứng nhận BCC",
  },
  {
    value: "CLC",
    label: "Giấy chứng nhận CLC",
  },
  {
    value: "DT",
    label: "Tên tàu đặt trước",
  },
  {
    value: "MLC",
    label: "GCN DLC",
  },
  {
    value: "IMO",
    label: "GCN phí IMO",
  },
  {
    value: "IMOA4",
    label: "GCN phí IMO, in A4",
  },
  {
    value: "IMOTB",
    label: "Thông báo phí IMO",
  },
];
//danh sach cac truong phai chuyen thanh chu hoa
const Fields_UpperCasing = [
  "HoHieu",
  "TenTau",
  "SoDK",
  "NoiDK",
  "ThuyenTruong",
  "TenCang_Tu",
  "TenCang_Di",
  "TauLai",
  "CapManTau",
  "SoGP",
  "ViTri",
  "ViTri_Den",
  "SoCT",
];
//danh sach cac truong date truoc khi submit phai bo time di
const Fields_Date = ["NgayCT"];
//thuc hien chuan hoa du liue truoc khi gui ve server
export function ChuanHoaDuLieu(data) {
  if (data === undefined) {
    return data;
  }
  var keys = Object.keys(data);
  keys.forEach((field) => {
    if (Fields_UpperCasing.indexOf(field) >= 0) {
      var value = data[field];
      if (value) {
        data[field] = data[field].toString().toUpperCase();
      }
    }
    if (Fields_Date.indexOf(field) >= 0) {
      var value = data[field];
      if (value) {
        if (typeof value == "string") {
          value = new Date(value);
        }
        data[field] = new Date(value.setHours(0, 0, 0, 0));
      }
    }
  });
  return data;
}
export const TenTrangThaiKeHoach = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "Thông báo đến cảng",
  "Xác báo đến cảng",
  "Đã đến PS",
  "Đã kiểm tra xác báo",
  "Cho phép vào cảng",
  "Kế hoạch dự kiến",
  "Kế hoạch chính thức",
  "",
  "",
  "",
  "",
  "Đã cập cảng",
  "Xong thủ tục vào",
  "Xác báo di chuyển",
  "Kiểm tra xác báo",
  "Cho phép di chuyển",
  "Điều động",
  "Kế hoạch dự kiến",
  "Kế hoạch chính thức",
  "Đến vị trí",
  "",
  "Thông báo rời cảng",
  "Kiểm tra thông báo",
  "Cho phép rời cảng",
  "Kế hoạch dự kiến",
  "Kế hoạch chính thức",
  "Xong thủ tục rời",
  "Đã tính phí",
  "Đã cấp phép",
  "Đã rời cảng",
];
