/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import uuid from "react-uuid";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "EMAIL CONFIG" : "CẤU HÌNH EMAIL";
const fieldLabels = englishUI
  ? {
      MailServer: "Server:",
      ServerPort: "Port:",
      EmailFrom: "From email:",
      EmailPwd: "Password:",
    }
  : {
      MailServer: "Server:",
      ServerPort: "Port:",
      EmailFrom: "From email:",
      EmailPwd: "Password:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnSend: "Test send",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnSend: "Test send",
      btnClose: "Thoát",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, [
    "MailServer",
    "ServerPort",
    "EmailFrom",
    "EmailPwd",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
////////////////////////--------------/////////////////////////////////////////
export default class EmailEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        MailServer: "",
        ServerPort: null,
        EmailFrom: "",
        EmailPwd: "",
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  loadDanhMuc() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_DM_Email_Get",
        ThamSo: {},
      })
      .then((response) => {
        if (response.DM_Email.length > 0) {
          this.setState({
            after: response.DM_Email[0],
          });
        }
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({ saving: false });
      });
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  //cap nhat
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.ShipID > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });

        server
          .post("Email/EncryptPwd", this.state.after.EmailPwd)
          .then((response) => {
            server
              .post("Data/DoRequest", {
                Function: "Proc_DM_Email_Update",
                ThamSo: { ...this.state.after, EmailPwd: response },
              })
              .then((response) => {
                alert("Cập nhật thành công!");
                this.props.close();
              })
              .catch((error) => {
                handleServerError(error);
                this.setState({ saving: false });
              });
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };
  doSend = () => {
    try {
      this.setState({ saving: true, errorLog: "" });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_Email_TestSend",
          ThamSo: {},
        })
        .then((response) => {
          //thong bao de gui mail ngay
          server
            .post("Email/WakeUp", { x: 1 })
            .then((response) => {})
            .catch((err) => console.log(err));
          //close
          alert("Cập nhật thành công!");
          this.props.close();
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    } catch (ex) {
      showError(ex);
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.MailServer}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="MailServer"
                  fullWidth
                  autoFocus
                  value={this.state.after.MailServer}
                  onChange={(event) => {
                    this.saveField("MailServer", event.target.value);
                  }}
                  error={this.state.validateStatus.MailServer !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.ServerPort}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <MyNumberEditor
                  id="ServerPort"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.ServerPort}
                  onValueChange={(value) => {
                    this.saveField("ServerPort", value);
                  }}
                  error={this.state.validateStatus.ServerPort !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.EmailFrom}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="EmailFrom"
                  fullWidth
                  value={this.state.after.EmailFrom}
                  onChange={(event) => {
                    this.saveField("EmailFrom", event.target.value);
                  }}
                  error={this.state.validateStatus.EmailFrom !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.EmailPwd}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="EmailPwd"
                  fullWidth
                  value={this.state.after.EmailPwd}
                  onChange={(event) => {
                    this.saveField("EmailPwd", event.target.value);
                  }}
                  error={this.state.validateStatus.EmailPwd !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>
          <Button
            onClick={() => {
              this.doSend();
            }}
            variant="contained"
          >
            {buttonLabels.btnSend}
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
