import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  OutlinedInput,
  Grid,
  FormLabel,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/theme/blue-dark.css";
import "@inovua/reactdatagrid-community/theme/amber-light.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhanMemEditDialog from "./PhanMemEditDialog";
import SimpleDialog from "../components/SimpleDialog";
import { filterStyles } from "../components/common";
import moment from "moment";
import SelectFilter from "react-select";
import QuyenPhanMemDialog from "./QuyenPhanMemDialog";


const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const PhanLoaiBenCang = [
  "Vùng đón trả hoa tiêu",
  "Cảng thường",
  "Vùng neo",
  "Phân cảng dầu khí",
  "",
  "Bến phao",
];
const columns_grid = [
  {
    name: "SCD",
    header: "Mã PM",
    headerAlign: "center",
    defaultWidth: 85,
  },
  {
    name: "SNM",
    header: "Tên phần mềm",
    headerAlign: "center",
    defaultWidth: 350,
  },
  {
    name: "UPUSCD",
    header: "Người CN",
    headerAlign: "center",
    defaultWidth: 120,
  },
  {
    name: "UPTM",
    header: "Ngày CN",
    headerAlign: "center",
    defaultWidth: 150,
    render: (props) => moment(props.data.UPTM).format("DD/MM/YYYY HH:mm"),
  },
];
const defaultSortInfo = { name: "TenCang", dir: -1, type: "string" };
const loadDataError = (error, empty, log, trace) => {
  return !error ? (
    <b
      style={{
        padding: 8,
        border: "1px solid #7986cb",
        color: "#0000ff",
        borderRadius: 4,
      }}
    >
      {error ? log : empty ? "Không có bản ghi nào!" : ""}
    </b>
  ) : (
    <a
      href="#"
      style={{ textDecoration: "none" }}
      onClick={() =>
        toast.error(trace, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      }
    >
      <b
        style={{
          padding: 8,
          border: "1px solid #7986cb",
          color: "red",
          borderRadius: 4,
        }}
      >
        {error ? log : empty ? "Không có bản ghi nào!" : ""}
      </b>
    </a>
  );
};
export default class PhanMemDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      saving: false, //co dang save du lieu hay khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      loading_coquan: true, // co dang load ding danh muc nguon tin ko
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong

      source_danhmuc: {
        CangBien: undefined,
        CangBien_Filter: undefined,
        KhuVuc: undefined,
      },
      listData: [],
      after: {
        PageIndex: 1,
        PageSize: 1000,
        Ascending: true,
        SortBy: 0, //0-tentau,1-sogcn
      },
      rowSelected: undefined, //dang lua chon row nao
      showEditDialog: false, // hien thi form cap nhat hay khong
      edittingRecord: undefined, //ban ghi chon de edit
      showConfirmXoa: false, //hien thi dialog xoa
      hasQuyen: global.hasRoles("Admin"),
      open_show_quyen_phanmem: false, //hien thi form quyen cua phan mem
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  handleClose(selection) {
    this.props.close(selection);
  }
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);

      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  saveField = (fieldName, value) => {
    var s = this.state;
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    }
    if (fieldName !== "PageSize" && fieldName !== "PageIndex") {
      this.setState(this.state);
    } else {
      this.setState(this.state, () => {
        this.doSearch();
      });
    }
    this.setState(this.state);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  loadDanhMuc() {}

  loadData = ({ skip, limit, sortInfo }) => {
    var loader = new Promise((resolve, eject) => {
      server
        .query("PhanQuyen/GetDanhSachPhanMem", {})
        .then((response) => {
          this.setState({
            search_error: false,
            search_result: { data: response, count: response.length },
            rowSelected: undefined,
          });
          resolve({ data: response, count: response.length });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  doSearch = () => {
    console.log(this.state.after);
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectedRecord: {},
    });
  };
  render() {
    let tau = this.state.tauData;
    return (
      <Dialog
        open={this.props.open}
        fullWidth={true}
        maxWidth={"lg"}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AppBar sx={{ position: "relative" }}>
            <Stack direction="row">
              <Typography
                sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
                variant="h6"
                component="div"
              >
                Danh mục phần mềm
              </Typography>
              <Stack direction="row" spacing={4}>
                {this.state.loading_data ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                {this.state.saving ? (
                  <CircularProgress color="warning" size={24} />
                ) : null}
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    this.setState({ showEditDialog: true });
                  }}
                >
                  Thêm
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    this.state.rowSelected === undefined ||
                    (this.state.rowSelected.MaCang != global.User.MaCang &&
                      this.state.rowSelected.Khoa)
                  }
                  onClick={() => {
                    this.setState({
                      showEditDialog: true,
                      edittingRecord: this.state.rowSelected,
                    });
                  }}
                >
                  Sửa
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  disabled={this.state.rowSelected === undefined}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  Xóa
                </Button>
                {this.props.disableChon ? null : (
                  <Button
                    color="success"
                    variant="contained"
                    disabled={this.state.rowSelected === undefined}
                    onClick={() => {
                      this.setState({
                        open_show_quyen_phanmem: true,
                        edittingRecord: this.state.rowSelected,
                      });
                    }}
                  >
                    Quyền
                  </Button>
                )}
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.handleClose(undefined)}
                >
                  Thoát
                </Button>
              </Stack>
            </Stack>
          </AppBar>
          <Paper variant="outlined">
            <Grid container spacing={1} columns={12}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon fontSize="small" />}
                  onClick={this.doSearch}
                >
                  Tìm kiếm
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <ReactDataGrid
            style={{ height: 450, fontSize: 12 }}
            showZebraRows={true}
            columns={columns_grid}
            pagination={true}
            multiSelect={false}
            showHoverRows={false}
            checkboxColumn={{
              render: (props) => (
                <input
                  type="checkbox"
                  readOnly
                  checked={props.rowSelected}
                ></input>
              ),
            }}
            defaultLimit={10}
            limit={this.state.after.PageSize}
            skip={(this.state.after.PageIndex - 1) * this.state.after.PageSize}
            onSkipChange={(skip) => {
              this.saveField("PageIndex", skip / this.state.after.PageSize + 1);
            }}
            dataSource={this.state.search_dataSource}
            idProperty="SCD"
            headerHeight={30}
            rowHeight={30}
            defaultSortInfo={defaultSortInfo}
            onSortInfoChange={this.onSortInfoChange}
            onLimitChange={(v) => this.saveField("PageSize", v)}
            emptyText={loadDataError(
              this.state.search_error,
              this.state.search_result.data.length === 0,
              this.state.errorMessage,
              this.state.errorLog
            )}
            skipLoadOnMount={true}
            enableSelection={true}
            onSelectionChange={({ data, selected, unselected }) => {
              this.setState({ rowSelected: data });
            }}
          ></ReactDataGrid>
        </DialogContent>
        {this.state.open_show_quyen_phanmem ? (
          <QuyenPhanMemDialog
            open={true}
            PhanMem={this.state.edittingRecord}
            close={(record) => {
              this.setState({
                open_show_quyen_phanmem: false,
              });
            }}
          />
        ) : null}
        {this.state.showEditDialog ? (
          <PhanMemEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={(record) => {
              this.setState({
                showEditDialog: false,
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa phần mềm:" + this.state.rowSelected.SCD + " ?"
            }
            open={true}
            close={(ok) => {
              if (ok) {
                //thuc hien xoa
                server
                  .post("PhanQuyen/XoaPhanMem", {
                    SCD: this.state.rowSelected.SCD,
                  })
                  .then((response) => {
                    this.setState({ showConfirmXoa: false, rowSelected: undefined, edittingRecord: undefined });
                    //reload
                    this.doSearch();
                  })
                  .catch((error) => {
                    this.setState({ showConfirmXoa: false });
                    this.handleServerError(error);
                  });
              } else {
                this.setState({ showConfirmXoa: false });
              }
            }}
          />
        ) : null}
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
