import React from "react";
import {
  Select,
  MenuItem,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Link,
} from "@mui/material";
import CheckResultConfirmDialog from "./CheckResultConfirmDialog";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import DoneIcon from "@mui/icons-material/Done";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import { AttachFile } from "@mui/icons-material";
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
const colors = ["#4a148c", "#ff5722", "#ff0000"];
//Hien thi danh sach cac canh bao cua 1 report
export default class CheckResultControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: undefined,
    };
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  updateXuLy() {
    //load de hien thi preview report
    var result = this.state.rowSelected.Result == 1 ? 2 : 1;
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_CheckResult_Confirm",
        ThamSo: {
          CheckResultID: this.state.rowSelected.CheckResultID,
          Result: result, //dao nguoc trang thai
        },
      })
      .then((response) => {
        this.state.rowSelected.Result = result;
        this.setState({
          show_confim_warning: false,
        });
        //thong bao cho form cha
        if (this.props.onChange) {
          this.props.onChange();
        }
      })
      .catch((error) => {
        showError(error);
      });
  }
  render() {
    var list = this.props.CheckResults;
    var stt = 1;
    return (
      <div style={{ height: this.props.maxHeight, overflow: "scroll" }}>
        {this.props.Attachs.length > 0 ? (
          <List fullWidth>
            {this.props.Attachs.map((att) => (
              <ListItemButton
                onClick={() => {
                  window.open(
                    server.getRequest("Data/GetFile?fileID=" + att.FileID),
                    "_blank"
                  );
                }}
              >
                <ListItemText
                  style={{
                    color: "blue",
                  }}
                >
                  {att.FileName}
                </ListItemText>
                <ListItemIcon>
                  <AttachFile color="primary" />
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        ) : null}
        <List fullWidth>
          {list.map((check) => (
            <ListItemButton
              onClick={() => {
                if (this.props.onWarningClicked) {
                  this.props.onWarningClicked(check);
                }
              }}
            >
              <ListItemText
                style={{
                  color: colors[check.WarningLevel],
                }}
              >
                {stt++ + ") " + check.RuleName}
              </ListItemText>
              <ListItemIcon>
                {check.Result <
                5 *
                  ((check.Result1 ? 1 : 0) +
                    (check.Result2 ? 1 : 0) +
                    (check.Result3 ? 1 : 0)) ? (
                  <PriorityHighIcon fontSize="small" color="warning" />
                ) : (
                  <DoneIcon fontSize="small" color="primary" />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
        {this.state.show_confim_warning ? (
          <CheckResultConfirmDialog
            confirm
            title="Xác nhận"
            message={
              this.state.rowSelected.Result == 2
                ? "Hủy xác nhận xử lý ?"
                : "Xác nhận đã xử lý ?"
            }
            ActionGuide={
              this.state.rowSelected ? this.state.rowSelected.ActionGuide : ""
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  this.updateXuLy();
                } else {
                  this.setState({ show_confim_warning: false });
                }
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
