/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import { Shower } from "@mui/icons-material";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "QUOTA OF SHIP" : "ĐỊNH MỨC CỦA TÀU";
const fieldLabels = englishUI
  ? {
      RpmME: "Rpm",
      PowerME: "Power",
      ConsFOME: "FO.ME",
      ConsDOME: "DO.ME",
      ConsCylME: "Cyl",
      ConsSysME: "Sys",
      ConsFOAENS: "FO.AE.S",
      ConsDOAENS: "DO.AE.S",
      ConsFOAENP0: "FO.AE.P0",
      ConsDOAENP0: "DO.AE.P0",
      ConsFOAENP: "FO.AE.P1",
      ConsDOAENP: "DO.AE.P1",
      ConsLOAE: "LO.AE",
      ConsFOIG: "FO.IG",
      ConsFONS: "FO.S",
      ConsDONS: "DO.S",
      ConsFONP0: "FO.P0",
      ConsDONP0: "DO.P0",
      ConsFONP: "FO.P1",
      ConsDONP: "DO.P1",
      SpeedBallast: "Speed.B",
      SpeedCargo: "Speed.L",
    }
  : {
      RpmME: "Rpm",
      PowerME: "Power",
      ConsFOME: "FO.ME",
      ConsDOME: "DO.ME",
      ConsCylME: "Cyl",
      ConsSysME: "Sys",
      ConsFOAENS: "FO.AE.S",
      ConsDOAENS: "DO.AE.S",
      ConsFOAENP0: "FO.AE.P0",
      ConsDOAENP0: "DO.AE.P0",
      ConsFOAENP: "FO.AE.P1",
      ConsDOAENP: "DO.AE.P1",
      ConsLOAE: "LO.AE",
      ConsFOIG: "FO.IG",
      ConsFONS: "FO.S",
      ConsDONS: "DO.S",
      ConsFONP0: "FO.P0",
      ConsDONP0: "DO.P0",
      ConsFONP: "FO.P1",
      ConsDONP: "DO.P1",
      SpeedBallast: "Speed.B",
      SpeedCargo: "Speed.L",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
      btnTest: "Test",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
      btnTest: "Thử",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["GraphCode", "GraphValues"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version

  return ret;
}
function newRecord(shipid, stt) {
  return {
    ShipID: shipid,
    DinhMucID: stt,
  };
}
////////////////////////--------------/////////////////////////////////////////
export default class DinhMucEditDialog extends React.Component {
  constructor(props) {
    super(props);
    var shipID = props.edittingRecord.ShipID;
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      DanhSach: [
        newRecord(shipID, 1),
        newRecord(shipID, 2),
        newRecord(shipID, 3),
        newRecord(shipID, 4),
        newRecord(shipID, 5),
        newRecord(shipID, 6),
      ],
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //load du lieu khi form load
  loadDanhMuc() {
    if (this.props.edittingRecord !== undefined) {
      this.setState({ saving: true });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_DinhMuc_GetOne",
          ThamSo: {
            ShipID: this.props.edittingRecord.ShipID,
          },
        })
        .then((response) => {
          for(var i = 0;i < this.state.DanhSach.length;i++) {
            var dm = this.state.DanhSach[i];
            let find = response.DM_DinhMuc.find((d)=>d.DinhMucID == dm.DinhMucID);
            if(find) {
              this.state.DanhSach[i] = find;
            }
          }
          this.setState({ saving: false });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    }
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = {};
      var action = this.props.edittingRecord ? "update" : "insert";
      var list = [];
      this.state.DanhSach.map((dm)=>{
        if(dm.RpmME) {
          list.push(dm);
        }
      })
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_DinhMuc_Update",
            ThamSo: list,
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action);
            }
            alert("Cập nhật thành công!");
            this.props.close();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };
  //thuc hien thu gia tri
  doTest = () => {
    try {
      this.setState({ saving: true, errorLog: "" });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_Graph_GetTest",
          ThamSo: {
            GraphCode: this.state.after.GraphCode,
            TestValue: this.state.after.TestValue,
          },
        })
        .then((response) => {
          //lay ket qua neu co
          this.state.after.TestResult = response.DM_Graph_Result[0].Result;
          this.setState({
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
          });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    } catch (ex) {
      showError(ex);
    }
  };
  render() {
    var keys = Object.keys(fieldLabels);
    var stt = 1;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>No.</FormLabel>
              </Grid>
              {keys.map((k) => (
                <Grid item xs={1}>
                  <FormLabel>{fieldLabels[k]}</FormLabel>
                </Grid>
              ))}
              <Grid item xs={1}></Grid>

              {this.state.DanhSach.map((dm) => (
                <>
                  <Grid item xs={1}>
                    <FormLabel>{dm.DinhMucID + "."}</FormLabel>
                  </Grid>
                  {keys.map((k) => (
                    <Grid item xs={1}>
                      <MyNumberEditor
                        id={k}
                        fullWidth
                        value={dm[k]}
                        onValueChange={(value) => {
                          dm[k] = value;
                          this.setState({
                            DanhSach: this.state.DanhSach,
                          });
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={1}></Grid>
                </>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>

          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
