/*Tao code cho procedure thuc hien tim kiem co phan trang*/
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import {
  filterStyles,
  handleServerError,
  showError,
} from "../components/common";
import AsyncSelect from "react-select/async";
import { tr } from "date-fns/locale";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const validator = require("../data/DangKy_Validator");

const fields = [
  "MaCang",
  "MaKV",
  "TenCang",
  "TenCang_TA",
  "MoTa",
  "MoTa_TA",
  "LoaiCang",
  "PhiNeo",
  "PhiDV",
  "Website",
  "STT",
  "HoatDong",
];

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validate_Data(data) {
  var ret = validate_Empty(data, ["TableName"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

export default class PagingSearchCodeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {
        DanhSachTables: [],
      },
      after: {
        TableName: "",
        TableName_Item: {},
        FromTable: "",
        OrderList: this.getColumnsGrid(props.gridColumns), //danh sach sap tuy theo columns
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  getColumnsGrid(columns) {
    //hien thi ten cot theo columns cua grid form tim kiem
    var str = "";
    columns.map((col) => {
      str += (str !== "" ? ";" : "") + col.name;
    });
    return str;
  }
  //lay du lieu danh muc
  loadDanhMuc() {
    //lay danh sach table
    server
      .post("Data/DoRequest", {
        Function: "Proc_Tool_Get_Tables",
        ThamSo: {},
      })
      .then((response) => {
        var danhsach = response.DanhSachTables;
        danhsach.map((item) => {
          item.label = item.TABLE_NAME;
          item.vaue = item.TABLE_NAME;
        });
        this.setState({
          source_danhmuc: {
            DanhSachTables: danhsach,
          },
        });
      })
      .catch((error) => handleServerError(error));
  }
  filterDanhSach = (danhsach, inputValue, callback) => {
    inputValue = inputValue ? inputValue.toUpperCase() : "";
    var i = 0;
    var count = 0;
    var list = [];
    for (; i < danhsach.length && count < 1000; i++) {
      var item = danhsach[i];
      if (
        inputValue === "" ||
        item.label.toUpperCase().indexOf(inputValue) >= 0
      ) {
        list.push(item);
        count++;
      }
    }
    callback(list);
  };
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doGenerate = () => {
    try {
      var check = validate_Data(this.state.after);

      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_Generate_Search_Procedure_Code",
            ThamSo: {
              TableName: this.state.after.TableName,
              FromTable: this.state.after.FromTable,
              OrderList: this.state.after.OrderList,
              DieuKien: this.props.DieuKien,
              CodeID: "",
            },
          })
          .then((response) => {
            this.setState({
              after: {
                ...this.state.after,
                ScriptCode: response.DanhSachCodes
                  ? response.DanhSachCodes[0]["Script"]
                  : "",
              },
              saving: false,
            });
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  doLoad = () => {
    try {
      //load du lieu cac tham so su dung generate code trong lan truoc do
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_Cus_GenerateCodes_Get",
            ThamSo: {
              CodeID: this.state.after.CodeID,
            },
          })
          .then((response) => {
            var data =
              response.DataList && response.DataList.length > 0
                ? JSON.parse(response.DataList[0].ThamSoJson)
                : {};
            this.setState({
              after: {
                ...this.state.after,
                ...data,
              },
              saving: false,
            });
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  doSave = () => {
    try {
      //load du lieu cac tham so su dung generate code trong lan truoc do
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_Cus_GenerateCodes_Save",
            ThamSo: {
              ...this.state.after,
            },
          })
          .then((response) => {
            this.setState({
              saving: false,
            });
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Paging search code</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Main table (*):</FormLabel>
              </Grid>
              <Grid item xs={8}>
                {this.state.source_danhmuc.DanhSachTables.length > 0 ? (
                  <AsyncSelect
                    id="cboQT"
                    fullWidth
                    cacheOptions
                    defaultOptions
                    isClearable
                    placeholder=""
                    styles={filterStyles}
                    menuPosition={"fixed"}
                    value={this.state.after.TableName_Item}
                    loadOptions={(i, c) =>
                      this.filterDanhSach(
                        this.state.source_danhmuc.DanhSachTables,
                        i,
                        c
                      )
                    }
                    onChange={(item) => {
                      this.saveData({
                        TableName_Item: item ? item : {},
                        TableName: item ? item.TABLE_NAME : "",
                        FromTable:
                          this.state.after.FromTable == "" && item
                            ? item.TABLE_NAME
                            : this.state.after.FromTable,
                        CodeID: item ? "Search_" + item.TABLE_NAME : "",
                      });
                    }}
                    error={this.state.validateStatus.TableName !== undefined}
                  ></AsyncSelect>
                ) : (
                  <OutlinedInput fullWidth readOnly />
                )}
              </Grid>
              <Grid item xs={9}>
                <Button
                  onClick={() => {
                    this.doGenerate();
                  }}
                  variant="contained"
                  disabled={this.state.after.TableName == ""}
                >
                  Generate
                </Button>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Code ID (*):</FormLabel>
              </Grid>
              <Grid item xs={8}>
                <OutlinedInput
                  fullWidth
                  multiline
                  value={this.state.after.CodeID}
                  onChange={(event) =>
                    this.saveField("CodeID", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => {
                    this.doLoad();
                  }}
                  variant="contained"
                  disabled={this.state.after.CodeID == ""}
                >
                  Load
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    this.doSave();
                  }}
                  variant="contained"
                  disabled={this.state.after.CodeID == ""}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>From tables (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  multiline
                  value={this.state.after.FromTable}
                  onChange={(event) =>
                    this.saveField("FromTable", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Columns grid (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  multiline
                  value={this.getColumnsGrid(this.props.gridColumns)}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Order list (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  multiline
                  value={this.state.after.OrderList}
                  onChange={(event) =>
                    this.saveField("OrderList", event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={24}>
                <OutlinedInput
                  fullWidth
                  multiline
                  value={this.state.after.ScriptCode}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
