/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import uuid from "react-uuid";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../components/common";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import { af } from "date-fns/locale";
import { Add, Clear, Delete, Edit, SyncAlt } from "@mui/icons-material";
import BackupIcon from "@mui/icons-material/Backup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SimpleDialog from "../components/SimpleDialog";
import SimpleInputDialog from "../components/SimpleInputDialog";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";

const dataStruct = require("../data/DataStruct");
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
//tao control cho tung field tim kiem
function createTieuChi(field, props, state, saveField, saveData, autoFocus) {
  console.log(state);
  return (
    <>
      <Grid item xs={field.LabelWidth}>
        <FormLabel>{field.Label}</FormLabel>
      </Grid>
      <Grid item xs={field.FieldInputWidth}>
        {!myLib.isEmpty(field.SourceName) ? (
          <SelectFilter
            id={field.FieldName}
            fullWidth
            autoFocus={autoFocus}
            options={
              state.DanhMuc[field.SourceName]
                ? state.DanhMuc[field.SourceName]
                : []
            }
            getOptionLabel={(item) => item[field.SourceLabel]}
            getOptionValue={(item) => item[field.SourceValue]}
            placeholder=""
            styles={filterStyles}
            menuPosition={"fixed"}
            value={state.after[field.FieldName]}
            isMulti={field.DataType.indexOf("Array") >= 0}
            isClearable
            onChange={(item) => {
              saveField(field.FieldName, item ? item : []);
            }}
          ></SelectFilter>
        ) : field.DataType == "String" ? (
          <OutlinedInput
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(event) => {
              saveField(field.FieldName, event.target.value);
            }}
          />
        ) : field.DataType == "Date" ? (
          <MyDateEditor
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(value) => {
              saveField(field.FieldName, value);
            }}
          />
        ) : field.DataType == "DateTime" ? (
          <MyDateTimeEditor
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(value) => {
              saveField(field.FieldName, value);
            }}
          />
        ) : null}
      </Grid>
      <Grid item xs={1}></Grid>
    </>
  );
}
//lay gia tri de truyen ve server
function getTieuChi(after, configFields) {
  var newAfter = myLib.cloneObject(after);
  configFields.map((field) => {
    if (field.DataType.indexOf("Array") >= 0) {
      var list = [];
      var giatri = after[field.FieldName];
      if (giatri && giatri.length > 0) {
        giatri.map((gt) => list.push(gt[field.SourceValue]));
      }
      newAfter[field.FieldName] = list.length == 0 ? null : list;
    } else {
      if (
        !myLib.isEmpty(field.SourceName) &&
        after[field.FieldName] &&
        after[field.FieldName] && [field.SourceValue]
      ) {
        newAfter[field.FieldName] = after[field.FieldName][field.SourceValue];
      }
    }
  });
  return newAfter;
}
//////////////////////////////////////////////---------------///////////////////////////////////////////////////////
export default class ShowReportDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {
        Report_RDLC_Files: [],
      },
      FormConfig: null,
      uploading: false,
      after: {},
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  loadDanhMuc() {
    //load cac danh muc can thiet
    var formDefine = myLib.cloneObject(this.props.ReportFormDefine);
    formDefine.ReportFormConfig = JSON.parse(formDefine.ReportFormConfig);
    //load danh sach danh muc neu co
    var list = "";
    formDefine.ReportFormConfig.FilterList.map((item) => {
      if (!myLib.isEmpty(item.SourceName)) {
        if (list.indexOf(item.SourceName) < 0) {
          list = list + (list !== "" ? "," : "") + item.SourceName;
        }
      }
    });
    if (list !== "") {
      //load danh muc
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: list,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => {
              this.loadDanhSachFiles(formDefine.ReportFormID);
              this.setState({ FormConfig: formDefine });
            }
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    } else {
      this.loadDanhSachFiles(formDefine.ReportFormID);
      this.setState({ FormConfig: formDefine });
    }
  }
  loadDanhSachFiles = (formID) => {
    //lay danh sach files mau rdlc
    server
      .post("Data/DoRequest", {
        Function: "Proc_Report_RDLC_Files_GetBy",
        ThamSo: {
          ReportFormID: formID,
        },
      })
      .then((response) => {
        this.setState({
          DanhMuc: {
            ...this.state.DanhMuc,
            ...response,
          },
          after: {
            RdlcFileID:
              response.Report_RDLC_Files.length > 0
                ? response.Report_RDLC_Files[0].RdlcFileID
                : 0,
            RdlcFileName:
              response.Report_RDLC_Files.length > 0
                ? response.Report_RDLC_Files[0].RdlcFileName
                : "",
          },
        });
      })
      .catch((error) => {
        handleServerError(error);
      });
  };
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: false, errorLog: "" });
        console.log(this.state.after);
        var thamso = getTieuChi(
          this.state.after,
          this.state.FormConfig.ReportFormConfig.FilterList
        );
        myLib.assign(this.state.FormConfig, thamso, [
          "ReportFormID",
          "ReportTitle",
        ]);
        thamso.RdlcFileID = this.state.after.RdlcFileID; // ma bieu mau report
        thamso.ReportRequestID = uuid();
        thamso.ReportDataName =
          this.state.FormConfig.ReportFormConfig.ReportDataName;
        thamso.ReportDataTables =
          this.state.FormConfig.ReportFormConfig.ReportDataTables;
        console.log(thamso);
        server
          .post("Data/DoRequest", {
            Function: "Proc_Report_ExecReport",
            ThamSo: thamso,
          })
          .then((response) => {
            this.setState({
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            var ps = encodeURIComponent(JSON.stringify(thamso));
            window.open(
              server.getServer() +
                "reports/ShowReport.aspx?" +
                "thamso_json=" +
                ps +
                "&_t=" +
                new Date().getTime() +
                "&sessionId=" +
                global.User.RandomID,
              "_blank"
            );
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  //tao file report RDLC trong CSDL
  doCreateFile = () => {
    try {
      this.setState({ saving: true, errorLog: "" });
      server
        .post("Data/DoRequest", {
          Function: "Proc_Report_File_Create",
          ThamSo: {
            ReportFormID: this.state.FormConfig.ReportFormID,
          },
        })
        .then((response) => {
          this.setState({
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
          });
          alert("Cập nhật thành công!");
          this.loadDanhSachFiles(this.state.FormConfig.ReportFormID);
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({
            saving: false,
          });
        });
    } catch (error) {
      handleServerError(error);
      this.setState({
        saving: false,
      });
    }
  };
  doUpload(files) {
    //thuc hien upload file report
    const formData = new FormData();
    files.map((item, index) => {
      if (item.name !== undefined) {
        formData.append("file" + index, item, item.name);
      }
    });
    formData.append("RdlcFileID", this.state.after.RdlcFileID);
    this.setState({ uploading: true });
    server
      .upload("Reporting/UploadReportFile", formData)
      .then((response) => {
        console.log("save dinh kem response:", response);
        this.setState({
          uploading: false,
        });
        //thong bao cho parent biet co data duoc saved
        alert("Upload thành công!");
      })
      .catch((error) =>
        this.setState({ uploading: false, errorLog: error + "" })
      );
  }
  doUpdate_DataSet() {
    //thuc hien update lai cau truc du lieu trong file rdlc theo cau truc dataset dinh nghia trong data define
    try {
      this.setState({ syncing: true, errorLog: "" });
      server
        .post("Data/DoRequest", {
          Function: "Proc_Report_RDLC_Files_SyncSchema",
          ThamSo: {
            ReportFormID: this.state.FormConfig.ReportFormID,
            RdlcFileID: this.state.after.RdlcFileID,
          },
        })
        .then((response) => {
          this.setState({
            dataChanged: false,
            canSaved: false,
            syncing: false,
            errorMessage: "",
            errorLog: "",
          });
          alert("Cập nhật thành công!");
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({
            syncing: false,
          });
        });
    } catch (error) {
      handleServerError(error);
      this.setState({
        syncing: false,
      });
    }
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.state.FormConfig ? this.state.FormConfig.ReportTitle : "Report"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={26}>
              {this.state.FormConfig ? (
                <>
                  {this.state.FormConfig.ReportFormConfig.FilterList.map(
                    (field, index) => (
                      <>
                        {createTieuChi(
                          field,
                          this.props,
                          this.state,
                          this.saveField,
                          this.saveData,
                          index == 0
                        )}
                      </>
                    )
                  )}
                </>
              ) : null}
              <Grid item xs={26}>
                <Typography variant="h6">Chọn biểu mẫu:</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Biểu mẫu:</FormLabel>
              </Grid>
              <Grid item xs={16}>
                <SelectFilter
                  id="cboMau"
                  fullWidth
                  options={this.state.DanhMuc.Report_RDLC_Files}
                  getOptionLabel={(item) => item.RdlcFileName}
                  getOptionValue={(item) => item.RdlcFileID}
                  placeholder=""
                  styles={filterStyles}
                  menuPosition={"fixed"}
                  value={{
                    RdlcFileID: this.state.after.RdlcFileID,
                    RdlcFileName: this.state.after.RdlcFileName,
                  }}
                  isClearable
                  onChange={(item) => {
                    this.saveData({
                      RdlcFileID: item ? item.RdlcFileID : 0,
                      RdlcFileName: item ? item.RdlcFileName : "",
                    });
                  }}
                ></SelectFilter>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                  onClick={() => this.doCreateFile()}
                >
                  <Add color="primary" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={this.state.after.RdlcFileID === 0}
                  sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                  onClick={() => this.setState({ open_edit_filename: true })}
                >
                  <Edit color="info" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={this.state.after.RdlcFileID === 0}
                  sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                  onClick={() => this.setState({ showConfirmXoa: true })}
                >
                  <Clear color="error" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={this.state.after.RdlcFileID === 0}
                  sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                  onClick={() =>
                    window.open(
                      server.getRequest(
                        "/Reporting/GetReportFile?RdclFileID=" +
                          this.state.after.RdlcFileID
                      ),
                      "_blank"
                    )
                  }
                >
                  <FileDownloadIcon color="success" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                {this.state.uploading ? (
                  <CircularProgress color="success" size={24} />
                ) : (
                  <Dropzone
                    maxFiles={1}
                    multiple={false}
                    onDrop={(files) => this.doUpload(files)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <IconButton
                          disabled={
                            this.state.after.RdlcFileID === 0 ||
                            this.state.uploading
                          }
                          sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            padingLeft: 1,
                          }}
                        >
                          <BackupIcon color="success" />
                        </IconButton>
                      </div>
                    )}
                  </Dropzone>
                )}
              </Grid>
              <Grid item xs={1}>
                {this.state.syncing ? (
                  <CircularProgress color="success" size={24} />
                ) : (
                  <IconButton
                    disabled={this.state.after.RdlcFileID === 0}
                    sx={{ paddingTop: 0, paddingBottom: 0, padingLeft: 1 }}
                    onClick={() => this.doUpdate_DataSet()}
                  >
                    <SyncAltIcon color="action" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Xem báo cáo
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa:" + this.state.after.RdlcFileID + " ?"}
            open={true}
            OKRunning={this.state.saving ? "Đang XL..." : "OK"}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_Report_RDLC_Files_Delete",
                      ThamSo: {
                        RdlcFileID: this.state.after.RdlcFileID,
                      },
                    })
                    .then((response) => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.loadDanhSachFiles(
                        this.state.FormConfig.ReportFormID
                      );
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
        {this.state.open_edit_filename ? (
          <SimpleInputDialog
            confirm
            title="Sửa biểu mẫu"
            message="Nhập tên biểu mẫu mới:"
            defaultValue={this.state.after.RdlcFileName}
            confirm
            open={true}
            OKRunning={this.state.saving ? "Đang XL..." : "OK"}
            close={(ok, input) => {
              if (!this.state.saving) {
                if (ok && input !== "") {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_Report_RDLC_Files_Update",
                      ThamSo: {
                        RdlcFileID: this.state.after.RdlcFileID,
                        RdlcFileName: input,
                      },
                    })
                    .then((response) => {
                      this.setState({
                        open_edit_filename: false,
                        saving: false,
                      });
                      //reload
                      this.loadDanhSachFiles(
                        this.state.FormConfig.ReportFormID
                      );
                    })
                    .catch((error) => {
                      this.setState({
                        saving: false,
                        open_edit_filename: false,
                      });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ open_edit_filename: false });
                }
              }
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
