/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import { filterStyles } from "../components/common";
import QuyenPhanMemDialog from "./QuyenPhanMemDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const validator = require("../data/DangKy_Validator");

const fields = [
  "MaCang",
  "MaKV",
  "TenCang",
  "TenCang_TA",
  "MoTa",
  "MoTa_TA",
  "LoaiCang",
  "PhiNeo",
  "PhiDV",
  "Website",
  "STT",
  "HoatDong",
];

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validate_ChuTau(data) {
  var ret = validate_Empty(data, [
    "BRCD",
    "BRNM",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    data.BRCD = data.BRCD.toUpperCase();  
  }
  
  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

export default class BoPhanEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {},
      after: {
        BRCD: '',
        BRNM: '',
        ACTIVE: 1,
        ...props.edittingRecord ? props.edittingRecord : {}
      },
      open_show_quyen_phanmem: false, //hien thi form quyen phan mem
    };
  }

  componentDidMount() {
    //this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    var check = validate_ChuTau(this.state.after);
    var action = this.state.after.MaCT > 0 ? "update" : "insert";
    if (!check.error) {
      this.setState({ validateStatus: check, saving: true, errorLog: "" });
      server
        .post("PhanQuyen/SaveBoPhan", this.state.after)
        .then((response) => {
          var before = {};
          var after = {
            ...this.state.after,
          };
          Object.assign(before, after);
          this.setState({
            before: before,
            after: after,
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
          });
          //thong bao cho parent biet co data duoc saved
          if (this.props.onSaved) {
            this.props.onSaved(action, after);
          }
          alert("Cập nhật thành công!");
          this.props.close(after);
        })
        .catch((error) => this.handleServerError(error));
    } else {
      this.setState({ validateStatus: check });
      toast.error("Vui lòng kiểm tra các trường đánh dấu đỏ!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);
      
      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  render() {
    let tau = this.state.tauData;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Bộ phận</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Mã bộ phận(*):</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  autoFocus
                  value={this.state.after.BRCD}
                  readOnly={this.props.edittingRecord !== undefined}
                  onChange={(event) => {
                    this.saveField("BRCD", event.target.value.toUpperCase());
                  }}
                  error={this.state.validateStatus.BRCD !== undefined}
                />
              </Grid>
              <Grid item xs={16}></Grid>
              <Grid item xs={4}>
                <FormLabel>Tên bộ phận(*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  autoFocus={this.props.edittingRecord !== undefined}
                  value={this.state.after.BRNM}
                  onChange={(event) => {
                    this.saveField("BRNM", event.target.value);
                  }}
                  error={this.state.validateStatus.BRNM !== undefined}
                />
              </Grid>
                 
              <Grid item xs={24}>
                <FormControlLabel
                  label="Hoạt động"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.after.ACTIVE > 0}
                      onChange={(event) =>
                        this.saveField("ACTIVE", event.target.checked ? 1 : 0)
                      }
                    />
                  }
                />
              </Grid>
              
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Lưu
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />        
      </Dialog>
    );
  }
}
