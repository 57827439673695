//mot so ham su dung cho GEO
//chuyen doi format 23.51.07N thành dang decimal
export const convertLAT = (st) => {
    var ps = st.split('.');
    if(ps.length != 3) {
        throw 'Định dạng LAT chưa đúng';
    }
    var d = Number(ps[0]);
    if(isNaN(d) || d < 0 || ch > 90) {
        throw 'Giá trị độ - LAT chưa đúng';
    }
    var m = Number(ps[1]);
    if(isNaN(m) || m < 0 || m >= 60) {
        throw 'Giá trị phút - LAT chưa đúng';
    }
    var s = ps[2];
    if(s.length !== 3) {
        throw 'Định dạng LAT chưa đúng';
    }
    var n1 = Number(s.substring(0, 1));
    if(isNaN(n1) || n1 < 0 || n1 >= 60) {
        throw 'Giá trị giây - LAT chưa đúng';
    }
    var ch = s.charAt(2);
    
    if(ch !== 'N' && ch !== 'S') {
        throw 'Giá trị hướng - LAT chưa đúng'
    }
    //chuyen thanh gia tri decimal
    return Number(d + m / 60.0 + n1 / 3600.0).toFixed(5) * (ch == 'N' ? 1 : -1);
};
export const convertLON= (st) => {
    var ps = st.split('.');
    if(ps.length != 3) {
        throw 'Định dạng LON chưa đúng';
    }
    var d = Number(ps[0]);
    if(isNaN(d) || d < 0 || ch > 180) {
        throw 'Giá trị độ - LON chưa đúng';
    }
    var m = Number(ps[1]);
    if(isNaN(m) || m < 0 || m >= 60) {
        throw 'Giá trị phút - LON chưa đúng';
    }
    var s = ps[2];
    if(s.length !== 3) {
        throw 'Định dạng LON chưa đúng';
    }
    var n1 = Number(s.substring(0, 1));
    if(isNaN(n1) || n1 < 0 || n1 >= 60) {
        throw 'Giá trị giây - LON chưa đúng';
    }
    var ch = s.charAt(2);
    
    if(ch !== 'E' && ch !== 'W') {
        throw 'Giá trị hướng - LON chưa đúng'
    }
    //chuyen thanh gia tri decimal
    return Number(d + m / 60.0 + n1 / 3600.0).toFixed(5) * (ch == 'E' ? 1 : -1);
}
//chuyen doi vi do thanh string
export function latToDMS(lat, format) {
  if (lat === undefined || lat === null) {
    return undefined;
  }
  var isN = lat >= 0;
  lat = Math.abs(lat);
  var d = Math.floor(lat);
  var m = Math.floor((lat - d) * 60);
  var s = Math.round((lat - d - m / 60.0) * 3600);
  if (s >= 60) {
    m += 1;
    s = s - 60;
  }
  if (m >= 60) {
    m = m - 60;
    d = d + 1;
  }
  var t =
    (d < 10 ? "0" : "") +
    d +
    "." +
    ((m < 10 ? "0" : "") + m) +
    "." +
    ((s < 10 ? "0" : "") + s) +
    (isN ? "N" : "S");
  return t;
}
export function lonToDMS(lon, format) {
  if (lon === undefined || lon === null) {
    return undefined;
  }
  var isE = lon >= 0;
  lon = Math.abs(lon);
  var d = Math.floor(lon);
  var m = Math.floor((lon - d) * 60);
  var s = Math.round((lon - m / 60.0 - d) * 3600);
  if (s >= 60) {
    m += 1;
    s = s - 60;
  }
  if (m >= 60) {
    m = m - 60;
    d = d + 1;
  }
  var t =
    (d < 10 ? "00" : d < 100 ? "0" : "") +
    d +
    "." +
    ((m < 10 ? "0" : "") + m) +
    "." +
    ((s < 10 ? "0" : "") + s) +
    (isE ? "E" : "W");
  return t;
}
//chuyen tu text sang gia tri
export function latFromDMS(text) {
  text = text.toUpperCase();
  var d = parseInt(text.substring(0, 2));
  if (d === undefined) return undefined;
  var m = parseInt(text.substring(3, 5));
  if (m === undefined) return undefined;
  var s = parseInt(text.substring(6, 8));
  if (s === undefined) return undefined;
  var direct = text.substring(8, 9);
  if (direct === undefined || (direct !== "N" && direct !== "S"))
    return undefined;
  var lat =
    ((direct === "N" ? 1.0 : -1.0) *
      Math.round((d + m / 60.0 + s / 3600.0) * 10000)) /
    10000.0;
  return lat;
}
export function lonFromDMS(text) {
  text = text.toUpperCase();
  var d = parseInt(text.substring(0, 3));
  if (d === undefined) return undefined;
  var m = parseInt(text.substring(4, 6));
  if (m === undefined) return undefined;
  var s = parseInt(text.substring(7, 9));
  if (s === undefined) return undefined;
  var direct = text.substring(9, 10);
  if (direct === undefined || (direct !== "E" && direct !== "W"))
    return undefined;
  var lat =
    ((direct === "E" ? 1.0 : -1.0) *
      Math.round((d + m / 60.0 + s / 3600.0) * 10000)) /
    10000.0;
  return lat;
}