import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
} from "@mui/material";

export default class SimpleLogDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      GhiChu: props.Text ? props.Text : "",
    };
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  render() {
    let tau = this.state.tauData;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.props.title ? this.props.title : "Thông báo"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 450,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <FormLabel sx={{ ml: 1, flex: 1 }} multiline fullWidth>
              {this.props.message}
            </FormLabel>
            <br />
            <OutlinedInput
              fullWidth
              autoFocus
              multiline
              value={this.state.GhiChu}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
