import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "All Fast Report" : "Báo cáo tàu cập cảng";
const fieldLabels = englishUI
  ? {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeAF: "TimeAF",
      Tugs: "Tugs",
      TugTime: "TugTime",
      TugPower: "TugPower",
      ETC: "ETC",
      RobFOAF: "RobFOAF",
      RobDOAF: "RobDOAF",
      RobFWAF: "RobFWAF",
      RunhMEAF: "RunhMEAF",
      RunhAE1AF: "RunhAE1AF",
      RunhAE2AF: "RunhAE2AF",
      RunhAE3AF: "RunhAE3AF",
      RunhIGAF: "RunhIGAF",
      ConsFOMEAF: "ConsFOMEAF",
      ConsDOMEAF: "ConsDOMEAF",
      ConsFOAEAF: "ConsFOAEAF",
      ConsDOAEAF: "ConsDOAEAF",
      ConsFOBLRAF: "ConsFOBLRAF",
      ConsDOBLRAF: "ConsDOBLRAF",
      ConsCylAF: "ConsCylAF",
      ConsSysAF: "ConsSysAF",
      ConsAEAF: "ConsAEAF",
      ConsHydAF: "ConsHydAF",
      FWPureAF: "FWPureAF",
      FlowMEAF: "FlowMEAF",
      FlowAEAF: "FlowAEAF",
      FlowBLRAF: "FlowBLRAF",
      FlowTempAF: "FlowTempAF",
      METempAF: "METempAF",
      RpmCounterMEAF: "RpmCounterMEAF",
      Remark: "Remark",
    }
  : {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeAF: "TimeAF",
      Tugs: "Tugs",
      TugTime: "TugTime",
      TugPower: "TugPower",
      ETC: "ETC",
      RobFOAF: "RobFOAF",
      RobDOAF: "RobDOAF",
      RobFWAF: "RobFWAF",
      RunhMEAF: "RunhMEAF",
      RunhAE1AF: "RunhAE1AF",
      RunhAE2AF: "RunhAE2AF",
      RunhAE3AF: "RunhAE3AF",
      RunhIGAF: "RunhIGAF",
      ConsFOMEAF: "ConsFOMEAF",
      ConsDOMEAF: "ConsDOMEAF",
      ConsFOAEAF: "ConsFOAEAF",
      ConsDOAEAF: "ConsDOAEAF",
      ConsFOBLRAF: "ConsFOBLRAF",
      ConsDOBLRAF: "ConsDOBLRAF",
      ConsCylAF: "ConsCylAF",
      ConsSysAF: "ConsSysAF",
      ConsAEAF: "ConsAEAF",
      ConsHydAF: "ConsHydAF",
      FWPureAF: "FWPureAF",
      FlowMEAF: "FlowMEAF",
      FlowAEAF: "FlowAEAF",
      FlowBLRAF: "FlowBLRAF",
      FlowTempAF: "FlowTempAF",
      METempAF: "METempAF",
      RpmCounterMEAF: "RpmCounterMEAF",
      Remark: "Remark",
    };

const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class AllfastReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        VoyageCode: null,
        ReportTypeID: null,
        ReportTime: null,
        TimeAF: null,
        Tugs: null,
        TugTime: null,
        TugPower: null,
        ETC: null,
        RobFOAF: null,
        RobDOAF: null,
        RobFWAF: null,
        RunhMEAF: null,
        RunhAE1AF: null,
        RunhAE2AF: null,
        RunhAE3AF: null,
        RunhIGAF: null,
        ConsFOMEAF: null,
        ConsDOMEAF: null,
        ConsFOAEAF: null,
        ConsDOAEAF: null,
        ConsFOBLRAF: null,
        ConsDOBLRAF: null,
        ConsCylAF: null,
        ConsSysAF: null,
        ConsAEAF: null,
        ConsHydAF: null,
        FWPureAF: null,
        FlowMEAF: null,
        FlowAEAF: null,
        FlowBLRAF: null,
        FlowTempAF: null,
        METempAF: null,
        RpmCounterMEAF: null,
        Remark: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_Voyage", () => {});
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //load du lieu randomm
  loadRandomSample() {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 4,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 4; //departure report
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_TempReport_Update",
            ThamSo: [
              //danh sach cac report update cung 1 luc'
              {
                ReportID: this.state.after.ReportID,
                VoyageCode: this.state.after.VoyageCode,
                JsonData: JSON.stringify(this.state.after),
                TempStatus: 0,
              },
            ],
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
            //close form
            this.props.close();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
 
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTypeID}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ReportTypeID"
                  key="ReportTypeID"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ReportTypeID}
                  onChange={(event) => {
                    this.saveField("ReportTypeID", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportTypeID !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="TimeAF"
                  key="TimeAF"
                  fullWidth
                  value={this.state.after.TimeAF}
                  onChange={(value) => {
                    this.saveField("TimeAF", value);
                  }}
                  error={this.state.validateStatus.TimeAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Tugs}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Tugs"
                  key="Tugs"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Tugs}
                  onChange={(event) => {
                    this.saveField("Tugs", event.target.value);
                  }}
                  error={this.state.validateStatus.Tugs !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TugTime"
                  key="TugTime"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TugTime}
                  onChange={(event) => {
                    this.saveField("TugTime", event.target.value);
                  }}
                  error={this.state.validateStatus.TugTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugPower}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TugPower"
                  key="TugPower"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TugPower}
                  onChange={(event) => {
                    this.saveField("TugPower", event.target.value);
                  }}
                  error={this.state.validateStatus.TugPower !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ETC"
                  key="ETC"
                  fullWidth
                  value={this.state.after.ETC}
                  onChange={(value) => {
                    this.saveField("ETC", value);
                  }}
                  error={this.state.validateStatus.ETC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFOAF"
                  key="RobFOAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFOAF}
                  onChange={(event) => {
                    this.saveField("RobFOAF", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFOAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDOAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobDOAF"
                  key="RobDOAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobDOAF}
                  onChange={(event) => {
                    this.saveField("RobDOAF", event.target.value);
                  }}
                  error={this.state.validateStatus.RobDOAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFWAF"
                  key="RobFWAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFWAF}
                  onChange={(event) => {
                    this.saveField("RobFWAF", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFWAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhMEAF"
                  key="RunhMEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhMEAF}
                  onChange={(event) => {
                    this.saveField("RunhMEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1AF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE1AF"
                  key="RunhAE1AF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE1AF}
                  onChange={(event) => {
                    this.saveField("RunhAE1AF", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE1AF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE2AF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE2AF"
                  key="RunhAE2AF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE2AF}
                  onChange={(event) => {
                    this.saveField("RunhAE2AF", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE2AF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE3AF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE3AF"
                  key="RunhAE3AF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE3AF}
                  onChange={(event) => {
                    this.saveField("RunhAE3AF", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE3AF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhIGAF"
                  key="RunhIGAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhIGAF}
                  onChange={(event) => {
                    this.saveField("RunhIGAF", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhIGAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOMEAF"
                  key="ConsFOMEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOMEAF}
                  onChange={(event) => {
                    this.saveField("ConsFOMEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOMEAF"
                  key="ConsDOMEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOMEAF}
                  onChange={(event) => {
                    this.saveField("ConsDOMEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOAEAF"
                  key="ConsFOAEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOAEAF}
                  onChange={(event) => {
                    this.saveField("ConsFOAEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOAEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOAEAF"
                  key="ConsDOAEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOAEAF}
                  onChange={(event) => {
                    this.saveField("ConsDOAEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOBLRAF"
                  key="ConsFOBLRAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOBLRAF}
                  onChange={(event) => {
                    this.saveField("ConsFOBLRAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOBLRAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOBLRAF"
                  key="ConsDOBLRAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOBLRAF}
                  onChange={(event) => {
                    this.saveField("ConsDOBLRAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsCylAF"
                  key="ConsCylAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsCylAF}
                  onChange={(event) => {
                    this.saveField("ConsCylAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsCylAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsSysAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsSysAF"
                  key="ConsSysAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsSysAF}
                  onChange={(event) => {
                    this.saveField("ConsSysAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsSysAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsAEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsAEAF"
                  key="ConsAEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsAEAF}
                  onChange={(event) => {
                    this.saveField("ConsAEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsHydAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsHydAF"
                  key="ConsHydAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsHydAF}
                  onChange={(event) => {
                    this.saveField("ConsHydAF", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsHydAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FWPureAF"
                  key="FWPureAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FWPureAF}
                  onChange={(event) => {
                    this.saveField("FWPureAF", event.target.value);
                  }}
                  error={this.state.validateStatus.FWPureAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowMEAF"
                  key="FlowMEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowMEAF}
                  onChange={(event) => {
                    this.saveField("FlowMEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowAEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowAEAF"
                  key="FlowAEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowAEAF}
                  onChange={(event) => {
                    this.saveField("FlowAEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowBLRAF"
                  key="FlowBLRAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowBLRAF}
                  onChange={(event) => {
                    this.saveField("FlowBLRAF", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowBLRAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowTempAF"
                  key="FlowTempAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowTempAF}
                  onChange={(event) => {
                    this.saveField("FlowTempAF", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowTempAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.METempAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="METempAF"
                  key="METempAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.METempAF}
                  onChange={(event) => {
                    this.saveField("METempAF", event.target.value);
                  }}
                  error={this.state.validateStatus.METempAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmCounterMEAF"
                  key="RpmCounterMEAF"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmCounterMEAF}
                  onChange={(event) => {
                    this.saveField("RpmCounterMEAF", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  AllfastReportEditDialog
);
