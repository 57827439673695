const myLib = require("../lib/MyLib");
const code_full = `
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = false;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "{formTitleEN}" : "{formTitle}";
const fieldLabels = {fieldLabels};
const buttonLabels = englishUI ?
{
  btnSave: "Save",
  btnClose: "Close",
}
: 
{
  btnSave: "Lưu",
  btnClose: "Thoát",
};

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, [{NotNullFields}]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class {FormName} extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        {PrimaryKey}: 0,
        {fieldNames}
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc({DanhMuc}, ()=>{});
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if(danhsach) {
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: danhsach,
        },
      })
      .then((response) => {
        this.setState(
          {
            DanhMuc: {
              ...response,
            },
          },
          () => nextFunc()
        );
      })
      .catch((error) => {
        showError(error);
        nextFunc();
      });
  }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.{PrimaryKey} > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_{DataSetName}_Update",
            ThamSo: this.state.after,
          })
          .then((response) => {
            var before = {};
            var after = {
              ...this.state.after,
              {PrimaryKey}: response.{DataSetName}[0].{PrimaryKey},
            };
            Object.assign(before, after);
            this.setState({
              before: before,
              after: after,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, after);
            }
            alert("Cập nhật thành công!");
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid
              container
              spacing={1}
              columns={GridLayoutCount}
            >
              {editFields}
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })({FormName});
`;

function generate_TenCacDanhMuc(filterFields) {
  var danhsach = "";
  filterFields.map((field) => {
    if (!myLib.isEmpty(field.SourceName)) {
      danhsach += (danhsach != "" ? "," : "") + field.SourceName;
    }
  });
  return '"' + (danhsach == "" ? "null" : danhsach) + '"';
}
function generate_TieuChi(filterFields) {
  var oneField = "";
  var stt = 0;
  filterFields.map((field) => {
    //tao code cho tung field
    if (field.LabelWidth) {
      oneField += `
        <Grid item xs={field.LabelWidth}>
          {label}
        </Grid>
        `
        .replace("field.LabelWidth", field.LabelWidth)
        .replace(
          "{label}",
          field.FieldName != ""
            ? "<FormLabel>{fieldLabels.{FieldName}}</FormLabel>".replace(
                "{FieldName}",
                field.FieldName
              )
            : !myLib.isEmpty(field.Label)
            ? "<FormLabel>{fieldLabels.{FieldName}}</FormLabel>".replace(
                "{FieldName}",
                "Label_" + stt++
              )
            : ""
        );
    }
    if (field.InputWidth) {
      //the grid bao ben ngoai input
      oneField += "<Grid item xs={" + field.InputWidth + "}>";
      //tao input combobox

      if (!myLib.isEmpty(field.SourceName) && !field.useAsync) {
        oneField += `
          <SelectFilter
              id={"field.FieldName"}
              key={"field.FieldName"}
              fullWidth
              autoFocus={autoFocus}
              options={danhsach}
              getOptionLabel={(item) => item[field.SourceLabel]}
              getOptionValue={(item) => item[field.SourceValue]}
              placeholder={placeHolder}
              styles={filterStyles}
              menuPosition={"fixed"}
              value={this.state.after.field.FieldName}
              isMulti={isMultiSelect}
              isClearable
              onChange={(item) => {
                {save}
              }}
              error={this.state.validateStatus.field.FieldName !== undefined}
            ></SelectFilter>
          `

          .replace(
            "{danhsach}",
            "{this.state.DanhMuc." + field.SourceName + "}"
          )
          .replace("[field.SourceLabel]", "." + field.SourceLabel)
          .replace("[field.SourceValue]", "." + field.SourceValue)
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replace("isMultiSelect", field.Compare.indexOf("in") >= 0)
          .replace(
            "{save}",
            field.Compare.indexOf("in") >= 0
              ? 'this.saveField("field.FieldName", item ? item : [])'
              : 'this.saveField("field.FieldName", item ? item : undefined)'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //tao input combobox async
      if (!myLib.isEmpty(field.SourceName) && field.useAsync) {
        oneField += `
        <AsyncSelect
              id={"field.FieldName"}
              key={"field.FieldName"}
              fullWidth
              cacheOptions
              defaultOptions
              autoFocus={autoFocus}
              getOptionLabel={(item) => item[field.SourceLabel]}
              getOptionValue={(item) => item[field.SourceValue]}
              placeholder={placeHolder}
              styles={filterStyles}
              menuPosition={"fixed"}
              value={this.state.after.field.FieldName}
              isMulti={isMultiSelect}
              isClearable
              loadOptions={(i, c) =>
                filterDanhSach(
                  danhsach,
                  {field.SourceLabel},
                  i,
                  c
                )
              }
              onChange={(item) => {
                {save}
              }}
              error={this.state.validateStatus.field.FieldName !== undefined}
            ></AsyncSelect>
          `

          .replace("danhsach", "this.state.DanhMuc." + field.SourceName + "")
          .replace("[field.SourceLabel]", "." + field.SourceLabel)
          .replace("{field.SourceLabel}", '"' + field.SourceLabel + '"')
          .replace("[field.SourceValue]", "." + field.SourceValue)
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replace("isMultiSelect", field.Compare.indexOf("in") >= 0)
          .replace(
            "{save}",
            field.Compare.indexOf("in") >= 0
              ? 'this.saveField("field.FieldName", item ? item : [])'
              : 'this.saveField("field.FieldName", item ? item : undefined)'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //tao input text box
      if (myLib.isEmpty(field.SourceName) && field.DataType == "string") {
        oneField += `
        <OutlinedInput
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={this.state.after.field.FieldName}
            onChange={(event) => {
              this.saveField(\"field.FieldName\", event.target.value);
            }}
            error={this.state.validateStatus.field.FieldName !== undefined}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //input la date
      if (myLib.isEmpty(field.SourceName) && field.DataType == "date") {
        oneField += `
        <MyDateEditor
        id={\"field.FieldName\"}
        key={\"field.FieldName\"}
        fullWidth
        autoFocus={autoFocus}
        value={this.state.after.field.FieldName}
        onChange={(value) => {
          this.saveField(\"field.FieldName\", value);
        }}
        error={this.state.validateStatus.field.FieldName !== undefined}
      />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //input la datetime
      if (myLib.isEmpty(field.SourceName) && field.DataType == "datetime") {
        oneField += `
        <MyDateTimeEditor
        id={\"field.FieldName\"}
        key={\"field.FieldName\"}
        fullWidth
        autoFocus={autoFocus}
        value={this.state.after.field.FieldName}
        onChange={(value) => {
          this.saveField(\"field.FieldName\", value);
        }}
        error={this.state.validateStatus.field.FieldName !== undefined}
      />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (
        myLib.isEmpty(field.SourceName) &&
        (field.DataType == "Bit" || field.DataType == "bit")
      ) {
        oneField += `
        <FormControlLabel
          id={\"field.FieldName\"}
          key={\"field.FieldName\"}
            label={""}
            labelPlacement="end"
            fullWidth
            control={
              <Checkbox
                size="medium"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                checked={this.state.after.field.FieldName}
                onChange={(event) => {
                  this.saveField(\"field.FieldName\", event.target.checked);
                }}
              />
            }
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (
        myLib.isEmpty(field.SourceName) &&
        (field.DataType == "Int" || field.DataType == "int")
      ) {
        oneField += `
        <MyNumberEditor
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            decimalScale={0}
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={this.state.after.field.FieldName}
            onChange={(event) => {
              this.saveField(\"field.FieldName\", event.target.value);
            }}
            error={this.state.validateStatus.field.FieldName !== undefined}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (myLib.isEmpty(field.SourceName) && field.DataType == "lat") {
        oneField += `
        <MyLatLonEditor
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            latlon="lat"
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={this.state.after.field.FieldName}
            onChange={(event) => {
              this.saveField(\"field.FieldName\", event.target.value);
            }}
            error={this.state.validateStatus.field.FieldName !== undefined}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      if (myLib.isEmpty(field.SourceName) && field.DataType == "lon") {
        oneField += `
        <MyLatLonEditor
            id="field.FieldName"
            key="field.FieldName"
            fullWidth
            latlon="lon"
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={this.state.after.field.FieldName}
            onChange={(event) => {
              this.saveField(\"field.FieldName\", event.target.value);
            }}
            error={this.state.validateStatus.field.FieldName !== undefined}
          />
        `
          .replace("{autoFocus}", field.autoFocus ? "{true}" : "{false}")
          .replace(
            "{placeHolder}",
            '"' + (field.placeHolder ? field.placeHolder : "") + '"'
          )
          .replaceAll("field.FieldName", field.FieldName);
      }
      //close tag
      oneField += "</Grid>";
      //khoang trong phia sau
      if (field.EmptySpace) {
        oneField += `
      <Grid item xs={field.EmptySpace}>
      </Grid>
      `.replace("field.EmptySpace", field.EmptySpace);
      }
    }
  });
  return oneField;
}
//tao cac nhan label
function generate_Labels(filterFields) {
  var labelEnglish = "",
    labelTV = "";
  var stt1 = 0,
    stt2 = 0;
  filterFields.map((field) => {
    if (field.FieldName !== "" && field.LabelWidth) {
      labelEnglish +=
        (labelEnglish != "" ? ",\n" : "") +
        field.FieldName +
        ' : "' +
        field.LabelEN +
        '"';
      labelTV +=
        (labelTV != "" ? ",\n" : "") +
        field.FieldName +
        ' : "' +
        field.Label +
        '"';
    } else {
      if (field.Label !== undefined && field.Label !== "") {
        labelEnglish +=
          (labelEnglish != "" ? ",\n" : "") +
          ("Label_" + stt1++) +
          ' : "' +
          field.LabelEN +
          '"';
        labelTV +=
          (labelTV != "" ? ",\n" : "") +
          ("Label_" + stt2++) +
          ' : "' +
          field.Label +
          '"';
      }
    }
  });
  return "englishUI ? \n{\n" + labelEnglish + "\n}\n:{\n" + labelTV + "\n}";
}
//tra ve danh sach cac truong edit
function generate_EditFields(filterFields) {
  var danhsach = "";
  filterFields.map((field) => {
    if (field.FieldName !== "") {
      danhsach +=
        (danhsach != "" ? ",\n" : "") +
        field.FieldName +
        " : " +
        (field.DefaultValue ? '"' + field.DefaultValue + '"' : "null");
    }
  });
  return danhsach + ",";
}
function generate_NotNullFields(filterFields) {
  var danhsach = "";
  filterFields.map((field) => {
    if (field.FieldName !== "") {
      danhsach += (danhsach != "" ? ", " : "") + '"' + field.FieldName + '"';
    }
  });
  return danhsach !== "" ? danhsach : "";
}
//tra ve code cua page tim kiem
export function generateCode_DataEditPage(name, config) {
  var code = code_full;
  code = code.replaceAll("{FormName}", name);
  code = code.replace("{formTitleEN}", config.TitleEN);
  code = code.replace("{formTitle}", config.Title);
  code = code.replace("{DanhMuc}", generate_TenCacDanhMuc(config.EditFields));
  code = code.replaceAll("{DataSetName}", config.DataSetName);
  code = code.replaceAll("{PrimaryKey}", config.PrimaryKey);
  code = code.replace("GridLayoutCount", config.GridLayoutCount);
  code = code.replace("{fieldNames}", generate_EditFields(config.EditFields));
  code = code.replace("{editFields}", generate_TieuChi(config.EditFields));
  code = code.replace("{fieldLabels}", generate_Labels(config.EditFields));
  code = code.replace(
    "{NotNullFields}",
    generate_NotNullFields(config.EditFields)
  );
  return code;
}
