import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import { makeStyles } from "@mui/styles";
import {
  Input,
  InputLabel,
  TextField,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";

import Moment from "moment";

const myLib = require("../lib/MyLib");

function LatEditor(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      mask={[
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-5]/,
        /[0-9]/,
        ".",
        /[0-5]/,
        /[0-9]/,
        /[nsNS]/,
      ]}
      placeholderChar={"_"}
      showMask
      keepCharPositions={true}
      guide={true}
      style={{ textTransform: "uppercase" }}
    />
  );
}

LatEditor.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
function LonEditor(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      mask={[
        /[0-1]/,
        /[0-9]/,
        /[0-9]/,
        ".",
        /[0-5]/,
        /[0-9]/,
        ".",
        /[0-5]/,
        /[0-9]/,
        /[ewEW]/,
      ]}
      placeholderChar={"_"}
      showMask
      keepCharPositions={true}
      guide={true}
      style={{ textTransform: "uppercase" }}
    />
  );
}

LonEditor.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function MyLatLonEditor(props) {
  const [state, setState] = React.useState({ error: false, rawText: "" });
  const classes = useStyles();
  return (
    <OutlinedInput
      name="textmask"
      id="maskInput"
      autoComplete="none"
      error={state.error}
      inputComponent={props.latlon === "lat" ? LatEditor : LonEditor}
      {...props}
      value={
        props.value === undefined
          ? state.rawText
          : props.latlon === "lat"
          ? myLib.latToDMS(props.value)
          : myLib.lonToDMS(props.value)
      }
      onChange={(event) => {
        //console.log("text value:", event.target.value);
        var raw = event.target.value;
        if (props.latlon === "lat" && !state.rawText.endsWith("N")) {
          if (raw.indexOf("_") === raw.length - 1) {
            raw = raw.substring(0, raw.length - 1) + "N";
          }
        } else if (props.latlon === "lon" && !state.rawText.endsWith("E")) {
          if (raw.indexOf("_") === raw.length - 1) {
            raw = raw.substring(0, raw.length - 1) + "E";
          }
        }
        var date =
          props.latlon === "lat"
            ? myLib.latFromDMS(raw)
            : myLib.lonFromDMS(raw);
        setState({ error: date === undefined, rawText: raw });
        if (props.onChange) {
          props.onChange(date);
        }
      }}
    />
  );
}
