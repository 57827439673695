import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
} from "@mui/material";

export default class CheckResultConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  render() {
    let tau = this.state.tauData;
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.props.title ? this.props.title : "Thông báo"}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 450,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontWeight: "bold" }}
              multiline
              fullWidth
              value={this.props.message}
            />
            <InputBase
              sx={{ ml: 1, flex: 1, color: "blue" }}
              multiline
              fullWidth
              value={"Hướng dẫn:\n" + this.props.ActionGuide}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {this.props.confirm ? (
            <Button
              onClick={() => {
                this.setState({ showConfirmExit: false });
                if (this.props.close) {
                  this.props.close(true);
                }
              }}
              variant="contained"
              autoFocus
            >
              {this.props.OKRunning ? "SAVING..." : "OK"}
            </Button>
          ) : null}
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
