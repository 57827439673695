import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Shifting Report" : "Báo cáo tàu di chuyển";
const fieldLabels = englishUI
  ? {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeSS: "TimeSS",
      TimeFS: "TimeFS",
      PosLat: "PosLat",
      PosLon: "PosLon",
      Tugs: "Tugs",
      TugTime: "TugTime",
      TugPower: "TugPower",
      ForeDraft: "ForeDraft",
      AftDraft: "AftDraft",
      CargoMT: "CargoMT",
      ShiftDistance: "ShiftDistance",
      RobFOFS: "RobFOFS",
      RobDOFS: "RobDOFS",
      RobLOFS: "RobLOFS",
      RobFWFS: "RobFWFS",
      RpmCounterMESS: "RpmCounterMESS",
      FlowMESS: "FlowMESS",
      FlowAESS: "FlowAESS",
      FlowBLRSS: "FlowBLRSS",
      FlowTempSS: "FlowTempSS",
      METempSS: "METempSS",
      RpmCounterMEFS: "RpmCounterMEFS",
      FlowMEFS: "FlowMEFS",
      FlowAEFS: "FlowAEFS",
      FlowBLRFS: "FlowBLRFS",
      FlowTempFS: "FlowTempFS",
      METempFS: "METempFS",
      ConsFOMESS: "ConsFOMESS",
      ConsDOMESS: "ConsDOMESS",
      ConsFOAESS: "ConsFOAESS",
      ConsDOAESS: "ConsDOAESS",
      ConsFOBLRSS: "ConsFOBLRSS",
      ConsDOBLRSS: "ConsDOBLRSS",
      ConsCylSS: "ConsCylSS",
      ConsSysSS: "ConsSysSS",
      ConsAESS: "ConsAESS",
      ConsHydSS: "ConsHydSS",
      FWPureSS: "FWPureSS",
      ConsFOMEFS: "ConsFOMEFS",
      ConsDOMEFS: "ConsDOMEFS",
      ConsFOAEFS: "ConsFOAEFS",
      ConsDOAEFS: "ConsDOAEFS",
      ConsFOBLRFS: "ConsFOBLRFS",
      ConsDOBLRFS: "ConsDOBLRFS",
      ConsCylFS: "ConsCylFS",
      ConsSysFS: "ConsSysFS",
      ConsAEFS: "ConsAEFS",
      ConsHydFS: "ConsHydFS",
      FWPureFS: "FWPureFS",
      RunhMESS: "RunhMESS",
      RunhAE1SS: "RunhAE1SS",
      RunhAE2SS: "RunhAE2SS",
      RunhAE3SS: "RunhAE3SS",
      RunhIGSS: "RunhIGSS",
      RunhMEFS: "RunhMEFS",
      RunhAE1FS: "RunhAE1FS",
      RunhAE2FS: "RunhAE2FS",
      RunhAE3FS: "RunhAE3FS",
      RunhIGFS: "RunhIGFS",
      Remark: "Remark",
    }
  : {
      VoyageCode: "VoyageCode",
      ReportTypeID: "ReportTypeID",
      ReportTime: "ReportTime",
      TimeSS: "TimeSS",
      TimeFS: "TimeFS",
      PosLat: "PosLat",
      PosLon: "PosLon",
      Tugs: "Tugs",
      TugTime: "TugTime",
      TugPower: "TugPower",
      ForeDraft: "ForeDraft",
      AftDraft: "AftDraft",
      CargoMT: "CargoMT",
      ShiftDistance: "ShiftDistance",
      RobFOFS: "RobFOFS",
      RobDOFS: "RobDOFS",
      RobLOFS: "RobLOFS",
      RobFWFS: "RobFWFS",
      RpmCounterMESS: "RpmCounterMESS",
      FlowMESS: "FlowMESS",
      FlowAESS: "FlowAESS",
      FlowBLRSS: "FlowBLRSS",
      FlowTempSS: "FlowTempSS",
      METempSS: "METempSS",
      RpmCounterMEFS: "RpmCounterMEFS",
      FlowMEFS: "FlowMEFS",
      FlowAEFS: "FlowAEFS",
      FlowBLRFS: "FlowBLRFS",
      FlowTempFS: "FlowTempFS",
      METempFS: "METempFS",
      ConsFOMESS: "ConsFOMESS",
      ConsDOMESS: "ConsDOMESS",
      ConsFOAESS: "ConsFOAESS",
      ConsDOAESS: "ConsDOAESS",
      ConsFOBLRSS: "ConsFOBLRSS",
      ConsDOBLRSS: "ConsDOBLRSS",
      ConsCylSS: "ConsCylSS",
      ConsSysSS: "ConsSysSS",
      ConsAESS: "ConsAESS",
      ConsHydSS: "ConsHydSS",
      FWPureSS: "FWPureSS",
      ConsFOMEFS: "ConsFOMEFS",
      ConsDOMEFS: "ConsDOMEFS",
      ConsFOAEFS: "ConsFOAEFS",
      ConsDOAEFS: "ConsDOAEFS",
      ConsFOBLRFS: "ConsFOBLRFS",
      ConsDOBLRFS: "ConsDOBLRFS",
      ConsCylFS: "ConsCylFS",
      ConsSysFS: "ConsSysFS",
      ConsAEFS: "ConsAEFS",
      ConsHydFS: "ConsHydFS",
      FWPureFS: "FWPureFS",
      RunhMESS: "RunhMESS",
      RunhAE1SS: "RunhAE1SS",
      RunhAE2SS: "RunhAE2SS",
      RunhAE3SS: "RunhAE3SS",
      RunhIGSS: "RunhIGSS",
      RunhMEFS: "RunhMEFS",
      RunhAE1FS: "RunhAE1FS",
      RunhAE2FS: "RunhAE2FS",
      RunhAE3FS: "RunhAE3FS",
      RunhIGFS: "RunhIGFS",
      Remark: "Remark",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class ShiftingReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        VoyageCode: null,
        ReportTypeID: null,
        ReportTime: null,
        TimeSS: null,
        TimeFS: null,
        PosLat: null,
        PosLon: null,
        Tugs: null,
        TugTime: null,
        TugPower: null,
        ForeDraft: null,
        AftDraft: null,
        CargoMT: null,
        ShiftDistance: null,
        RobFOFS: null,
        RobDOFS: null,
        RobLOFS: null,
        RobFWFS: null,
        RpmCounterMESS: null,
        FlowMESS: null,
        FlowAESS: null,
        FlowBLRSS: null,
        FlowTempSS: null,
        METempSS: null,
        RpmCounterMEFS: null,
        FlowMEFS: null,
        FlowAEFS: null,
        FlowBLRFS: null,
        FlowTempFS: null,
        METempFS: null,
        ConsFOMESS: null,
        ConsDOMESS: null,
        ConsFOAESS: null,
        ConsDOAESS: null,
        ConsFOBLRSS: null,
        ConsDOBLRSS: null,
        ConsCylSS: null,
        ConsSysSS: null,
        ConsAESS: null,
        ConsHydSS: null,
        FWPureSS: null,
        ConsFOMEFS: null,
        ConsDOMEFS: null,
        ConsFOAEFS: null,
        ConsDOAEFS: null,
        ConsFOBLRFS: null,
        ConsDOBLRFS: null,
        ConsCylFS: null,
        ConsSysFS: null,
        ConsAEFS: null,
        ConsHydFS: null,
        FWPureFS: null,
        RunhMESS: null,
        RunhAE1SS: null,
        RunhAE2SS: null,
        RunhAE3SS: null,
        RunhIGSS: null,
        RunhMEFS: null,
        RunhAE1FS: null,
        RunhAE2FS: null,
        RunhAE3FS: null,
        RunhIGFS: null,
        Remark: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_Voyage", () => {});
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }
  //load du lieu randomm
  loadRandomSample() {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 6,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 6; //departure report
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DR_TempReport_Update",
            ThamSo: [
              //danh sach cac report update cung 1 luc'
              {
                ReportID: this.state.after.ReportID,
                VoyageCode: this.state.after.VoyageCode,
                JsonData: JSON.stringify(this.state.after),
                TempStatus: 0,
              },
            ],
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
            //close form
            this.props.close();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTypeID}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ReportTypeID"
                  key="ReportTypeID"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ReportTypeID}
                  onChange={(event) => {
                    this.saveField("ReportTypeID", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportTypeID !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="TimeSS"
                  key="TimeSS"
                  fullWidth
                  value={this.state.after.TimeSS}
                  onChange={(value) => {
                    this.saveField("TimeSS", value);
                  }}
                  error={this.state.validateStatus.TimeSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyDateTimeEditor
                  id="TimeFS"
                  key="TimeFS"
                  fullWidth
                  value={this.state.after.TimeFS}
                  onChange={(value) => {
                    this.saveField("TimeFS", value);
                  }}
                  error={this.state.validateStatus.TimeFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLat}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLat"
                  key="PosLat"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLat}
                  onChange={(event) => {
                    this.saveField("PosLat", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLat !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PosLon}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="PosLon"
                  key="PosLon"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.PosLon}
                  onChange={(event) => {
                    this.saveField("PosLon", event.target.value);
                  }}
                  error={this.state.validateStatus.PosLon !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Tugs}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Tugs"
                  key="Tugs"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Tugs}
                  onChange={(event) => {
                    this.saveField("Tugs", event.target.value);
                  }}
                  error={this.state.validateStatus.Tugs !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugTime}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TugTime"
                  key="TugTime"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TugTime}
                  onChange={(event) => {
                    this.saveField("TugTime", event.target.value);
                  }}
                  error={this.state.validateStatus.TugTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugPower}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="TugPower"
                  key="TugPower"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.TugPower}
                  onChange={(event) => {
                    this.saveField("TugPower", event.target.value);
                  }}
                  error={this.state.validateStatus.TugPower !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ForeDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ForeDraft}
                  onChange={(event) => {
                    this.saveField("ForeDraft", event.target.value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AftDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.AftDraft}
                  onChange={(event) => {
                    this.saveField("AftDraft", event.target.value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.CargoMT}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="CargoMT"
                  key="CargoMT"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.CargoMT}
                  onChange={(event) => {
                    this.saveField("CargoMT", event.target.value);
                  }}
                  error={this.state.validateStatus.CargoMT !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ShiftDistance}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ShiftDistance"
                  key="ShiftDistance"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ShiftDistance}
                  onChange={(event) => {
                    this.saveField("ShiftDistance", event.target.value);
                  }}
                  error={this.state.validateStatus.ShiftDistance !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFOFS"
                  key="RobFOFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFOFS}
                  onChange={(event) => {
                    this.saveField("RobFOFS", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFOFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDOFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobDOFS"
                  key="RobDOFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobDOFS}
                  onChange={(event) => {
                    this.saveField("RobDOFS", event.target.value);
                  }}
                  error={this.state.validateStatus.RobDOFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobLOFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobLOFS"
                  key="RobLOFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobLOFS}
                  onChange={(event) => {
                    this.saveField("RobLOFS", event.target.value);
                  }}
                  error={this.state.validateStatus.RobLOFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RobFWFS"
                  key="RobFWFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RobFWFS}
                  onChange={(event) => {
                    this.saveField("RobFWFS", event.target.value);
                  }}
                  error={this.state.validateStatus.RobFWFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmCounterMESS"
                  key="RpmCounterMESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmCounterMESS}
                  onChange={(event) => {
                    this.saveField("RpmCounterMESS", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmCounterMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowMESS"
                  key="FlowMESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowMESS}
                  onChange={(event) => {
                    this.saveField("FlowMESS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowAESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowAESS"
                  key="FlowAESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowAESS}
                  onChange={(event) => {
                    this.saveField("FlowAESS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowBLRSS"
                  key="FlowBLRSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowBLRSS}
                  onChange={(event) => {
                    this.saveField("FlowBLRSS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowBLRSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowTempSS"
                  key="FlowTempSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowTempSS}
                  onChange={(event) => {
                    this.saveField("FlowTempSS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowTempSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.METempSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="METempSS"
                  key="METempSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.METempSS}
                  onChange={(event) => {
                    this.saveField("METempSS", event.target.value);
                  }}
                  error={this.state.validateStatus.METempSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RpmCounterMEFS"
                  key="RpmCounterMEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RpmCounterMEFS}
                  onChange={(event) => {
                    this.saveField("RpmCounterMEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowMEFS"
                  key="FlowMEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowMEFS}
                  onChange={(event) => {
                    this.saveField("FlowMEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowAEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowAEFS"
                  key="FlowAEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowAEFS}
                  onChange={(event) => {
                    this.saveField("FlowAEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowBLRFS"
                  key="FlowBLRFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowBLRFS}
                  onChange={(event) => {
                    this.saveField("FlowBLRFS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowBLRFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FlowTempFS"
                  key="FlowTempFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FlowTempFS}
                  onChange={(event) => {
                    this.saveField("FlowTempFS", event.target.value);
                  }}
                  error={this.state.validateStatus.FlowTempFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.METempFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="METempFS"
                  key="METempFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.METempFS}
                  onChange={(event) => {
                    this.saveField("METempFS", event.target.value);
                  }}
                  error={this.state.validateStatus.METempFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOMESS"
                  key="ConsFOMESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOMESS}
                  onChange={(event) => {
                    this.saveField("ConsFOMESS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOMESS"
                  key="ConsDOMESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOMESS}
                  onChange={(event) => {
                    this.saveField("ConsDOMESS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOAESS"
                  key="ConsFOAESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOAESS}
                  onChange={(event) => {
                    this.saveField("ConsFOAESS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOAESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOAESS"
                  key="ConsDOAESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOAESS}
                  onChange={(event) => {
                    this.saveField("ConsDOAESS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOBLRSS"
                  key="ConsFOBLRSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOBLRSS}
                  onChange={(event) => {
                    this.saveField("ConsFOBLRSS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOBLRSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOBLRSS"
                  key="ConsDOBLRSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOBLRSS}
                  onChange={(event) => {
                    this.saveField("ConsDOBLRSS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsCylSS"
                  key="ConsCylSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsCylSS}
                  onChange={(event) => {
                    this.saveField("ConsCylSS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsCylSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsSysSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsSysSS"
                  key="ConsSysSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsSysSS}
                  onChange={(event) => {
                    this.saveField("ConsSysSS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsSysSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsAESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsAESS"
                  key="ConsAESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsAESS}
                  onChange={(event) => {
                    this.saveField("ConsAESS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsHydSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsHydSS"
                  key="ConsHydSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsHydSS}
                  onChange={(event) => {
                    this.saveField("ConsHydSS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsHydSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FWPureSS"
                  key="FWPureSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FWPureSS}
                  onChange={(event) => {
                    this.saveField("FWPureSS", event.target.value);
                  }}
                  error={this.state.validateStatus.FWPureSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOMEFS"
                  key="ConsFOMEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOMEFS}
                  onChange={(event) => {
                    this.saveField("ConsFOMEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOMEFS"
                  key="ConsDOMEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOMEFS}
                  onChange={(event) => {
                    this.saveField("ConsDOMEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOAEFS"
                  key="ConsFOAEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOAEFS}
                  onChange={(event) => {
                    this.saveField("ConsFOAEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOAEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOAEFS"
                  key="ConsDOAEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOAEFS}
                  onChange={(event) => {
                    this.saveField("ConsDOAEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsFOBLRFS"
                  key="ConsFOBLRFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsFOBLRFS}
                  onChange={(event) => {
                    this.saveField("ConsFOBLRFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsDOBLRFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsDOBLRFS"
                  key="ConsDOBLRFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsDOBLRFS}
                  onChange={(event) => {
                    this.saveField("ConsDOBLRFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsCylFS"
                  key="ConsCylFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsCylFS}
                  onChange={(event) => {
                    this.saveField("ConsCylFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsCylFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsSysFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsSysFS"
                  key="ConsSysFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsSysFS}
                  onChange={(event) => {
                    this.saveField("ConsSysFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsSysFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsAEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsAEFS"
                  key="ConsAEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsAEFS}
                  onChange={(event) => {
                    this.saveField("ConsAEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsHydFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="ConsHydFS"
                  key="ConsHydFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.ConsHydFS}
                  onChange={(event) => {
                    this.saveField("ConsHydFS", event.target.value);
                  }}
                  error={this.state.validateStatus.ConsHydFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="FWPureFS"
                  key="FWPureFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.FWPureFS}
                  onChange={(event) => {
                    this.saveField("FWPureFS", event.target.value);
                  }}
                  error={this.state.validateStatus.FWPureFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhMESS"
                  key="RunhMESS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhMESS}
                  onChange={(event) => {
                    this.saveField("RunhMESS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1SS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE1SS"
                  key="RunhAE1SS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE1SS}
                  onChange={(event) => {
                    this.saveField("RunhAE1SS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE1SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE2SS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE2SS"
                  key="RunhAE2SS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE2SS}
                  onChange={(event) => {
                    this.saveField("RunhAE2SS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE2SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE3SS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE3SS"
                  key="RunhAE3SS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE3SS}
                  onChange={(event) => {
                    this.saveField("RunhAE3SS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE3SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhIGSS"
                  key="RunhIGSS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhIGSS}
                  onChange={(event) => {
                    this.saveField("RunhIGSS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhIGSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhMEFS"
                  key="RunhMEFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhMEFS}
                  onChange={(event) => {
                    this.saveField("RunhMEFS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1FS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE1FS"
                  key="RunhAE1FS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE1FS}
                  onChange={(event) => {
                    this.saveField("RunhAE1FS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE1FS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE2FS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE2FS"
                  key="RunhAE2FS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE2FS}
                  onChange={(event) => {
                    this.saveField("RunhAE2FS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE2FS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE3FS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhAE3FS"
                  key="RunhAE3FS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhAE3FS}
                  onChange={(event) => {
                    this.saveField("RunhAE3FS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhAE3FS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="RunhIGFS"
                  key="RunhIGFS"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.RunhIGFS}
                  onChange={(event) => {
                    this.saveField("RunhIGFS", event.target.value);
                  }}
                  error={this.state.validateStatus.RunhIGFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ShiftingReportEditDialog
);
